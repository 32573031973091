import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { UpdateParams, crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { usePrefetchLists } from '../form/prefetchLists';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import { getPrintTemplatesByEntityConfId as getPrintTemplateAction } from 'printTemplate/actions';
import {
    getEntityId,
    getValueSetCodesRequiredForEntity,
    getAccessLevelForEntity,
    allowsDelete,
} from '../utils/viewConfigUtils/index';
import { Provider, refreshContext } from '../form/refreshContext';
import uniq from 'lodash/uniq';
import { getEditableViewVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import DeferredSpinner from 'components/DeferredSpinner';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import { RootState } from 'reducers/rootReducer';
import formTypeContext from '../form/formTypeContext';
import { AjaxError } from 'rxjs/ajax';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/processPageRefreshContext';
import useViewConfig from 'util/hooks/useViewConfig';
import { fromNullable } from 'fp-ts/lib/Option';
import EditForm from './EditForm2';

import { createSelector } from 'reselect';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { EditActions } from '../viewActions/ActionsWithOverrides';
import Title from '../title/Title';
import { diff } from 'jsondiffpatch';
import withRestrictions from '../utils/withRestrictions';
import { useOverrideTitle } from '../form/hooks/configurations/overrideTitle';
import { setAsTopView, unsetAsTopView } from 'popoverStackManagement/actions';
import useTabToPrefetch from '../form/hooks/useTabToPrefetch';
import isOffline from 'util/isOffline';
import { refetchAllXManysContext } from 'fieldFactory/display/components/RefmanyMultiselect/util/refetchAllXManysContext';
import useRegisterViewDetails from 'popoverStackManagement/viewDetailsStack/useRegisterViewDetails';
import { EditProps } from './EditProps';
import { useGetData } from './useGetData';
import ShowWrapper from '../genericShow/Show2';
import withVisibleViewName from '../hoc/withVisibleViewName';

type Submission = {
    record: {};
    redirect?: string;
    afterSaveOrFailureCb?: (err?: AjaxError) => void;
    visibleAndEditableFields?: string[];
};
type SubmissionState =
    | {
          // default state
          type: 'NOT_SUBMITTING';
      }
    | {
          // intermediate update state to capture initialData
          type: 'SUBMITTING_1';
          submission: Submission;
      }
    | {
          // submission phase where we submit initial data.
          type: 'SUBMITTING_2';
          submission: Submission;
          initialData: {};
      };
const useSubmit = (
    args: Pick<
        EditProps,
        'onSaveCb' | 'resource' | 'viewName' | 'id' | 'interceptSubmit' | 'injectSubmissionValues' | 'formId'
    > & {
        updateData: () => void;
    },
) => {
    const {
        onSaveCb,
        resource,
        viewName,
        updateData,
        id,
        interceptSubmit,
        injectSubmissionValues,
        formId = 'record-form',
    } = args;
    const [submissionState, setSubmissionState] = React.useState<SubmissionState>({ type: 'NOT_SUBMITTING' });
    const dispatch = useDispatch();
    const getData = useGetData();
    const viewConfig = useViewConfig();
    const initialData = useSelector((state: RootState) => {
        if (submissionState.type === 'SUBMITTING_1') {
            return getData(state, {
                viewName,
                id,
                overrideViewConfig: viewConfig,
            });
        }
        return null;
    });
    React.useEffect(() => {
        if (submissionState.type === 'SUBMITTING_1') {
            setSubmissionState({
                type: 'SUBMITTING_2',
                submission: submissionState.submission,
                initialData: initialData,
            });
        }
    }, [submissionState, initialData]);
    React.useEffect(() => {
        if (submissionState.type === 'SUBMITTING_2') {
            const { record, redirect, afterSaveOrFailureCb, visibleAndEditableFields } = submissionState.submission;
            const onSuccessCb =
                (onSaveCb || afterSaveOrFailureCb || undefined) &&
                ((...args) => {
                    if (onSaveCb) {
                        (onSaveCb as any)(...args);
                    }
                    if (redirect) {
                        dispatch(pushAction(redirect));
                    }
                    if (afterSaveOrFailureCb) {
                        afterSaveOrFailureCb();
                    }
                });
            const updateParams: UpdateParams = {
                resource,
                data: {
                    ...record,
                    ...injectSubmissionValues,
                },
                previousData: submissionState.initialData,
                cb: onSuccessCb,
                errorsCbs: {
                    409: () => {
                        alert('Stale data submitted. Record was updated from another session.');
                        updateData();
                    },
                    '*': afterSaveOrFailureCb,
                },
                formId,
                visibleAndEditableFields,
            };

            if (interceptSubmit) {
                interceptSubmit(updateParams);
            } else {
                dispatch(crudUpdateAction(updateParams));
            }
            setSubmissionState({
                type: 'NOT_SUBMITTING',
            });
        }
    }, [submissionState, onSaveCb, updateData, dispatch, resource, interceptSubmit, injectSubmissionValues, formId]);

    const save = React.useCallback(
        (
            record: {},
            redirect?: string,
            afterSaveOrFailureCb?: (err?: AjaxError) => void,
            visibleAndEditableFields?: string[],
        ) => {
            setSubmissionState({
                type: 'SUBMITTING_1',
                submission: {
                    record,
                    redirect,
                    afterSaveOrFailureCb,
                    visibleAndEditableFields,
                },
            });
        },
        [],
    );
    return save;
};
const Edit: React.FunctionComponent<EditProps> = (props) => {
    const {
        viewName,
        resource,
        id,
        noRedirectOnIdChange,
        onSaveCb,
        interceptSubmit,
        noBaseRecordFetch,
        injectSubmissionValues,
        noListsFetch,
        disallowClickAwayNavigation,
        entityFormContextRef,
    } = props;
    const dispatch = useDispatch();
    const { refresh: refreshProcessPage } = React.useContext(processPageRefreshContext);
    const tabToPrefetch = useTabToPrefetch({ id, resource, viewName });
    const officialViewConfig = useViewConfig(false);
    const viewConfig = useViewConfig();
    const getValueSetCodeLiterals = React.useMemo(getEditableViewVSCodeLiteralsSelector, []);
    const valueSetCodeLiterals = useSelector((state: RootState) =>
        getValueSetCodeLiterals(state, { viewName, overrideViewConfig: viewConfig, resource }),
    );
    const dataLoadedSelector = React.useMemo(() => {
        const getEntities = createGetEntities();
        return createSelector(
            getEntities,
            (state, props) => props.id,
            (state, props) => props.resource,
            (entities, id, resource) => {
                return fromNullable(entities[resource])
                    .mapNullable((e) => e[id])
                    .fold(false, () => true);
            },
        );
    }, []);
    const dataLoaded = useSelector((state: RootState) => dataLoadedSelector(state, { id, resource }));

    const prefetchLists = usePrefetchLists();
    const updateData = React.useCallback(() => {
        if (noBaseRecordFetch) {
            return;
        }
        dispatch(
            crudGetOneAction({
                monitorRequest: true,
                resource,
                id,
                view: viewName,
                override:
                    officialViewConfig !== viewConfig
                        ? {
                              patchViewConfig: diff(officialViewConfig, viewConfig),
                          }
                        : undefined,
                cb: (responseId, responseData) => {
                    if (`${id}` !== `${responseId}`) {
                        console.log('Merge Occured.\n ourId: ', id, ', responseId: ', responseId);
                        if (!noRedirectOnIdChange) {
                            dispatch(pushAction(`/${resource}/${responseId}`));
                        } else if (refreshProcessPage) {
                            refreshProcessPage();
                        }
                    }
                },
            }),
        );
    }, [
        dispatch,
        viewName,
        resource,
        id,
        noRedirectOnIdChange,
        refreshProcessPage,
        officialViewConfig,
        viewConfig,
        noBaseRecordFetch,
    ]);

    const [refetchAllXManysKey, refetchAllXMany] = React.useReducer((state) => state + 1, 1);

    const updateAllData = React.useCallback(() => {
        updateData();
        const valueSetCodes = uniq(
            getValueSetCodesRequiredForEntity(viewConfig, viewName).concat(valueSetCodeLiterals),
        ).map((valueset) => ({ valueSet: valueset }));
        if (valueSetCodes.length > 0) {
            dispatch(loadValueSetsAction(valueSetCodes));
        }
        dispatch(getPrintTemplateAction(getEntityId(viewConfig, resource)));

        if (noListsFetch) {
            return;
        }
        prefetchLists(viewName, id, tabToPrefetch);
    }, [
        noListsFetch,
        viewName,
        id,
        valueSetCodeLiterals,
        dispatch,
        tabToPrefetch,
        prefetchLists,
        resource,
        updateData,
        viewConfig,
    ]);
    React.useEffect(() => {
        updateAllData();

        if (props.formId) {
            dispatch(setAsTopView(props.formId));
            return () => {
                dispatch(unsetAsTopView(props.formId));
            };
        }
    }, []); // eslint-disable-line
    const lastId = React.useRef(props.id);
    React.useEffect(() => {
        if (id !== lastId.current) {
            updateAllData();
            lastId.current = id;
        }
    }, [id, updateAllData]);
    const propagateRefresh = React.useContext(refreshContext);
    const refresh = React.useCallback(
        /**
         *
         * @param event
         * @param fullRefresh refresh all parent Edit views, in addition to current.
         */
        (event?: Event | React.SyntheticEvent<any, any>, fullRefresh = false) => {
            if (event) {
                event.stopPropagation();
            }
            updateAllData();
            if (fullRefresh) {
                propagateRefresh(null, true);
                refetchAllXMany();
            }
        },
        [updateAllData, propagateRefresh],
    );

    const save = useSubmit({
        resource,
        onSaveCb,
        viewName,
        id,
        updateData,
        interceptSubmit,
        injectSubmissionValues,
    });
    const { actions, hasDelete: _hasDelete = true, hasShow, redirect, formId = 'record-form', taskId, backref } = props;
    const [, sameEntityExistsBelowInTheStack] = useRegisterViewDetails(formId, 'entity', id);
    const { element: overrideTitleElement } = useOverrideTitle(viewName);

    const hasDelete = React.useMemo(
        () => _hasDelete && allowsDelete(getAccessLevelForEntity(viewConfig, resource)),
        [_hasDelete, viewConfig, resource],
    );

    const clonedactions: any = React.useMemo(() => {
        return actions ? (
            React.cloneElement(actions, {
                hasDelete,
                hasShow,
                viewName,
                sameEntityExistsBelowInTheStack,
            })
        ) : (
            <EditActions
                sameEntityExistsBelowInTheStack={sameEntityExistsBelowInTheStack}
                viewName={viewName}
                id={id}
                hasDelete={hasDelete}
                hasShow={hasShow}
            />
        );
    }, [actions, hasDelete, hasShow, viewName, id, sameEntityExistsBelowInTheStack]);
    const renderSuccess = React.useCallback(() => {
        return dataLoaded || noBaseRecordFetch || isOffline() ? (
            <refetchAllXManysContext.Provider value={refetchAllXManysKey}>
                <EditForm
                    disallowClickAwayNavigation={disallowClickAwayNavigation}
                    entityFormContextRef={props.entityFormContextRef}
                    evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                    createMobileAppBar={props.createMobileAppBar}
                    backref={backref}
                    disableFormSaveNotifier={props.disableFormSaveNotifierTrigger}
                    optInWriteable={props.optInWriteable}
                    viewName={viewName}
                    id={id}
                    formId={formId}
                    taskId={taskId}
                    actions={clonedactions}
                    save={save}
                    resource={resource}
                    tabToPrefetch={tabToPrefetch}
                    referenceFieldsShouldFetchInitialData={true}
                    redirect={redirect}
                    toolbar={props.toolbar}
                    overrideInitialValues={props.overrideInitialValues}
                    renderLayoutEditor={props.renderLayoutEditor}
                    renderAboveForm={props.renderAboveForm}
                />
            </refetchAllXManysContext.Provider>
        ) : (
            <DeferredSpinner />
        );
    }, [
        props.entityFormContextRef,
        props.evaluatedAdhocSPELVariables,
        refetchAllXManysKey,
        props.renderAboveForm,
        props.renderLayoutEditor,
        props.createMobileAppBar,
        noBaseRecordFetch,
        dataLoaded,
        props.disableFormSaveNotifierTrigger,
        props.optInWriteable,
        props.toolbar,
        viewName,
        id,
        formId,
        taskId,
        clonedactions,
        save,
        resource,
        tabToPrefetch,
        redirect,
        props.overrideInitialValues,
        backref,
        disallowClickAwayNavigation,
    ]);
    return (
        <Provider value={refresh}>
            <formTypeContext.Provider value="EDIT">
                {props.createMobileAppBar ? (
                    <SsgAppBarMobile
                        title={overrideTitleElement ?? <Title component="span" resource={resource} id={id} />}
                    />
                ) : null}
                {noBaseRecordFetch ? (
                    renderSuccess()
                ) : (
                    <FormDisplayStatus
                        id={id}
                        resource={resource}
                        showSuccessOffline={dataLoaded}
                        renderSuccess={renderSuccess}
                        refresh={refresh}
                    />
                )}
            </formTypeContext.Provider>
        </Provider>
    );
};

const EditWrapper = withRestrictions(Edit);

const EditWithPrintControl = withVisibleViewName((props: EditProps) => {
    const printMode = useSelector((state: RootState) => state.printMode);
    if (printMode) {
        return (
            <ShowWrapper
                id={props.id}
                resource={props.resource}
                viewName={props.viewName}
                evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
            />
        );
    }
    return <EditWrapper {...props} />;
});
export default EditWithPrintControl;
