import React, {
    useState,
    useCallback,
    FunctionComponent,
    useContext,
    ComponentType,
    useMemo,
    useEffect,
    useRef,
} from 'react';
import EnhancedRGridWithVis from '../form/EnhancedRGridTabbable';
import { AjaxError } from 'rxjs/ajax';
import ErrorDialog from '../form/SubmissionFailureDialog';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { useIsPopover } from '../genericEdit/useIsPopover';
import { reduxForm, InjectedFormProps, getFormMeta } from 'redux-form';
import { formContext } from '../form/EntityFormContext';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { EntityValidations } from 'reducers/entityValidationsReducer';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import casetivityViewContext from 'util/casetivityViewContext';
import { SpelOptions } from 'expressions/evaluate';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import {
    allowsEdit,
    getFieldInstanceEntriesFromView,
    getFieldsFromView,
    isFieldViewField,
} from '../utils/viewConfigUtils';
import createGetDefaultValues from '../utils/getDefaultValues';
import getBooleanFieldsPreloadedFalse from '../utils/getBooleanFieldsPreloadedFalse';
import Toolbar from 'components/generics/form/Toolbar.aor';
import EntityExpressionTester, { RenderLayoutEditor } from 'expression-tester/entity-form';
import NavToWizardFab from 'layout-editor/components/NavigateToWizardFab';
import useValidation from '../form/validate/useValidation';
import { parse } from 'query-string';
import fromEntries from 'util/fromentries';
import merge from 'lodash/merge';
import { expressionTesterOpenContext } from 'expression-tester/hooks/useExpressionTesterOpen';
import EditViewInPlaceSwitch from 'layout-editor/editViewInPlaceContext/Switch';
import { InjectErrorsToAllFieldsContextProvider, TestingTools } from '../form/forceInjectErrorsForAllFieldsContext';
import { DisplayAboveWidth } from '../title/Title';
import { useOverrideTitle } from '../form/hooks/configurations/overrideTitle';
import useOverriddenViewValidations from '../form/hooks/useOverriddenViewValidations';
import { ParentBackRefProperties } from './ParentBackRefProperties';
import { useRecord } from './useRecord';

export type Save = (record: {}, alwaysCb: (err?: AjaxError) => void, visibleAndEditableFields: string[]) => void;
interface SimpleFormComponentProps {
    toolbar?: JSX.Element;
    save: Save;
    fields: JSX.Element[];
    TitleElement?: JSX.Element;
    viewName: string;
}
const SimpleFormComponent: ComponentType<
    SimpleFormComponentProps & Pick<InjectedFormProps, 'invalid' | 'handleSubmit' | 'form'>
> = (props) => {
    const [alertError, setAlertError] = useState<AjaxError | null>(null);
    const fc = useContext(formContext);
    const visibleAndEditableFieldsStr = useMemo(
        () => JSON.stringify(fc.visibleAndEditableFields),
        [fc.visibleAndEditableFields],
    );
    const visibleAndEditableFields = useMemo(() => {
        return JSON.parse(visibleAndEditableFieldsStr);
    }, [visibleAndEditableFieldsStr]);
    const clearAlert = useCallback(() => {
        setAlertError(null);
    }, [setAlertError]);
    const [isSaving, setIsSaving] = useState(false);
    const { save, toolbar, invalid, fields, handleSubmit } = props;
    const handleSubmitWithRedirect = useCallback(
        (redirect: string) => {
            // we ignore redirect here - just leaving the argument to let it fit
            // api in Toolbar component.
            return handleSubmit((values) => {
                setIsSaving(true);
                save(
                    values,
                    (err) => {
                        setIsSaving(false);
                        setAlertError(err);
                    },
                    visibleAndEditableFields,
                );
            });
        },
        [handleSubmit, setIsSaving, setAlertError, save, visibleAndEditableFields],
    );
    const viewConfig = useViewConfig();
    const currentUserHasEditPermissionOnViewDefs = useMemo(() => {
        const accessLevel = viewConfig.entities['ViewDef']?.accessLevel;
        return accessLevel && allowsEdit(accessLevel);
    }, [viewConfig]);

    const { element: overrideTitleElement, EditTitleElem } = useOverrideTitle(props.viewName);
    const OverrideElement = overrideTitleElement ? (
        <DisplayAboveWidth displayAboveWidth="xs">
            <h1>{overrideTitleElement}</h1>
        </DisplayAboveWidth>
    ) : null;

    const isPopover = useIsPopover(props.form);
    return (
        <React.Fragment>
            <ErrorDialog alertError={alertError} clearAlert={clearAlert} />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {EditTitleElem ? (
                        <div style={{ display: 'flex' }}>
                            {OverrideElement ?? props.TitleElement}&nbsp;{EditTitleElem}
                        </div>
                    ) : (
                        OverrideElement ?? props.TitleElement
                    )}
                </div>
                {!isPopover && currentUserHasEditPermissionOnViewDefs && (
                    // Hide this in view editing wizard, since it's hardcoded open.
                    <expressionTesterOpenContext.Consumer>
                        {(mode) =>
                            mode === 'OPEN_EXPRESSIONPANEL' ? null : (
                                <span>
                                    <EditViewInPlaceSwitch />
                                </span>
                            )
                        }
                    </expressionTesterOpenContext.Consumer>
                )}
            </div>
            <form autoComplete="off" className="simple-form">
                <div style={{ minHeight: '215px' }}>
                    <EnhancedRGridWithVis fields={fields} lastRowDropdownsFlipUp />
                </div>
                <div style={{ height: '1em' }} />
                {toolbar &&
                    React.cloneElement(toolbar, {
                        handleSubmitWithRedirect,
                        invalid,
                        submitOnEnter: true,
                        saving: isSaving,
                    })}
            </form>
        </React.Fragment>
    );
};

interface FormProps {
    initialValues?: {};
    viewName: string;
    adjustedFormValues: {};
    fieldValues: {};
    resource: string;
    fields: JSX.Element[];
    valueSets: ReturnType<typeof useValueSets>;
    entities: ReturnType<typeof useEntities>;
    entityConfigValidations: EntityValidations;
    viewConfig: ViewConfig;
    options: SpelOptions;
    form?: string;
    save: Save;
    toolbar?: JSX.Element;
    TitleElement?: JSX.Element;
    _validate: any;
    _warn: any;
    renderLayoutEditor?: RenderLayoutEditor;
    outerSubmitFailed?: boolean;
}
const shouldError = ({ values, props, nextProps, initialRender }) => {
    return initialRender || !props.anyTouched || nextProps.fieldValues !== props.fieldValues;
};

const SpyOnOuterSubmit = ({ onOuterSubmitFailed, outerSubmitFailed }) => {
    const prevOuterSubmitFailed = useRef(false);
    useEffect(() => {
        if (prevOuterSubmitFailed.current !== outerSubmitFailed && outerSubmitFailed) {
            onOuterSubmitFailed();
        }
    }, [onOuterSubmitFailed, outerSubmitFailed]);
    return null;
};

const Form: ComponentType<FormProps> = reduxForm<{}, FormProps>({
    enableReinitialize: true,
    shouldError,
    shouldWarn: shouldError,
    validate: (values: {}, props) => {
        const { _validate } = props;
        return _validate();
    },
    warn: (values: {}, props) => {
        const { _warn } = props;
        return _warn();
    },
})((props) => {
    return (
        <React.Fragment>
            <SpyOnOuterSubmit
                onOuterSubmitFailed={() => {
                    const registeredFields = props.fields.map((f) => f.props.source);
                    props.touch(...registeredFields);
                }}
                outerSubmitFailed={props.outerSubmitFailed}
            />
            {/* <EditableViewFormEditor mode="EDIT" entityType={props.resource} viewName={props.viewName} /> */}
            <TestingTools />
            {props.renderLayoutEditor?.()}
            <SimpleFormComponent
                viewName={props.viewName}
                TitleElement={props.TitleElement}
                form={props.form}
                toolbar={props.toolbar}
                invalid={props.invalid}
                handleSubmit={props.handleSubmit}
                save={props.save}
                fields={props.fields}
            />
        </React.Fragment>
    );
});

interface FormInContextProps {
    formId?: string;
    viewName: string;
    parentEntityIdValue?: string;
    parentEntityName?: string;
    parentField?: string;
    fields: JSX.Element[];
    record: ParentBackRefProperties;
    injectValues?: {};
    save: Save;
    toolbar?: JSX.Element;
    search?: string;
    renderLayoutEditor?: () => JSX.Element;
    TitleElement?: JSX.Element;
    outerSubmitFailed?: boolean;
}
const FormInContext: FunctionComponent<FormInContextProps> = (props) => {
    const {
        formId,
        viewName,
        injectValues,
        parentEntityIdValue,
        parentEntityName,
        parentField,
        toolbar,
        record,
        fields,
        save: _save,
        search,
        renderLayoutEditor,
        TitleElement,
    } = props;
    const fc = useContext(formContext);
    const entityConfigValidations = useSelector((state: RootState) => state.entityValidations);
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewContext = useContext(casetivityViewContext);
    const options = useMemo(
        () => ({
            viewContext,
            dateFormat: viewConfig.application.dateFormat || 'MM/dd/yyyy',
        }),
        [viewContext, viewConfig],
    );
    const getDefaultValues = useMemo(createGetDefaultValues, []);
    const initialValues = useSelector((state: RootState) =>
        getDefaultValues(state, {
            fields: props.fields,
            record,
        }),
    );

    const save = useCallback<Save>(
        (unadjustedFormValues, cb) => {
            const values = fc.registeredValues || unadjustedFormValues;
            let submissionValues: {} = merge(getBooleanFieldsPreloadedFalse(viewConfig, viewName), values);
            if (injectValues) {
                submissionValues = {
                    ...submissionValues,
                    ...injectValues,
                };
            }
            if (
                getFieldsFromView(viewConfig.views[viewName])
                    .filter(isFieldViewField)
                    .find(({ field }) => field === 'linkedEntity')
            ) {
                submissionValues = {
                    ...submissionValues,
                    linkedEntityId: parentEntityIdValue,
                    linkedEntityType: parentEntityName,
                };
            } else if (parentField && parentEntityIdValue) {
                submissionValues = {
                    ...submissionValues,
                    [parentField]: parentEntityIdValue,
                };
                if (parentField === 'linkedEntityId' && parentEntityName) {
                    submissionValues['linkedEntityType'] = parentEntityName;
                }
            }
            _save(submissionValues, cb, fc.visibleAndEditableFields);
        },
        [
            _save,
            viewConfig,
            viewName,
            parentEntityIdValue,
            parentEntityName,
            fc.registeredValues,
            parentField,
            injectValues,
            fc.visibleAndEditableFields,
        ],
    );
    const resource = viewConfig.views[props.viewName].entity;
    const overriddenViewValidations = useOverriddenViewValidations(props.viewName);
    const viewValidations = useSelector((state: RootState) => state.viewValidations?.[viewName]);
    const vr = useValidation({
        type: 'error',
        values: fc.fieldValues,
        resource: resource,
        extraValidations: overriddenViewValidations ?? viewValidations,
        adhocVariablesContext: fc.adhocVariablesContext,
    });
    const validate = useCallback(() => {
        const res = vr();
        return res;
    }, [vr]);
    const wr = useValidation({
        type: 'warn',
        values: fc.fieldValues,
        resource: resource,
        extraValidations: overriddenViewValidations ?? viewValidations,
        adhocVariablesContext: fc.adhocVariablesContext,
    });
    const warn = useCallback(() => {
        const res = wr();
        return res;
    }, [wr]);
    return (
        <Form
            outerSubmitFailed={props.outerSubmitFailed}
            TitleElement={props.TitleElement}
            renderLayoutEditor={renderLayoutEditor}
            _validate={validate}
            _warn={warn}
            save={save}
            fields={fields}
            initialValues={initialValues}
            options={options}
            viewName={props.viewName}
            viewConfig={viewConfig}
            valueSets={valueSets}
            entities={entities}
            toolbar={toolbar || <Toolbar />}
            resource={resource}
            entityConfigValidations={entityConfigValidations}
            adjustedFormValues={fc.registeredValues}
            fieldValues={fc.fieldValues}
            form={formId || 'record-form'}
        />
    );
};
interface SimpleFormProps {
    formId: string;
    viewName: string;
    parentEntityIdValue?: string;
    parentEntityName?: string;
    parentField?: string;
    save: Save;
    injectValues?: {};
    toolbar?: JSX.Element;
    search?: string;
    renderLayoutEditor?: () => JSX.Element;
    hideFields?: string[];
    subscribe?: (values: {}) => void;
    TitleElement?: JSX.Element;
    outerSubmitFailed?: boolean;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}
const useInitialValuesFromSearchQuery = (qs: string | undefined, view: View) => {
    return React.useMemo(() => {
        if (!qs) {
            return undefined;
        }
        // Currently only returns values that map onto fields present in the view. (can change this later if we need to - just not a present need.)
        // note: 'injectValues' appears to be the prop on Create used to 'inject on submit'. i.e. non-editable, not present in the form.
        // so maybe use that prop for that purpose if necessary.
        const values = parse(qs);
        return fromEntries(
            Object.entries(values).filter(([k, v]) => {
                if (k.endsWith('Id')) {
                    return view.fields[k] || view.fields[k.slice(0, -2)];
                }
                if (k.endsWith('Ids')) {
                    return view.fields[k] || view.fields[k.slice(0, -3)];
                }
                return view.fields[k];
            }),
        );
    }, [qs, view]);
};
const SimpleForm: FunctionComponent<SimpleFormProps> = (props) => {
    const { parentField, parentEntityIdValue, parentEntityName, search } = props;
    const fieldFactory = useContext(FieldFactoryContext);
    const isPopover = useIsPopover(props.formId);
    const { viewName } = props;
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;
    /*
        parse search, and return fields present in widgets in the form.
    */
    const initialFromQueryString = useInitialValuesFromSearchQuery(search, viewConfig.views[viewName]);
    const _record = useRecord(props);
    const record = useMemo(() => ({ ..._record, ...initialFromQueryString }), [_record, initialFromQueryString]);

    const hideFieldsStr = useMemo(() => props.hideFields && JSON.stringify(props.hideFields), [props.hideFields]);
    const fields = useMemo(() => {
        const hideFields = hideFieldsStr && JSON.parse(hideFieldsStr);
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT,
            validate: true,
            connected: true,
            // some fields need to know any backrefs
            options: {
                parentField,
                parentEntityIdValue,
                parentEntityName,
            },
        };
        return fieldFactory(config)({
            record,
            resource,
            basePath: `/${resource}`,
            referenceFieldsShouldFetchInitialData: true,
            shouldFetchValueset: false,
            isPopover,
            overrideFieldValueIfDisabled: true,
            isForCreate: true,
            fetchOnMount: true,
        })(
            getFieldInstanceEntriesFromView(viewConfig, viewName).filter(([k, f]) => {
                if (!hideFields) {
                    return true;
                }
                if (!isFieldViewField(f)) {
                    return true;
                }
                if (hideFields.some((hidePath) => k === hidePath)) {
                    return false;
                }
                return true;
            }),
        );
    }, [
        viewConfig,
        parentField,
        parentEntityIdValue,
        parentEntityName,
        viewName,
        resource,
        record,
        fieldFactory,
        isPopover,
        hideFieldsStr,
    ]);
    return (
        <>
            <FormInContext {...props} record={record} fields={fields} />
            <NavToWizardFab viewName={props.viewName} />
        </>
    );
};

const SimpleFormSubscriber = ({ subscribe, formId }: { subscribe?: (values: {}) => void; formId?: string }) => {
    const fc = useContext(formContext);
    const invalid = useSelector(
        (state: RootState) => Object.values(state.form[formId]?.syncErrors ?? {}).filter(Boolean).length !== 0,
    );
    const touched = useSelector(
        (state: RootState) =>
            Object.values(getFormMeta(formId)(state)).filter((meta: { touched?: boolean }) => meta.touched).length !==
            0,
    );
    useEffect(() => {
        const value = { ...fc.registeredValues };
        if (invalid) {
            value['__invalid'] = invalid;
        }
        if (touched) {
            value['__touched'] = touched;
        }
        subscribe(value);
    }, [fc.registeredValues, subscribe, invalid, touched]);
    return null;
};
const WrappedSimpleForm: React.FC<SimpleFormProps> = (props) => {
    const _record = useRecord(props);
    return (
        <InjectErrorsToAllFieldsContextProvider>
            <EntityExpressionTester
                evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                type="EDIT"
                record={_record}
                viewName={props.viewName}
                formId={props.formId}
            >
                {({ renderLayoutEditor }) => (
                    <>
                        <SimpleForm {...props} renderLayoutEditor={renderLayoutEditor} />
                        {props.subscribe ? (
                            <SimpleFormSubscriber subscribe={props.subscribe} formId={props.formId} />
                        ) : null}
                    </>
                )}
            </EntityExpressionTester>
        </InjectErrorsToAllFieldsContextProvider>
    );
};

export default WrappedSimpleForm;
