import { getFormAsyncErrors, getFormSyncErrors, getFormSubmitErrors, getFormMeta } from 'redux-form';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import { RootState } from '../../../../reducers/rootReducer';
import memoizeOne from 'memoize-one';
import {
    ViewField,
    EntityField,
    SearchField,
    Tab,
    MenuItem,
    FieldViewField,
    ComponentField,
} from '../../../../reducers/ViewConfigType';
import {
    isValueSetOrValueSetManyField,
    isRefManyField,
    isRefOneField,
    getRefEntityName,
    isString,
    getValueSetForFieldExpr,
    isValueSetField,
    isValueSetManyField,
    isRefManyManyField,
    getDataTypeForFieldExpr,
    isValidEntityFieldExpression,
} from './getFieldProperties';
import {
    isFieldViewField,
    isAddressVerificationField,
    isInlineManyViewField,
    isAddressVerification2Field,
    isComponentField,
    isExpressionViewField,
} from './getFieldProperties/viewFields';
import { fromNullable, tryCatch, fromPredicate, fromEither, some, none } from 'fp-ts/lib/Option';
import decodeAddressConfig from 'fieldFactory/input/components/Address/util/decodeAddressConfig';
import { IFieldMapping2 } from 'fieldFactory/input/components/Address/types';
import applyToBracketedExpressions from '@mkanai/casetivity-shared-js/lib/print-templates/render/util/applyToBracketedExpressions';
import evaluateExpression from 'ts-spel-utils/evaluateExpression';
import { tryCatch2v } from 'fp-ts/lib/Either';
import produce from 'immer';
import getOverrideSecondarySorts from 'sideEffect/crud/getList/getOverrideSecondarySorts';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import getAllFieldsWeWriteTo from 'fieldFactory/input/components/Address2/getAllFieldsWeWriteTo';
import get from 'lodash/get';
import { groupBy, sortBy } from 'lodash';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
export * from './getFieldProperties';
export * from './getFieldProperties/viewFields';

type ViewConfig = RootState['viewConfig'];
type View = ViewConfig['views'][keyof ViewConfig['views']];
/*
    Note to prevent confusion:
    Both entities and fields have 'tabs' properties!
    viewConfig[entityName].tabs contains an array of tab titles
    fieldObj.tabs contains indexes of the tabs to appear in
    (1 indexed. This is in case we want to use 0 to 'opt-in' to outside-tab view in the future)
*/

export const getDisplayName = (viewConfig: ViewConfig, entityName: string): string =>
    viewConfig.entities[entityName].displayName;

export const getPluralName = (viewConfig: ViewConfig, entityName: string): string | null =>
    viewConfig.entities[entityName].displayNamePlural;

export const getPossibleMatchName = (viewConfig: ViewConfig, entityName: string): string =>
    `${viewConfig.entities[entityName].displayName} Possible Matches`;

export const hasTabs = (viewConfig: ViewConfig, viewName: string): boolean =>
    Object.prototype.hasOwnProperty.call(viewConfig.views[viewName], 'tabs'); // TODO - deprecate

export const viewHasTabs = (view: View): boolean => view.tabs && Object.keys(view.tabs).length > 0;

export const getTabsTitlesFromView = (view: View): string[] => {
    if (viewHasTabs(view)) {
        return Object.keys(view.tabs || {});
    }
    return [];
};

// TODO Update REMOVING the OLD Config
export const getFields = (viewConfig: ViewConfig, entityName: string): EntityField[] =>
    Object.values(viewConfig.entities[entityName].fields);

export const getEntityId = (viewConfig: ViewConfig, entityName: string) => viewConfig.entities[entityName].id;

export const getEntityRestUrl = (viewConfig: ViewConfig, entityName: string) => viewConfig.entities[entityName].restUrl;

export const getFieldsFromView = (view: View): ViewField[] => Object.values(view.fields);
export const getFieldInstanceEntriesFromView = (viewConfig: ViewConfig, viewName): [string, ViewField][] => {
    const view = viewConfig.views[viewName];
    const getWithPostfix = getAdjustedFieldSource(viewConfig)(view);
    return !view
        ? []
        : Object.entries(view.fields).map(([key, vf]) => {
              return [isFieldViewField(vf) && !vf.overrideId ? getWithPostfix(vf, key) : key, vf];
          });
};

export const getViewNameFromComponentField = (componentField: ViewField) => {
    if (!isComponentField(componentField) || !componentField.config?.trim()?.startsWith('{')) {
        return null;
    }
    try {
        const componentConfig = JSON.parse(componentField.config) as { component?: string };
        return componentConfig?.component || null;
    } catch (e) {
        console.error(e);
    }
    return null;
};

const _expandComponentFields = (
    viewConfig: ViewConfig,
    fields: [string, ViewField][],
    rebaseField: (
        component: [componentFieldId: string, componentViewField: ComponentField],
        child: [fieldId: string, viewField: ViewField],
    ) => [fieldId: string, viewField: ViewField],
    // track our encountered views so we never cycle.
    // It will loop once, but stop.
    encounteredViews: string[] = [],
): {
    rowsTaken: number;
    expandedFieldsByRow: [string, ViewField][][];
} => {
    const expanded = (() => {
        const grouped: {
            [row: string]: [string, ViewField][];
        } = groupBy(fields, '[1].row');
        const rows = sortBy(Object.entries(grouped), ([k, v]) => parseInt(k)).map(([k, v]) => v);

        const adjustedRows: [string, ViewField][][] = [];
        let i = 0;

        while (i < rows.length) {
            const curr: [string, ViewField][] = rows[i];
            const components = curr.filter(([, field]) => isComponentField(field));
            if (!components.length) {
                adjustedRows.push(curr);
                i++;
                continue;
            }
            const columns = sortBy(curr, ([k, v]) => parseInt(v.column as any));

            // eslint-disable-next-line no-loop-func
            components.forEach(([componentId, componentField], componentColIx) => {
                const viewName = getViewNameFromComponentField(componentField);
                if (!viewName || encounteredViews.includes(viewName)) {
                    return;
                }
                const { rowsTaken, expandedFieldsByRow: _expandedFieldsByRow } = _expandComponentFields(
                    viewConfig,
                    getAllFieldEntriesFromView(viewConfig, viewName),
                    rebaseField,
                    encounteredViews.concat(viewName),
                );
                const expandedFieldsByRow = _expandedFieldsByRow.map((col) =>
                    col.map((c) => {
                        return rebaseField([componentId, componentField as ComponentField], c);
                    }),
                );
                const isMultiRowComponent = rowsTaken > 1;
                if (isMultiRowComponent) {
                    // insert expanded fields at the next row, mutating 'rows'
                    // we will continue to iterate over the rows we insert here in the next passes, but that's fine since they shouldn't have component fields left in them.
                    columns.splice(componentColIx, 1);
                    rows.splice(i + 1, 0, ...expandedFieldsByRow);
                    return;
                }
                const [onlyRow] = expandedFieldsByRow;
                if (!onlyRow) {
                    // component view might be empty
                    return;
                }
                const x = parseInt(componentField.column as any);

                const insertedWidth = onlyRow.reduce((prev, curr) => prev + parseInt(curr[1].span as any), 0);
                for (let i = componentColIx; i < columns.length; i++) {
                    columns[i][1] = { ...columns[i][1], column: columns[i][1].column + insertedWidth };
                }
                columns.splice(
                    componentColIx,
                    1,
                    ...onlyRow.map(([id, vf]) => [id, { ...vf, column: vf.column ?? 0 + x }] as [string, ViewField]),
                );
            });
            adjustedRows.push(columns);
            i++;
        }
        // now adjust columns of all the rows, pushing them downwards.
        for (let currentRow = 0; currentRow < adjustedRows.length; currentRow++) {
            adjustedRows[currentRow].forEach((t) => {
                t[1] = { ...t[1], row: currentRow };
            });
        }
        return adjustedRows;
    })();

    return {
        rowsTaken: expanded.length,
        expandedFieldsByRow: expanded,
    };
};

const rebasePlainExpression =
    <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig, resource: string) =>
    (expr: string, rebaseOntoRootPath: string, onFailure: () => string): string => {
        const compile = getImpl();
        const parsed = compile.compileExpression(expr);
        if (parsed.type === 'parse_failure' || !parsed.rebase || !parsed.prettyPrint) {
            return onFailure();
        }
        return parsed
            .rebase((path) => {
                if (isValidEntityFieldExpression(viewConfig, resource, path)) {
                    return [rebaseOntoRootPath];
                }
                return [];
            })
            .prettyPrint();
    };

export const expandComponentFields = (
    viewConfig: ViewConfig,
    fields: [string, ViewField][],
    resource: string,
    options?: {
        rebaseExpressionsWithinFields?: boolean;
        replaceXmanyWithMultiCard?: boolean;
    },
): {
    rowsTaken: number;
    expandedFieldsByRow: [string, ViewField & { __originalField?: string }][][];
} => {
    const rebaseExpressionsWithinFields = options?.rebaseExpressionsWithinFields ?? true;
    const replaceXmanyWithMultiCard = options?.replaceXmanyWithMultiCard ?? false;
    let { rowsTaken, expandedFieldsByRow } = _expandComponentFields(
        viewConfig,
        fields,
        ([componentId, componentField], [fieldId, field]): [string, ViewField] => {
            const key = [componentId || componentField.field, fieldId].join('.');
            const newFieldProperty = field['field']
                ? [
                      field.widgetType === 'EXPRESSION' ? componentId || componentField.field : componentField.field,
                      field['field'],
                  ]
                      .filter(Boolean)
                      .join('.')
                : undefined;
            if (!rebaseExpressionsWithinFields) {
                return [
                    key,
                    {
                        ...field,
                        field: newFieldProperty,
                        __originalField: field['field'],
                    },
                ] as [string, ViewField & { __originalField?: string }];
            }

            const config = (() => {
                if (!componentField.field) {
                    return field.config;
                }
                if (isExpressionViewField(field)) {
                    return parseTemplateString(field.config, viewConfig, resource, componentField.field).expression;
                }
                const parsedConfig = fromPredicate<string>((conf) => !!conf?.trim())(field.config).chain((conf) =>
                    tryCatch(() => JSON.parse(conf)),
                );
                const rebaseConfig = ({
                    expressionsToRemap,
                    templatesToRemap,
                }: {
                    expressionsToRemap?: string[];
                    templatesToRemap?: string[];
                }) => {
                    return parsedConfig
                        .map((conf) =>
                            Object.fromEntries(
                                Object.entries(conf).map(([k, v]) => {
                                    if (typeof v !== 'string') {
                                        return [k, v];
                                    }
                                    if (templatesToRemap?.includes(k)) {
                                        return [
                                            k,
                                            parseTemplateString(v, viewConfig, resource, componentField.field)
                                                .expression,
                                        ];
                                    }
                                    if (expressionsToRemap?.includes(k)) {
                                        return [
                                            k,
                                            rebasePlainExpression(viewConfig, resource)(v, componentField.field, () => {
                                                console.error(
                                                    `Rebasing failure for "${k}" in ${JSON.stringify(
                                                        field,
                                                        null,
                                                        1,
                                                    )} from component ${JSON.stringify(componentField, null, 1)}`,
                                                );
                                                return v;
                                            }),
                                        ];
                                    }
                                    return [k, v];
                                }),
                            ),
                        )
                        .map((conf) => JSON.stringify(conf))
                        .getOrElse(field.config);
                };
                switch (field.widgetType) {
                    case 'MULTI_CARD':
                    case 'MULTISELECT': {
                        return rebaseConfig({
                            expressionsToRemap: ['defineSPELVariables', 'hasAdd', 'hasCreate'],
                            templatesToRemap: ['filter', 'listFilter'],
                        });
                    }
                    case 'INLINE_MANY':
                    case 'ENTITY_CHIP':
                    case 'ENTITY_TYPEAHEAD':
                    case 'MULTIPLE_ENTITY_TYPEAHEAD':
                    case 'SELECT': {
                        return rebaseConfig({
                            templatesToRemap: ['filter'],
                        });
                    }
                    default:
                        return field.config;
                }
            })();

            return [
                key,
                {
                    ...field,
                    config,
                    field: newFieldProperty,
                    __originalField: field['field'],
                },
            ] as [string, ViewField & { __originalField?: string }];
        },
    );

    expandedFieldsByRow = expandedFieldsByRow.map((r) => {
        return r.map(([k, field]) => {
            let widgetType = field.widgetType;
            let config = field.config;
            if (field.widgetType === 'MULTISELECT' || field.widgetType === 'INLINE_MANY') {
                if (
                    replaceXmanyWithMultiCard &&
                    (isRefManyField(viewConfig, field.entity, field.field, 'POP_LAST') ||
                        isRefManyManyField(viewConfig, field.entity, field.field, 'POP_LAST'))
                ) {
                    widgetType = 'MULTI_CARD';
                    // configuration needs to be adjusted to show the same thing in MULTI_CARD
                    // as it does when clicking into the items
                    config = (() => {
                        if (!field.config) {
                            return field.config;
                        }
                        try {
                            const conf = JSON.parse(field.config);
                            if (conf['useCreateView']) {
                                conf['viewName'] = conf['createViewName'];
                            }
                            return JSON.stringify(conf);
                        } catch (e) {
                            console.error(e);
                            return field.config;
                        }
                    })();
                }
            }
            return [
                k,
                {
                    ...field,
                    config,
                    widgetType,
                } as ViewField,
            ];
        });
    });
    return {
        rowsTaken,
        expandedFieldsByRow,
    };
};

export const getSearchFieldsFromView = (view: View): SearchField[] =>
    Object.values(typeof view.searchFields === 'undefined' ? {} : view.searchFields);

export const getDefaultListViewName = (viewConfig: ViewConfig, resource: string) => {
    return fromNullable(viewConfig.entities[resource])
        .mapNullable((e) => e.defaultViews)
        .mapNullable((d) => d.LIST)
        .mapNullable<string | null>((L) => L.name)
        .getOrElse(null);
};

// smaller set of fields to display when shown as a reference
export const getAsRefFields = (viewConfig, entityName) => {
    const entityDef = viewConfig.entities[entityName];
    if (entityDef.defaultViews && entityDef.defaultViews.LIST) {
        const defaultListView = viewConfig.views[entityDef.defaultViews.LIST.name];
        return getFieldsFromView(defaultListView);
    }
    return [];
    // TODO: Backup to all entity fields or certain types
    // - this will be some effort since would have to use the data type to determine widget..?
};

export const mapFieldEntriesToDataFields = (
    viewConfig: ViewConfig,
    entries: [string, ViewField][],
    rootEntity: string,
): [string, ViewField][] => {
    const getWithPostfix = getAdjustedFieldSource(viewConfig)({ entity: rootEntity });
    return entries.map(([key, vf]) => {
        return [isFieldViewField(vf) && !vf.overrideId ? getWithPostfix(vf, key) : key, vf];
    });
};

export const getViewFieldsForTab = (tab: Tab): ViewField[] => Object.values(tab.fields);
export const getViewFieldInstanceEntriesForTab = (
    viewConfig: ViewConfig,
    viewName: string,
    tab: Tab,
): [string, ViewField][] => {
    const view = viewConfig.views[viewName];
    return mapFieldEntriesToDataFields(viewConfig, Object.entries(tab.fields) as [string, ViewField][], view.entity);
};

/*
    Tab validation functions
*/
const collectErrors = (state: RootState, formId?: string) => {
    const syncErrors = getFormSyncErrors(formId || 'record-form')(state);
    const asyncErrors = getFormAsyncErrors(formId || 'record-form')(state);
    const submitErrors = getFormSubmitErrors(formId || 'record-form')(state);

    const meta = getFormMeta(formId || 'record-form')(state);

    return Object.fromEntries(
        Object.entries({
            ...syncErrors,
            ...asyncErrors,
            ...submitErrors,
        }).filter(([key]) => !meta || get(meta, key)?.touched),
    );
};

export const getView = (viewConfig: ViewConfig, viewName: string): View => {
    const view = viewConfig.views[viewName];
    if (!view) {
        throw new Error(`Failed to look up view "${viewName}" in the viewConfig`);
    }
    return view;
};

export const APPCASE_LIST_PREFIX = '_APPCASE_PROC_LIST:'; // trailing colon indicates process definition key follows
export const APPCASE_PAGE_PREFIX = '_APPCASE_PROC_PAGE:';
const isCapital = (c: string) => c.toUpperCase() === c;

// like adjustLinkedXToLinkedEntity, except returns the related entity in _2
const disallowJustEntity = (x?: string | null): string | null => (x && x !== 'Entity' ? x : null);
export const adjustLinkedXToLinkedEntityWithRes = (fieldName: string): [string, string | null] => {
    if (!fieldName) {
        // just in case undefined is passed at runtime
        // (this happens due to the CloneElement stuff where source isn't given to inputs initially)
        return [fieldName, null];
    }
    if (fieldName.startsWith('linked') && fieldName.length > 6 && isCapital(fieldName[6])) {
        const [first, ...rest] = fieldName.split('.');
        return [['linkedEntity', ...rest].join('.'), disallowJustEntity(first.slice('linked'.length))];
    }
    const linkedIndex = fieldName.indexOf('.linked');
    if (
        linkedIndex !== -1 &&
        fieldName[linkedIndex + '.linked'.length] &&
        isCapital(fieldName[linkedIndex + '.linked'.length])
    ) {
        const before = fieldName.slice(0, linkedIndex);
        const middle = fieldName.slice(linkedIndex + '.linked'.length).split('.')[0];
        const after = fieldName
            .slice(linkedIndex + 1)
            .split('.')
            .flatMap((v, i) => (i === 0 ? [] : [v]))
            .join('.');
        return [`${before}.linkedEntity${after.length > 0 ? `.${after}` : ''}`, disallowJustEntity(middle)];
    }
    return [fieldName, null];
};

export const adjustLinkedXToLinkedEntity = (fieldName: string) => {
    return adjustLinkedXToLinkedEntityWithRes(fieldName)[0];
};

export const getBPMConfigFields = (
    viewName: string,
    viewConfig: ViewConfig,
    options: {
        mode: 'KEEP_LINKEDX_TYPE' | 'ALWAYS_LINKEDENTITY'; // adjustment to fields returned
        readOrWrite: 'READ' | 'WRITE'; // read = columns + summary. write = search
    },
): FieldViewField[] => {
    const { mode, readOrWrite } = options;
    if (viewName.startsWith(APPCASE_LIST_PREFIX)) {
        const keyInSEARCHview: 'searchFields' | 'columns' = readOrWrite === 'WRITE' ? 'searchFields' : 'columns';
        const processConfig = viewConfig.processes[viewName.slice(APPCASE_LIST_PREFIX.length)];
        if (processConfig && processConfig.views.SEARCH && processConfig.views.SEARCH[keyInSEARCHview]) {
            const fields: FieldViewField[] = Object.values(processConfig.views.SEARCH[keyInSEARCHview]).filter(
                isFieldViewField,
            );
            if (mode === 'ALWAYS_LINKEDENTITY') {
                return fields.map((f): FieldViewField => ({ ...f, field: adjustLinkedXToLinkedEntity(f.field) }));
            }
            return fields;
        }
    }
    if (viewName.startsWith(APPCASE_PAGE_PREFIX)) {
        if (readOrWrite === 'WRITE') {
            // No WRITE fields are specified for the APPCASE_PAGE view. returning no fields
            return [];
        } else {
            const processConfig = viewConfig.processes[viewName.slice(APPCASE_LIST_PREFIX.length)];
            if (processConfig && processConfig.views.SUMMARY && processConfig.views.SUMMARY.headers) {
                const fields: FieldViewField[] = Object.values(processConfig.views.SUMMARY.headers).filter(
                    isFieldViewField,
                );
                if (mode === 'ALWAYS_LINKEDENTITY') {
                    return fields.map((f): FieldViewField => ({ ...f, field: adjustLinkedXToLinkedEntity(f.field) }));
                }
                return fields;
            }
        }
    }
    return [];
};

// Most viewNames are straightforward indexes into the viewConfig.
// However some views have additional behavior we want to inject.
// for example _APPCASE_PROC_LIST represents our process list config,
// but _APPCASE_PROC_LIST:environmental-case represents our process list with the environmental-case selected.
// in this case we should merge fields from viewConfig.processes['environmental-case'].views.SEARCH.(columns or search)
// this is done on a case by case basis

// the method below returns (adjusted) viewName to index into the viewConfig, and additional fields to merge,
// since these are common operations that occur together.
export const getViewIndexAndAdditionalConfigFields = (
    viewName: string,
    viewConfig: ViewConfig,
    mode: 'KEEP_LINKEDX_TYPE' | 'ALWAYS_LINKEDENTITY',
    readOrWrite: 'READ' | 'WRITE' = 'READ',
): [string, FieldViewField[]] => {
    if (viewName.startsWith(APPCASE_LIST_PREFIX)) {
        return [
            viewName.slice(0, APPCASE_LIST_PREFIX.length - 1),
            getBPMConfigFields(viewName, viewConfig, { mode, readOrWrite }),
        ];
    }
    if (viewName.startsWith(APPCASE_PAGE_PREFIX)) {
        return [
            viewName.slice(0, APPCASE_PAGE_PREFIX.length - 1),
            getBPMConfigFields(viewName, viewConfig, { mode, readOrWrite }),
        ];
    }
    return [viewName, []];
};
export const getValuesetFieldsFromAddressWidgetConfig = (
    config: string,
    viewConfig: ViewConfig,
): [string, string][] => {
    const VERIF_SOURCE = 'verificationStatus';
    const base = tryCatch(() => getValueSetForFieldExpr(viewConfig, 'Address', VERIF_SOURCE, 'TRAVERSE_PATH'))
        .map((valueSet) => [[VERIF_SOURCE, valueSet] as [string, string]])
        .getOrElse([]);

    const addrConfig = decodeAddressConfig(config, 'RETURN_NULL');
    const fromAddressConfig = Object.entries(addrConfig.fieldMapping)
        .flatMap(([k, path]: [keyof IFieldMapping2, string]): [string, string][] => {
            try {
                if (k.endsWith('Id') || k.endsWith('Code')) {
                    const source = path.endsWith('Id')
                        ? path.slice(0, -2)
                        : path.endsWith('Code')
                        ? path.slice(0, -4)
                        : path;
                    const dataType = getDataTypeForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                    if (dataType === 'VALUESET' || dataType === 'VALUESETMANY') {
                        const valueSet = getValueSetForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                        return [[source, valueSet]];
                    }
                }
                return [];
            } catch (e) {
                return [];
            }
        })
        .concat(base);
    return fromAddressConfig;
};

export const getValuesetCodesFromAddressWidgetConfig = (config: string, viewConfig: ViewConfig): string[] => {
    return getValuesetFieldsFromAddressWidgetConfig(config, viewConfig).map(([f, c]) => c);
};

export const getAllFieldsFromViewObj = (view: View) => {
    const tabFields = getTabsTitlesFromView(view).map((tabKey) => getViewFieldsForTab(view.tabs![tabKey]));
    return [...getFieldsFromView(view)].concat(...tabFields);
};
export const getAllFieldsFromView = (viewConfig: ViewConfig, viewName: string) => {
    const view = getView(viewConfig, viewName);
    return getAllFieldsFromViewObj(view);
};

export const getAllFieldEntriesFromViewObj = (view: View, tabKey?: string) => {
    const tabFields = tabKey
        ? Object.entries(view.tabs?.[tabKey]?.fields ?? {})
        : getTabsTitlesFromView(view).flatMap((tk) => Object.entries(view.tabs![tk].fields));
    return Object.entries(view.fields).concat(tabFields);
};
export const getAllFieldEntriesFromView = (viewConfig: ViewConfig, viewName: string, tabKey?: string) => {
    const view = getView(viewConfig, viewName);
    return getAllFieldEntriesFromViewObj(view, tabKey);
};

/* this seriously needs tests */
/* memoizing-one since there's JSON parsing going on in there, and it can be triggered in our form-contexts a lot */
export const getValueSetFieldsRequiredForEntity = memoizeOne(
    (
        viewConfig: ViewConfig,
        _viewName: string,
        types: 'ONES' | 'MANYS' | 'BOTH',
    ): {
        [field: string]: string;
    } => {
        const filterField = (field: ViewField): field is FieldViewField =>
            isFieldViewField(field) &&
            (types === 'BOTH'
                ? isValueSetOrValueSetManyField(viewConfig, field.entity, field.field)
                : types === 'ONES'
                ? isValueSetField(viewConfig, field.entity, field.field)
                : isValueSetManyField(viewConfig, field.entity, field.field));
        const [viewName, processConfigFields] = getViewIndexAndAdditionalConfigFields(
            _viewName,
            viewConfig,
            'ALWAYS_LINKEDENTITY',
        );
        const view = getView(viewConfig, viewName);
        const allViewFields = expandComponentFields(
            viewConfig,
            getAllFieldEntriesFromView(viewConfig, viewName),
            view.entity,
            { rebaseExpressionsWithinFields: false },
        )
            .expandedFieldsByRow.flat()
            .map(([, f]) => f);
        const allSubViewFields = allViewFields
            .filter(
                (field) =>
                    isFieldViewField(field) &&
                    (isRefOneField(viewConfig, field.entity, field.field) ||
                        isRefManyField(viewConfig, field.entity, field.field)),
            )
            .flatMap((field: FieldViewField) =>
                getAsRefFields(viewConfig, getRefEntityName(viewConfig, view.entity, field.field, 'TRAVERSE_PATH')).map(
                    (childField) => [field, childField] as const,
                ),
            );

        const allFieldsToGetValuesetsFrom = [...allViewFields].concat(...processConfigFields);
        const subViewFieldValueSets = allSubViewFields
            .filter((t): t is [FieldViewField, FieldViewField] => filterField(t[1]))
            .map(
                ([originalField, childField]) =>
                    [
                        originalField.field + '.' + childField.field,
                        getValueSetForFieldExpr(viewConfig, childField.entity, childField.field),
                    ] as const,
            );

        const vsFieldValueSets = allFieldsToGetValuesetsFrom
            .filter(filterField)
            .map(
                (field: FieldViewField) =>
                    [field.field, getValueSetForFieldExpr(viewConfig, field.entity, field.field)] as const,
            );
        const vsSuggestionFieldValueSets = allFieldsToGetValuesetsFrom.flatMap((f): [string, string][] => {
            if (f.widgetType === 'VALUESET_SUGGEST') {
                return fromNullable(f.config)
                    .chain((conf) => tryCatch(() => JSON.parse(conf) as { valueSet?: string }))
                    .chain((conf) => fromNullable(conf.valueSet))
                    .fold<[string, string][]>([], (valueSet) => [[f.field, valueSet]]);
            }
            return [];
        });

        const addressWidgetValuesets = allViewFields.filter(isAddressVerificationField).flatMap((f) => {
            return getValuesetFieldsFromAddressWidgetConfig(f.config, viewConfig);
        });

        const addressWidget2Valuesets = allViewFields.filter(isAddressVerification2Field).flatMap((f) => {
            return getAllFieldsWeWriteTo(f).flatMap((field): [string, string][] => {
                try {
                    const source = getFieldSourceFromPath(viewConfig, view.entity, field);
                    const dataType = getDataTypeForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                    if (dataType === 'VALUESET' || dataType === 'VALUESETMANY') {
                        const valueSet = getValueSetForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                        return [[source, valueSet]];
                    }
                    return [];
                } catch (e) {
                    return [];
                }
            });
        });

        const inlineManyCreateViewFields = allViewFields.filter(isInlineManyViewField).flatMap((f: FieldViewField) => {
            type InlineManyConfig = { useCreateView?: boolean; createViewName?: string };
            const createViewName = fromNullable(f.config)
                .chain((conf) => tryCatch(() => JSON.parse(conf) as InlineManyConfig))
                .filter((conf) => conf.useCreateView)
                .map((conf) => {
                    if (conf.createViewName) {
                        return conf.createViewName;
                    }
                    try {
                        const relatedEntity = getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST');
                        return relatedEntity && viewConfig.entities[relatedEntity]?.defaultViews?.CREATE?.name;
                    } catch (e) {
                        console.error(e);
                        return null;
                    }
                })
                .filter(Boolean)
                .toNullable();
            if (createViewName) {
                return Object.entries(getValueSetFieldsRequiredForEntity(viewConfig, createViewName, types));
            }
            return [];
        });

        const valueSets = vsFieldValueSets
            .concat(subViewFieldValueSets)
            .concat(addressWidgetValuesets)
            .concat(addressWidget2Valuesets)
            .concat(inlineManyCreateViewFields)
            .concat(vsSuggestionFieldValueSets)
            .filter(([f, vs]) => isString(vs))
            .reduce<{ [field: string]: string }>((prev, [field, valueSetCode]) => {
                prev[field] = valueSetCode;
                return prev;
            }, {});
        return valueSets;
    },
);
export const getValueSetCodesRequiredForEntity = (viewConfig: ViewConfig, _viewName: string): string[] => {
    return uniq(Object.values(getValueSetFieldsRequiredForEntity(viewConfig, _viewName, 'BOTH')));
};

const emptyArr = [];
export const findTabsWithErrors = <Props extends { form?: string; viewName: string; overrideViewConfig?: ViewConfig }>(
    state: RootState,
    props: Props,
    collectErrorsImpl = collectErrors,
): string[] | undefined => {
    const viewConfig = props.overrideViewConfig || state.viewConfig;
    const viewName = props.viewName;
    const view = getView(viewConfig, viewName);
    return findTabsWithErrorsFromViewDef(state, view, props.form, collectErrorsImpl);
};
export const findTabsWithErrorsFromViewDef = (
    state: RootState,
    view: View,
    formId?: string,
    collectErrorsImpl = collectErrors,
): string[] | undefined => {
    const errors = collectErrorsImpl(state, formId);
    const toReturn = viewHasTabs(view)
        ? getTabsTitlesFromView(view).filter((tabKey) =>
              getViewFieldsForTab(view.tabs![tabKey]).some(
                  (fieldObj) =>
                      isFieldViewField(fieldObj) &&
                      (errors[fieldObj.field] ||
                          errors[`${fieldObj.field}Ids`] ||
                          errors[`${fieldObj.field}Id`] ||
                          // added the below to support 'verificationStatusCode' (address field registered name). Might cause collisions in rare cases.
                          errors[`${fieldObj.field}Code`]),
              ),
          )
        : undefined;
    if (toReturn && toReturn.length === 0) {
        return emptyArr;
    } else {
        return toReturn;
    }
};

export const getRestUrl: (entityName: string) => (state: RootState) => string | null = (entityName) => (state) =>
    state.viewConfig &&
    state.viewConfig.entities &&
    state.viewConfig.entities[entityName] &&
    state.viewConfig.entities[entityName].restUrl;

export const getMenus = (viewConfig: ViewConfig): MenuItem[] => viewConfig.menus;

export const translateRoute = (
    viewConfig: ViewConfig,
    route?: string | null,
    viewName?: string | null,
): string | null => {
    if (viewConfig && !route && !viewName) {
        // console.error('Falsy view and route while translating route for MenuItem');
    }
    const view = viewName && viewConfig.views[viewName];
    return view?.route ?? (route as string); // one or the other will be a string
};

export const getLogin = (viewConfig: ViewConfig): string | null => viewConfig.user?.login ?? null;
export const getEmail = (viewConfig: ViewConfig): string | null => viewConfig.user?.email ?? null;
export const getLoginName = (viewConfig: ViewConfig): string | null =>
    viewConfig.user ? viewConfig.user.firstName : null;

export const allowsDelete = (accessLevel: number): boolean => accessLevel >= 5;
export const allowsMerge = (accessLevel: number): boolean => accessLevel >= 4;
export const allowsEdit = (accessLevel: number): boolean => accessLevel >= 3;
export const allowsCreate = (accessLevel: number): boolean => accessLevel >= 2;

export const getAccessLevelForEntity = (viewConfig: ViewConfig, entityName: string): number => {
    return viewConfig.entities[entityName].accessLevel;
};

export const getViewConfig =
    () =>
    (state: RootState): ViewConfig => {
        const viewConfig = state.viewConfig;
        return viewConfig;
    };

export const getEntityValidations = () => (state: RootState) => state.entityValidations;

export const evaluatePreFilter = (viewConfig: ViewConfig) => (prefilter: string | true | false | null | number) => {
    if (typeof prefilter === 'boolean' || typeof prefilter === 'number' || prefilter === null) {
        return some(prefilter);
    }
    return fromEither(
        tryCatch2v(
            () => {
                return applyToBracketedExpressions(
                    (exp) => {
                        const result = evaluateExpression(
                            exp,
                            {
                                user: produce(viewConfig.user, (draftState) => {
                                    if (draftState.properties) {
                                        if (!draftState.properties.organizationId) {
                                            draftState.properties.organizationId = null;
                                        }
                                    }
                                    return draftState;
                                }),
                            },
                            {},
                        );
                        return `${result}`;
                    },
                    '<<',
                    '>>',
                )(prefilter);
            },
            (e) => {
                console.error(e);
                return e;
            },
        ),
    );
};
export const getAllPrefilters = (
    viewConfig: ViewConfig,
    resource: string,
    viewName: string,
    filterBy: 'VISIBLE_WITHOUT_DEFAULT' | 'NOT_VISIBLE' | 'NON_DEFAULT' | 'DEFAULT_VALUE' | 'ALL' = 'ALL',
): {} => {
    let allConfigs = {};
    const [viewIndex, extraFields] = getViewIndexAndAdditionalConfigFields(
        viewName,
        viewConfig,
        'KEEP_LINKEDX_TYPE',
        'WRITE',
    );
    const searchFields = viewConfig.views[viewIndex].searchFields;

    const processField = (field: FieldViewField) => {
        const config: string = field.config;
        if (config && field.widgetType !== 'EXPRESSION') {
            const evaluatedPreFilter = fromPredicate(Boolean)(config)
                .map(JSON.parse)
                .mapNullable((conf: any): string => {
                    const isTrue = (value: unknown) => value === true || value === 'true';
                    const isFalse = (value: unknown) => value === false || value === 'false';
                    if (
                        filterBy === 'ALL' ||
                        (filterBy === 'VISIBLE_WITHOUT_DEFAULT' && isTrue(conf.visible)) ||
                        (filterBy === 'NOT_VISIBLE' && isFalse(conf.visible)) ||
                        (filterBy === 'DEFAULT_VALUE' && conf.visible === 'default') ||
                        (filterBy === 'NON_DEFAULT' && conf.visible !== 'default')
                    ) {
                        return conf.prefilter;
                    }
                    return null;
                })
                .chain(evaluatePreFilter(viewConfig))
                .chain(
                    fromPredicate((value) =>
                        Boolean(
                            (typeof value === 'string' && value) ||
                                typeof value === 'number' ||
                                value === null ||
                                typeof value === 'boolean',
                        ),
                    ),
                )
                .toUndefined();
            if (typeof evaluatedPreFilter !== 'undefined') {
                const modifiedFieldName = (() => {
                    const fieldName = field.field;
                    const searchType = field.searchType;
                    const f = isValueSetField(viewConfig, resource, fieldName, 'TRAVERSE_PATH')
                        ? fieldName.includes('.')
                            ? `${fieldName}.code`
                            : `${fieldName}Code`
                        : isValueSetManyField(viewConfig, resource, fieldName, 'TRAVERSE_PATH')
                        ? `${fieldName}.code`
                        : isRefOneField(viewConfig, resource, fieldName, 'TRAVERSE_PATH')
                        ? `${fieldName}Id`
                        : fieldName;
                    return searchType ? `${f}__${searchType}` : f;
                })();
                allConfigs[modifiedFieldName] = evaluatedPreFilter;
            }
        }
    };
    [...Object.values(searchFields ?? {}), ...(extraFields ?? [])].forEach(processField);

    return allConfigs;
};

export const showRecentlyViewed = (viewConfig: ViewConfig, viewName: string) => {
    return fromNullable(viewConfig.views)
        .mapNullable((views) => views[viewName])
        .chain((v) => fromPredicate<string>(Boolean)(v.config))
        .chain((c) => tryCatch(() => JSON.parse(c)))
        .mapNullable((conf) => conf.showRecentlyViewed as number)
        .getOrElse(0);
};

export const getValidationExpForEntity = (viewConfig: ViewConfig, entityName: string): string | null | undefined =>
    viewConfig.entities[entityName].validationExp;

export const getValidationExpForSearch = (viewConfig: ViewConfig, viewName?: string) => {
    const config = viewName && viewConfig.views[viewName].config;
    if (config) {
        const fullConfig = JSON.parse(config);

        const exp = JSON.stringify(fullConfig.searchValidations);

        return exp;
    }

    return;
};

export const getViewConfiguration = (viewConfig: ViewConfig, viewName: string) => {
    if (viewName && viewConfig.views[viewName].config) {
        const fullConfig = viewConfig.views[viewName].config;

        return fullConfig;
    }

    return;
};

/**
 * strips off 'Ids', 'Code', etc, IFF the path resolves to a field without it
 */
export const getFieldSourceFromPath = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    rootEntity: string,
    path: string,
) => {
    const possibleBasePath = path.endsWith('Id')
        ? path.slice(0, -2)
        : path.endsWith('Code')
        ? path.slice(0, 'Code'.length * -1)
        : path.endsWith('Ids')
        ? path.slice(0, -3)
        : path.endsWith('Codes')
        ? path.slice(0, 'Codes'.length * -1)
        : path;
    try {
        if (
            isFieldPathToAppendIdToSource(viewConfig, rootEntity)(possibleBasePath) ||
            isFieldPathToAppendIdsToSource(viewConfig, rootEntity)(possibleBasePath)
        ) {
            return possibleBasePath;
        }
    } catch (e) {
        // if it's an invalid path, return the same.
        return path;
    }
    return path;
};

export const isFieldPathToAppendIdToSource =
    <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig, baseEntity: string) =>
    (source: string) => {
        return (
            isRefOneField(viewConfig, baseEntity, source, 'TRAVERSE_PATH') ||
            isValueSetField(viewConfig, baseEntity, source, 'TRAVERSE_PATH')
        );
    };
export const isFieldToAppendIdToSource = (viewConfig: ViewConfig) => (baseEntity: string) => (f: FieldViewField) => {
    return isFieldPathToAppendIdToSource(viewConfig, baseEntity)(f.field);
};

export const isFieldPathToAppendIdsToSource =
    <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig, baseEntity: string) =>
    (source: string) => {
        return (
            isRefManyManyField(viewConfig, baseEntity, source, 'TRAVERSE_PATH') ||
            isValueSetManyField(viewConfig, baseEntity, source, 'TRAVERSE_PATH')
        );
    };

export const isFieldToAppendIdsToSource = (viewConfig: ViewConfig) => (baseEntity: string) => (f: FieldViewField) => {
    return isFieldPathToAppendIdsToSource(viewConfig, baseEntity)(f.field);
};
export const getAdjustedFieldSource =
    (viewConfig: ViewConfig) =>
    <View extends { entity: string }>(view: View) =>
    (f: FieldViewField, widgetKey?: string) => {
        const fieldKey = widgetKey || f.field;

        if (f.widgetType === 'ADDRESS' && fieldKey) {
            return fieldKey.endsWith('Code') ? fieldKey : `${fieldKey}Code`;
        }
        const appendId = isFieldToAppendIdToSource(viewConfig)(view.entity);
        const appendIds = isFieldToAppendIdsToSource(viewConfig)(view.entity);
        return appendId(f) ? `${fieldKey}Id` : appendIds(f) ? `${fieldKey}Ids` : fieldKey;
    };

export const areActionsHidden = (viewName, viewConfig) => {
    if (viewConfig.views[viewName] && viewConfig.views[viewName].config) {
        const parsedHideActions = JSON.parse(viewConfig.views[viewName].config).hideActions;
        if (parsedHideActions) {
            return parsedHideActions;
        }
    }
    return false;
};

export const getDefaultSort = (viewConfig: ViewConfig, viewName: string, defaultOrder: 'ASC' | 'DESC' = 'ASC') => {
    const view = viewConfig.views[viewName];
    if (!view) {
        return;
    }
    const overrideSecondarySort = getOverrideSecondarySorts(view)
        .chain((backupSorts) => (backupSorts.length > 0 ? some<(typeof backupSorts)[0]>(backupSorts[0]) : none))
        .chain((overrideSecondarySort) =>
            fromPredicate((s: typeof overrideSecondarySort) => Boolean(s.field && s.direction))(overrideSecondarySort),
        )
        .map(({ field, direction }) => ({
            field,
            order: direction,
        }));
    const getAdjustedSource = getAdjustedFieldSource(viewConfig)(view);
    const sortableFieldKeys = Object.keys(view.fields).filter(
        (fieldKey) =>
            (view.fields[fieldKey] as SearchField).sortDir && (view.fields[fieldKey] as SearchField).sortOrder,
    );
    const firstSortKey =
        sortableFieldKeys.length > 0
            ? minBy(sortableFieldKeys, (key) => (view.fields[key] as SearchField).sortOrder)
            : null;
    return overrideSecondarySort.isSome()
        ? overrideSecondarySort.value
        : firstSortKey
        ? {
              field: getAdjustedSource(view.fields[firstSortKey] as FieldViewField),
              order: (view.fields[firstSortKey] as SearchField).sortDir || defaultOrder,
          }
        : undefined;
};
export const getCustomViewName =
    (viewType: 'SHOW' | 'EDIT' | 'CREATE' | 'LIST', throwException: boolean = true) =>
    (resource: string, viewConfig: ViewConfig, viewItemConfig?: string): any => {
        return fromPredicate<string>(Boolean)(viewItemConfig)
            .chain((config) => tryCatch(() => JSON.parse(config)))
            .mapNullable(
                (config: {
                    viewName?: string;
                    showViewName?: string;
                    createViewName?: string;
                    editViewName?: string;
                    // to depreciate:
                    viewOverride?: { show?: string; edit?: string; create?: string; list?: string };
                }) => {
                    switch (viewType) {
                        case 'CREATE':
                            return config.createViewName ?? config.viewOverride?.create;
                        case 'EDIT':
                            return config.editViewName ?? config.viewOverride?.edit;
                        case 'LIST':
                            return config.viewName ?? config.viewOverride?.list;
                        case 'SHOW':
                            return config.showViewName ?? config.viewOverride?.show;
                    }
                },
            )
            .getOrElseL(() => {
                return fromNullable(viewConfig.entities[resource].defaultViews)
                    .mapNullable((defaultViews) => defaultViews[viewType])
                    .mapNullable((vt) => vt.name)
                    .getOrElseL(() => {
                        if (throwException) {
                            throw new Error(`Could not get a default viewName for "${resource}, ${viewType}"`);
                        }
                        return undefined;
                    });
            });
    };

export const applyNoWrap = (config) => {
    if (config) {
        const parsedConfig = JSON.parse(config);
        if (parsedConfig && parsedConfig.noWrap) {
            return parsedConfig.noWrap;
        }
    }
    return false;
};
