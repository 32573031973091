import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import getAdhocFieldsExpected, { getAdhocFieldsForView, EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector, { visibilityExpressionsSelector } from './util/recordSelector';
import forceBooleanFieldsBooleanAndArrayFieldsArrays from './util/enforceBoolAndArrayValues';
import { getAllValuesetFields, getExpressions } from './util/entityVisExp';
import createFormContext from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import mapFieldsToWidgets from './util/mapFieldsToWidgets';
import { CasetivityViewContext } from 'util/casetivityViewContext';
import getProviderAndHocWithViewContext from './util/getProviderAndHocWithViewContext';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';
import getAdhocVariablesContextSelector, { nullAdhocVariablesContext } from './util/getVariablesContextSelector';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';

export interface FCPProps {
    viewName: string;
    record: { id: string; entityType: string };
    overrideViewConfig?: ViewConfig;
    viewContext: CasetivityViewContext;
    formId?: undefined;
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
        conceptExps?: EntityFieldConceptExps[0];
    };
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    entityFormContextRef?: EntityFormContextRef;
}

const emptyObj = {};

const conceptExpressionsSelector = (state: RootState, props: FCPProps): EntityFieldConceptExps[0] =>
    props.overrides?.conceptExps || state.entityConceptExps[props.viewName] || emptyObj;

const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const recordSelector = createRecordSelector();
    const getValueSets = createGetValueSets();
    const formContextEvaluatorSelector = createSelector(
        visibilityExpressionsSelector,
        conceptExpressionsSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => props.viewContext,
        (state: RootState, props: FCPProps) => state.printMode,
        (visConfig, conceptConfig, viewConfig, viewName, viewContext, printMode) => {
            const adhocFieldsExpected = getAdhocFieldsExpected(viewConfig, viewName, {}, getAdhocFieldsForView);
            const fieldsToWidgets = mapFieldsToWidgets(viewConfig, viewName, adhocFieldsExpected, printMode);
            const allValuesetFields = getAllValuesetFields(visConfig, {}, {}, {});
            const visibilityExpressions = getExpressions(visConfig);
            const conceptExpressions = Object.fromEntries(
                Object.values(conceptConfig).map((ca) => [ca.fieldName, ca.expression]),
            );
            return new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: conceptExpressions,
                    valueset1Fields: allValuesetFields,
                    visibilityExpressions,
                    editabilityExpressions: {},
                    tableExpressions: {},
                    useBackingValuesRegardlessOfDisplayStatus: {
                        [EXTERNALGISID]: true,
                    },
                },
                options: {
                    viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                },
                viewConfig,
            });
        },
    );
    const adhocVariablesContextSelector = getAdhocVariablesContextSelector();
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => recordSelector(state, props),
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => adhocVariablesContextSelector(props.evaluatedAdhocSPELVariables),
        (state: RootState, props: FCPProps) => props.entityFormContextRef,
        (state: RootState, props: FCPProps) => state.printMode,
        (
            formContextEvaluator,
            values: {},
            entities: {},
            valueSets,
            viewConfig,
            viewName,
            adhocVariablesContext,
            entityFormContextRef,
            printMode,
        ) => {
            const valuesToUse = forceBooleanFieldsBooleanAndArrayFieldsArrays(viewConfig, viewName)(values);
            const { hiddenFields, fieldValues, valuesetFieldAvailableConceptIds } = formContextEvaluator.evaluate(
                valuesToUse,
                valueSets,
                valuesToUse,
                entities,
                {
                    ...adhocVariablesContext,
                    isPrintMode: () => !!printMode,
                },
            );
            const res = {
                hiddenFields,
                fieldValues,
                viewName,
                adhocVariablesContext,
                valuesetFieldAvailableConceptIds,
            };
            if (entityFormContextRef) entityFormContextRef.current = res;
            return res;
        },
    );
    return createDeepEqlSelector(formContextSelector);
};

export type ShowFormContext = ReturnType<ReturnType<typeof createFormContextSelector>>;
const defaultContext: ShowFormContext = {
    hiddenFields: {},
    fieldValues: {},
    viewName: undefined,
    valuesetFieldAvailableConceptIds: {},
    adhocVariablesContext: nullAdhocVariablesContext,
};

const { formContext, FormContextProvider: FCP } = createFormContext(createFormContextSelector, defaultContext);

const { FormContextProvider, formContextHoc } = getProviderAndHocWithViewContext(FCP);
export { formContext, formContextHoc, FormContextProvider };
