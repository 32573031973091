import {
    FlowableEntityTypeaheadField,
    FlowableMultipleEntityTypeaheadField,
    EntityDataField,
    FlowableEntityLookupField,
} from '../../../translation/types';
import EntityTypeahead, { EntityTypeaheadProps } from './';
import {
    ensureEndswithId,
    getLabel,
    isFieldFromFlowable,
    getConfigProperty,
    isEntityDataField,
    simpleGetConfProperty,
} from '../../fieldUtils';
import { GenericProps, FormFieldItem, ExternalPropsFromComponentInternalProps } from '../../props';
import { MULTIPLE_ENTITY_TYPEAHEAD } from 'fieldFactory/fieldTypes';
import OfflineMultipleEntityTypeahead from './Multiple/offline';
import MultipleEntityTypeaheadComponent, { MultipleEntityTypeaheadProps } from './Multiple';
import OfflineEntityTypeahead from './offline';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils/index';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import ViewConfig from 'reducers/ViewConfigType';
import { getExpansions } from '../../fieldUtils/reference';
import { withEvaluatedFilter } from 'fieldFactory/input/inputHigherOrderComponents/withEvaluatedFilter';
import { withNullFilteredRefoneOverride } from 'fieldFactory/input/inputHigherOrderComponents/withNullFilteredRefoneOverride';
import withValueFromFormContext from 'fieldFactory/input/inputHigherOrderComponents/withValueFromFormContext';

const getEntityTypeahead = (
    fieldDefinition:
        | FlowableEntityLookupField
        | FlowableEntityTypeaheadField
        | FlowableMultipleEntityTypeaheadField
        | EntityDataField,
    propConfiguration: GenericProps,
    viewConfig: ViewConfig,
    configuration?: { ignoreFilters?: boolean; isForSearch?: boolean; isOffline?: boolean },
): FormFieldItem<EntityTypeaheadProps | MultipleEntityTypeaheadProps> => {
    const isMultiple = fieldDefinition.type === MULTIPLE_ENTITY_TYPEAHEAD;
    const { ignoreFilters, isForSearch, isOffline } = configuration;
    const BaseComponent = (() => {
        const MET = isOffline ? OfflineMultipleEntityTypeahead : MultipleEntityTypeaheadComponent;
        const SET = isOffline ? OfflineEntityTypeahead : EntityTypeahead;
        return isMultiple ? MET : SET;
    })();
    const filter = ignoreFilters
        ? null
        : isFieldFromFlowable(fieldDefinition)
        ? fieldDefinition.params.filter
        : getConfigProperty('filter')(fieldDefinition).getOrElse(null);

    type ConfOptions = {
        hasSearch?: boolean;
        searchView?: string;
        hasCreate?: boolean;
        createView?: string;
        resultSize?: number;
    };
    const options: {
        search?: string | boolean;
        create?: string | boolean;
    } = {};
    const hasSearch = simpleGetConfProperty<ConfOptions>('hasSearch', undefined)(fieldDefinition);
    if (hasSearch) {
        options.search = simpleGetConfProperty<ConfOptions>('searchView', true)(fieldDefinition) as string | boolean;
    }
    const hasCreate = simpleGetConfProperty<ConfOptions>('hasCreate', undefined)(fieldDefinition);
    if (hasCreate) {
        options.create = simpleGetConfProperty<ConfOptions>('createView', true)(fieldDefinition) as string | boolean;
    }

    const resultSize = simpleGetConfProperty<ConfOptions>('resultSize', 10)(fieldDefinition) as number;

    const from = isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity';
    const Component = (() => {
        if (filter) {
            const PreComponent = withEvaluatedFilter(
                BaseComponent,
                filter,
                from,
                fieldDefinition['data-originaldefinition'],
            );
            return isMultiple
                ? withValueFromFormContext(from)(PreComponent)
                : withNullFilteredRefoneOverride(from)(PreComponent);
        }
        return BaseComponent;
    })();

    const expansions = filter
        ? [...getExpansionsFromFilter(filter), ...getExpansions(fieldDefinition)]
        : getExpansions(fieldDefinition);
    const props: ExternalPropsFromComponentInternalProps<EntityTypeaheadProps | MultipleEntityTypeaheadProps> = {
        ...propConfiguration,
        allowEmptyQuery: true,
        expansions,
        reference: isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1)
            : fieldDefinition.name.endsWith('.id')
            ? fieldDefinition.configuredEntity // in case we are a string field pointing to .id
            : getRefEntityName(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST'),
        source: ensureEndswithId(fieldDefinition.name),
        label: getLabel(fieldDefinition),
        resultSize,
        ...options,
    };
    if (fieldDefinition.type === MULTIPLE_ENTITY_TYPEAHEAD && isEntityDataField(fieldDefinition)) {
        if (isForSearch) {
            props.source = fieldDefinition.name + '.id';
        } else {
            (props as ExternalPropsFromComponentInternalProps<MultipleEntityTypeaheadProps>).mode =
                'SetRelationshipOnAdd';
            props.source = `${fieldDefinition.name}Ids`;
        }
    } else {
        props.source = fieldDefinition.name.endsWith('.id')
            ? fieldDefinition.name
            : `${fieldDefinition.name}${isEntityDataField(fieldDefinition) ? 'Id' : ''}`;
    }
    if (isForSearch) {
        (props as ExternalPropsFromComponentInternalProps<EntityTypeaheadProps>).fetchOnMount = true;
    }

    return {
        Component,
        props,
    };
};
export default getEntityTypeahead;
