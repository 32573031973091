import React, { useContext, useMemo } from 'react';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as entityShowFormContext } from 'components/generics/form/EntityFormContext/Show';
import { evaluateFilterString } from '../evaluteFilterString';
import { processContext } from 'bpm/components/processContext';
import { RootState } from 'reducers/rootReducer';
import { fromNullable, tryCatch, fromPredicate } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { createGetEntities, createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import { flowablePreprocessValuesForEval, entityPreprocessValuesForEval } from 'expressions/formValidation';
import {
    tableRowContext,
    getTableRowContext,
    TableRowContext,
} from 'fieldFactory/input/components/EditableTable/util/tableRowContext';
import get from 'lodash/get';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { purifyHtmlMoreStrict } from 'fieldFactory/display/components/HtmlDisplay';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import useViewConfig from 'util/hooks/useViewConfig';
import formTypeContext from 'components/generics/form/formTypeContext';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import { FieldViewField } from '@mkanai/casetivity-shared-js/lib/view-config/views';
import { ViewItemFilterExpressionsGeneratedType } from 'viewConfigCalculations/filterExpressions/ViewItemFilterExpressionsGeneratedType';
import useEntities from 'util/hooks/useEntities';
import useEvalContext from 'expressions/Provider/hooks/useEvalContext';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { useBackrefProperties } from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import {
    defaultReportFormContext,
    formContext as reportFormContext,
} from 'report2/components/FormContext/ReportFormContext';
import merge from 'lodash/merge';
interface WithTaskFormFieldProps {
    taskId: string;
    trc?: TableRowContext;
    formContext: ReturnType<typeof evaluateContext2>;
    children: (processedFieldValues: {}) => JSX.Element | null;
}

type Selector = (state: RootState, props: WithTaskFormFieldProps) => { processedValues: any };
let globalCurrentSelector: Selector = null;

const getMakeMapStateToProps = (isGloballyInitialized?: boolean) => () => {
    const emptyArray = [];
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();

    const getValuesSelector = createSelector(
        (state: RootState, props: WithTaskFormFieldProps) =>
            fromNullable(state.taskForms[props.taskId])
                .mapNullable((f) => f.fields)
                .getOrElse(emptyArray),
        getEntities,
        getValueSets,
        (state: RootState) => state.viewConfig.application.dateFormat,
        (state: RootState, props: WithTaskFormFieldProps) => props.formContext,
        (state: RootState, props: WithTaskFormFieldProps) => props.trc,

        (formFields, entities, valueSets, dateFormat, formContext, tableRowContext) => {
            // console.log('selector being run...');
            const rowData = tableRowContext ? getTableRowContext(tableRowContext, formContext).fieldValues : {};

            const res = flowablePreprocessValuesForEval(
                { ...formContext.variables, ...formContext.fieldValues, ...rowData },
                formFields,
                entities,
                { dateFormat },
                valueSets,
            );
            return res;
        },
    );
    const mapStateToProps = (state: RootState, props: WithTaskFormFieldProps) => {
        return {
            processedValues:
                /* Because there is only one task-form per page, we can assume we can reuse our caching selector
            for all instances that aren't in the context of a table row!
            */
                !props.trc && !isGloballyInitialized
                    ? globalCurrentSelector(state, props).processedValues
                    : getValuesSelector(state, props),
        };
    };
    return mapStateToProps;
};
globalCurrentSelector = getMakeMapStateToProps(true)();

interface WithTaskFormFieldsComponentProps
    extends WithTaskFormFieldProps,
        ReturnType<ReturnType<ReturnType<typeof getMakeMapStateToProps>>> {}
const WithTaskFormFieldsComponent: React.SFC<WithTaskFormFieldsComponentProps> = (props) => {
    const { trc } = props;
    if (trc) {
        const { tableId, columns, rowGetExp } = trc;
        const nullInitializedRowData = {};
        columns.forEach((c) => {
            nullInitializedRowData[`${tableId}_c_${c.id}`] = null;
        });
        const tableRowMappedData = fromNullable(get(props.processedValues, rowGetExp, null))
            .map((rowData) => {
                const newData = {};
                Object.entries(rowData).forEach(([k, v]) => {
                    newData[`${tableId}_c_${k}`] = typeof v === 'undefined' ? null : v;
                });
                return newData;
            })
            .getOrElse({});

        return props.children({
            ...nullInitializedRowData,
            ...props.processedValues,
            ...tableRowMappedData,
        });
    }
    return props.children(props.processedValues);
};
const WithTaskFormFields = connect(getMakeMapStateToProps())(WithTaskFormFieldsComponent);

const _evaluate = (fieldValues, filterString) => {
    return evaluateFilterString(filterString, fieldValues, purifyHtmlMoreStrict);
};

const RenderChildrenTaskForm: React.SFC<{
    templateString: string;
    children: (evaluatedString: string) => JSX.Element | null;
}> = (props) => {
    const rfc = useContext(reportFormContext);

    const fc = useContext(taskFormContext);
    const trc = useContext(tableRowContext);
    const { taskId } = useContext(processContext);
    const context = useEvalContext();
    const isReportFormContext = rfc !== defaultReportFormContext;

    if (isReportFormContext) {
        return props.children(_evaluate(merge({}, rfc.initialValues, rfc.fieldValues), props.templateString));
    }

    return (
        <WithTaskFormFields taskId={taskId} formContext={fc} trc={trc}>
            {(_fv) => {
                const fv = Object.fromEntries(
                    Object.entries(_fv).map(([k, v]) => {
                        if (typeof v === 'undefined') {
                            return [k, null];
                        }
                        return [k, v];
                    }),
                );
                return props.children(_evaluate({ ...context, ...fv }, props.templateString));
            }}
        </WithTaskFormFields>
    );
};
type EvalTemplateInEntityFormProps = {
    templateString: string;
    initial?: boolean;
    'data-originaldefinition'?: string;
    context:
        | {
              type: 'source';
              source: string;
          }
        | {
              type: 'adhoc';
              expansionsRequired: string[];
              dataPaths: string[];
              valuesetFieldsRequired: {
                  [key: string]: string;
              };
          };
};
export const useEvalTemplateInEntityForm = (props: EvalTemplateInEntityFormProps): string => {
    const efc = useContext(entityFormContext);
    const sfc = useContext(entityShowFormContext);
    const currentFormTypeContext = useContext(formTypeContext);
    const fc = currentFormTypeContext === 'SHOW' ? sfc : efc;
    const backref = useBackrefProperties(fc['initialValues'] ?? null);
    const entities = useEntities();
    const valueSets = useSelector((state: RootState) => state.valueSets);
    const viewConfig = useViewConfig();
    const officialViewConfig = useSelector((state: RootState) => state.viewConfig);
    const filterEvaluationContextFromStore = useSelector((state: RootState) =>
        fromNullable(state.viewItemFilterExps[fc.viewName])
            .mapNullable((ve) => (props.context.type === 'source' ? ve[props.context.source] : undefined))
            .toUndefined(),
    );
    const originalDefinition = props['data-originaldefinition'];
    const filterEvaluationContext: {
        expansionsRequired: string[];
        dataPaths: string[];
        valuesetFieldsRequired: {
            [vs: string]: string;
        };
    } = useMemo(() => {
        if (viewConfig === officialViewConfig && filterEvaluationContextFromStore) {
            return filterEvaluationContextFromStore;
        }
        // if we have local modifications to the viewConfig in our component tree, then we have to recalculate our context
        if (
            props.context.type === 'source' &&
            // SHOW views don't have these filters, so I think the below is fine.
            currentFormTypeContext !== 'SHOW' &&
            efc.viewName &&
            viewConfig.views[efc.viewName] &&
            originalDefinition
        ) {
            const { source } = props.context;
            const field = tryCatch(() => JSON.parse(originalDefinition) as FieldViewField);
            return field
                .mapNullable((f) => f.config)
                .chain((c) => tryCatch(() => JSON.parse(c)))
                .chain((c) =>
                    fromPredicate<string[]>((els) => els.length > 0)([c.filter, c.listFilter].filter(Boolean)),
                )
                .map((filters) =>
                    filters.map((filter) =>
                        parseTemplateString(filter, viewConfig, viewConfig.views[efc.viewName].entity),
                    ),
                )
                .map((els) =>
                    els
                        .map((e): ViewItemFilterExpressionsGeneratedType[0][0] => ({
                            ...e,
                            fieldName: source,
                            searchEntity: field.foldL(
                                () => {
                                    throw new Error(
                                        'field should be found if we are calculating search entity from it',
                                    );
                                },
                                (f) => getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST'),
                            ),
                        }))
                        .reduce(
                            (prev, curr) => {
                                return {
                                    expansionsRequired: prev.expansionsRequired.concat(curr.expansionsRequired),
                                    dataPaths: prev.dataPaths.concat(curr.dataPaths),
                                    valuesetFieldsRequired: Object.assign(
                                        prev.valuesetFieldsRequired,
                                        curr.valuesetFieldsRequired,
                                    ),
                                };
                            },
                            {
                                expansionsRequired: [],
                                dataPaths: [],
                                valuesetFieldsRequired: {},
                            },
                        ),
                )
                .toUndefined();
        }
        return undefined;
    }, [
        viewConfig,
        officialViewConfig,
        originalDefinition,
        efc.viewName,
        currentFormTypeContext,
        props.context,
        filterEvaluationContextFromStore,
    ]);
    const values =
        (fc === sfc || props.initial) && // we are in a show form, or want the unedited data values only
        (fc.fieldValues as any).entityType &&
        (fc.fieldValues as any).id // in the layout-builder, we don't provider a 'particular' entity, so we need to skip this.
            ? denormalizeEntitiesByPaths(
                  entities,
                  (props.context.type === 'adhoc'
                      ? props.context.expansionsRequired
                      : filterEvaluationContext
                      ? filterEvaluationContext.expansionsRequired
                      : []
                  ).flatMap((p) => (p.includes('.') ? [p.slice(0, p.lastIndexOf('.'))] : [])),
                  viewConfig,
                  (fc.fieldValues as any).entityType,
                  (fc.fieldValues as any).id,
              )
            : fc.fieldValues;
    const evaluationContext = useEvalContext();
    const context =
        props.context.type === 'adhoc'
            ? entityPreprocessValuesForEval(
                  values,
                  props.context.dataPaths,
                  props.context.valuesetFieldsRequired,
                  entities,
                  { viewContext: 'ENTITY', backref },
                  valueSets,
              )
            : filterEvaluationContext
            ? entityPreprocessValuesForEval(
                  values,
                  filterEvaluationContext.dataPaths,
                  filterEvaluationContext.valuesetFieldsRequired,
                  entities,
                  { viewContext: 'ENTITY', backref },
                  valueSets,
              )
            : {};

    const res = _evaluate({ ...evaluationContext, ...context, ...fc.adhocVariablesContext }, props.templateString);
    return res;
};
const RenderChildrenEntityForm: React.FC<
    EvalTemplateInEntityFormProps & {
        children: (evaluatedString: string) => JSX.Element | null;
    }
> = ({ children, ...props }) => {
    const res = useEvalTemplateInEntityForm(props);
    return children(res);
};

export type WithStringEvaluatedInFormContextProps =
    | {
          from: 'Flowable';
          templateString: string;
          children: (evaluatedString: string) => JSX.Element | null;
      }
    | {
          from: 'Entity';
          context: EvalTemplateInEntityFormProps['context'];
          initial?: boolean;
          templateString: string;
          children: (evaluatedString: string) => JSX.Element | null;
          'data-originaldefinition'?: string;
      };
const WithStringEvaluatedInFormContext: React.FC<WithStringEvaluatedInFormContextProps> = (props) => {
    const templateWithMessages = useEvaluatedFormattedMessage(props.templateString);
    return props.from === 'Flowable' ? (
        <RenderChildrenTaskForm templateString={templateWithMessages}>{props.children}</RenderChildrenTaskForm>
    ) : (
        <RenderChildrenEntityForm
            templateString={templateWithMessages}
            context={props.context}
            data-originaldefinition={props['data-originaldefinition']}
        >
            {props.children}
        </RenderChildrenEntityForm>
    );
};
export default WithStringEvaluatedInFormContext;
