import { storeRegistry } from 'configureStore/storeRegistry';
import { RootState } from 'reducers/rootReducer';
import { Store, Unsubscribe } from 'redux';

export type HandlePrintStatusChange = (state: 'pending' | 'complete' | 'error') => void;

/**
 * For some reason, the header on the first page doesn't always show up.
 * As a workaround, we can insert it ourselves.
 *
 * Make sure to check this still works (or, hopefully, the original issue was fixed) when we upgrade pagedjs- the css class names might change beneath us.
 */
const ensureInsertFirstCenterHeader = () => {
    const content = document.getElementsByClassName('print-header-center')?.[0]?.textContent;
    if (!content) {
        return;
    }
    const style = document.createElement('style');
    style.type = 'text/css';

    const cssRule =
        '.pagedjs_page.pagedjs_first_page .pagedjs_margin-top-center > .pagedjs_margin-content::after { content: "' +
        content +
        '"; }';

    if ('styleSheet' in style) {
        (style as any).styleSheet.cssText = cssRule;
    } else {
        style.appendChild(document.createTextNode(cssRule));
    }

    document.head.appendChild(style);
};

/**
 * These break-inside: avoid styles are breaking paged-js.
 *
 * Let's _keep the styles, in case the user just calls print without using pagedjs_
 *
 * But remove them once we are committing to pagedjs.
 */
function removeBreakInsideAvoidStyle(): void {
    // Get all elements in the document
    const allElements: NodeListOf<Element> = document.querySelectorAll('*');

    allElements.forEach((element: Element) => {
        const htmlElement = element as HTMLElement;

        // Check if the element has inline style for 'break-inside'
        if (htmlElement.style.breakInside === 'avoid') {
            htmlElement.style.breakInside = ''; // Remove the style
        }
    });

    // Check and modify CSS rules in stylesheets (for external and internal stylesheets)
    Array.from(document.styleSheets).forEach((sheet: CSSStyleSheet) => {
        try {
            const cssRulesList: CSSRuleList = sheet.cssRules;
            Array.from(cssRulesList).forEach((rule: CSSRule) => {
                const styleRule = rule as CSSStyleRule;
                if (styleRule.style && styleRule.style.breakInside === 'avoid') {
                    styleRule.style.breakInside = ''; // Remove the style
                }
            });
        } catch (e) {
            // Catch and ignore cross-origin issues
            console.warn('Could not modify styles from a cross-origin stylesheet');
        }
    });
}

const insertRefreshNotificationToDom = () => {
    const alertDiv = document.createElement('div');

    alertDiv.innerHTML = 'To exit "print mode", please reload the page.';

    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.right = '10px';
    alertDiv.style.backgroundColor = 'rgb(229, 246, 253)';
    alertDiv.style.color = 'rgb(1, 67, 97)';
    alertDiv.style.border = '1px solid rgb(1, 67, 97)';
    alertDiv.style.padding = '10px';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
    alertDiv.style.zIndex = '1000';

    // Add a "Reload" button
    const reloadButton = document.createElement('button');
    reloadButton.textContent = 'Reload';
    reloadButton.style.marginLeft = '10px';
    reloadButton.style.color = 'rgb(1, 67, 97)';
    reloadButton.style.border = 'none';
    reloadButton.style.backgroundColor = 'transparent';
    reloadButton.style.cursor = 'pointer';
    reloadButton.style.fontWeight = '500';

    // Append the reload button to the alert div
    alertDiv.appendChild(reloadButton);

    // Event listener to reload the page when the reload button is clicked
    reloadButton.onclick = function () {
        window.location.reload();
    };

    // Append the alert div to the body
    document.body.appendChild(alertDiv);

    // Add CSS to hide the alert during printing
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
    @media print {
        #alertDiv {
            display: none;
        }
    }
`;
    document.head.appendChild(styleSheet);

    // Set an ID for the alert div for the media query to work
    alertDiv.id = 'alertDiv';
};

class PrintController {
    private subscriptions: HandlePrintStatusChange[] = [];
    private store: Store<RootState>;
    private to: NodeJS.Timeout;
    private storeUnSub: Unsubscribe;
    public constructor(store: Store<RootState>) {
        this.store = store;
        this.subscribeToPrint = this.subscribeToPrint.bind(this);
        this.unsubscribeToPrint = this.unsubscribeToPrint.bind(this);
        this.startPrint = this.startPrint.bind(this);
    }

    public startPrint() {
        this.subscriptions.forEach((cb) => cb('pending'));

        const maxTries = 180;
        let tries = 0;
        // number of times we've iterated, and seen loading=0.
        // We track this so we stop only if loading=0 N consecutive times, with some time delta apart.
        // That way we don't abort early by some unlikely timing where it stops briefly and restarts
        let consecutiveNotLoadingCount = 0;
        const STOP_ON_CONSECUTIVE_COUNTS = 3;
        this.to = setInterval(() => {
            tries += 1;
            if (tries >= maxTries) {
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('error'));
            }
            if (!this.store.getState().admin.loading) {
                consecutiveNotLoadingCount += 1;
                if (consecutiveNotLoadingCount < STOP_ON_CONSECUTIVE_COUNTS) {
                    // continue
                    return;
                }
                // finally.
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('complete'));
                setTimeout(() => {
                    if (window['CANCEL_PRINT']) {
                        return;
                    }
                    removeBreakInsideAvoidStyle();
                    // dynamically import pagedjs, since it's quite large.
                    import('pagedjs')
                        .then((pagedjs) => {
                            // if we weren't asked to have custom stuff involving page numbers, we would just print here.
                            // window.print();
                            // instead, we need to use pagedjs as an irreversible step below (we can't go back and recover our react tree)
                            let paged = new pagedjs.Previewer();
                            paged.preview().then((flow) => {
                                ensureInsertFirstCenterHeader();
                                insertRefreshNotificationToDom();
                                console.log('Rendered', flow.total, 'pages.');
                                setImmediate(() => {
                                    window.print();
                                });
                            });
                        })
                        .catch((e) => {
                            alert('Printing failed. You may need to reconnect to the network.');
                        });
                }, window['PRINT_WAIT'] ?? 200);
            } else {
                // reset and check again
                consecutiveNotLoadingCount = 0;
            }
        }, 600);
        const getCurrPath = () => this.store.getState().router.location.pathname;
        const initialPath = getCurrPath();
        this.storeUnSub = this.store.subscribe(() => {
            const currPath = getCurrPath();
            if (initialPath !== currPath) {
                clearInterval(this.to);
            }
        });
    }

    public subscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions.push(cb);
    }
    public unsubscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
}

let printController: PrintController;
export const getPrintController = () => {
    if (!printController) {
        printController = new PrintController(storeRegistry['store']);
    }
    return printController;
};
