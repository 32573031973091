import React from 'react';
import { Subtract } from 'utility-types';

export const isStringGreaterThanLength =
    (len: number) =>
    (val: any): val is string =>
        typeof val === 'string' && val.length > len;

export const flowableArrayOfObjectsToJSONStr = (val: string): string =>
    val
        .split(' ')
        .join('')
        .split('},{')
        .map((f) => f.split('}').join('"}').split('{').join('{"').split('=').join('":"').split(',').join('","'))
        .join('"},{"');

export const isTableFieldErrorMessage = (m: string) => m && m.includes('_') && m.includes(':');

/*
    we don't want to push every single change up the component tree and into reduxForm.
    As the Table grows larger, this will result in progressively worse performance.
    Instead, buffer changes from onChange, and propogate the buffed changes when onBlur is called.
*/
interface BufferChangesProps {
    error?: string[] | null;
    // ref: string;
    id: string;
    style: {};
    defaultToggled: boolean;
    label: string;
    disabled: boolean;
    onChange: (eventOrValue: unknown) => void;
    value: unknown;
    render: (
        args: Subtract<BufferChangesProps, Pick<BufferChangesProps, 'render' | 'error' | 'onChange'>> & {
            meta: any;
            input: any;
        },
    ) => JSX.Element;
    submitFailed?: boolean;
}
interface BufferChangesState {
    buffer: unknown;
    touched: boolean;
}
export class BufferChanges extends React.Component<BufferChangesProps, BufferChangesState> {
    constructor(props: BufferChangesProps) {
        super(props);
        this.state = {
            buffer: props.value || '',
            touched: false,
        };
    }
    componentWillReceiveProps(nextProps: BufferChangesProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ buffer: nextProps.value });
        }
    }
    onBlur = (maybeHasValue) => {
        const { onChange: propagateChange } = this.props;
        // if a value is provided, propagate,
        // othewise, use the buffer
        if (typeof maybeHasValue === 'undefined') {
            propagateChange(this.state.buffer);
        } else {
            propagateChange(maybeHasValue);
        }
        this.setState({
            touched: true,
        });
    };
    onChange = (eventOrValue) => {
        this.setState({
            buffer: eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue,
        });
    };
    render() {
        const { render, onChange, error, children, submitFailed, ...rest } = this.props;
        return render({
            ...rest,
            input: {
                onFocus: () => null,
                onBlur: this.onBlur,
                value: this.state.buffer,
                onChange: this.onChange,
            },
            meta: {
                submitFailed,
                touched: this.state.touched || submitFailed,
                error,
            },
        });
    }
}
