import { useCallback } from 'react';
import { AjaxError } from 'rxjs/ajax';
import useRemoteData from 'util/hooks/useRemoteData';
import { Observable } from 'rxjs';

const useService = <ServiceResponse, Args extends any[]>(req: (...args: Args) => Observable<ServiceResponse>) => {
    const { state, setSuccess, setError, setPending } = useRemoteData<ServiceResponse, string>();

    const request = useCallback(
        (...args: Args) => {
            setPending();
            const $ajax = req(...args);
            const subscription = $ajax.subscribe(
                (res) => {
                    setSuccess(res);
                },
                (error: AjaxError) => {
                    setError(error.message);
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [req, setError, setPending, setSuccess],
    );

    return [state, request] as [typeof state, typeof request];
};

export default useService;
