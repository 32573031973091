import React, { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';

import { Card, Button, CircularProgress, Theme, CardHeader, CardContent, CardActions } from '@material-ui/core';
import EnhancedRGridWithVis from 'components/generics/form/EnhancedRGridTask';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { push as pushAction } from 'connected-react-router';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import createGetOptionsSelector from 'bpm/form-context-utils/etc/getOptionsSelector';
import { useSelector, useDispatch } from 'react-redux';
import { createGetStartFormInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { reduxForm, InjectedFormProps, SubmissionError } from 'redux-form';
import memoize from 'lodash/memoize';
import { ajax, AjaxError } from 'rxjs/ajax';
import { refreshJwt, getOptions, getUrl } from 'sideEffect/services';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import { Helmet } from 'react-helmet';
import adjustValues from 'bpm/components/TaskDetail/TaskForm/adjustSubmissionValues';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import casetivityViewContext from 'util/casetivityViewContext';
import ErrorPopup from './ValidationErrorPopup';
import BpmFormExpressionTester from 'expression-tester/bpm-form';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import Outcomes2 from 'bpm/components/TaskDetail/TaskForm/Outcomes2';
import Check from '@material-ui/icons/CheckCircle';
import WarningPopup from './WarningPopup';
import { getAllRecaptchaInstances } from 'fieldFactory/input/components/Recaptcha/util/getAllRecaptchaInstances';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { InternationalizeForm } from 'bpm/internationalizeForm/useInternationalizedForm';
import { EvaluateFormattedMessage, useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { startFormContext } from './startFormContext';
import { StartFormValidateContextProvider } from 'fieldFactory/input/components/WizardControl/startFormValidateContext';
import { AsyncEventsInProgressContextProvider } from 'bpm/components/TaskDetail/asyncEventCountContext';
import { ExpressionFormField } from 'fieldFactory/translation/fromFlowable/types';
import uniqueId from 'lodash/uniqueId';
import { setAsTopView, unsetAsTopView } from 'popoverStackManagement/actions';
import useRegisterViewDetails from 'popoverStackManagement/viewDetailsStack/useRegisterViewDetails';
import useLiveTaskFormValidation from 'bpm/components/TaskDetail/TaskForm/hooks/useLiveTaskFormValidation';
import formTypeContext from 'components/generics/form/formTypeContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submissionArea: {
            marginLeft: 21,
            paddingBottom: '1em',
            overflowWrap: 'break-word',
        },
        pendingSpinner: {
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            marginRight: '1em',
            verticalAlign: 'middle',
        },
        buttonSpinner: {
            marginLeft: '1em',
        },
        responseError: {
            color: theme.palette.error.dark,
        },
    }),
);

export const getFields = (fieldFactory, formDefinition: TaskForm, options = {}, embeddedInFormId?: string) => {
    return fieldFactory({
        dataSource: DataSource.FLOWABLE,
        mode: Mode.INPUT,
        validate: true,
        connected: true,
        options,
    })(
        {
            embeddedInFormId,
            replacePeriodsInFieldName: '_',
            options: {
                fullWidth: true,
            },
            overrideFieldValueIfDisabled: true,
            shouldFetchValueset: false,
        },
        null,
        createGetOptionsSelector(formDefinition),
    )(formDefinition.fields?.filter((f) => !(f.type === 'expression' && f.value && f.value.startsWith('$=['))) ?? []);
};

interface FormProps {
    submissionError?: { [field: string]: string[] };
    children?: React.ReactNode;
    resetRecaptchas?: boolean;
    recaptchaWidgetIds: string[];
}
const Form = reduxForm<{}, FormProps>({
    enableReinitialize: true,
    shouldWarn: ({ values, nextProps, props, initialRender }) => {
        // maybe optimize this eventually if we know what the runtime cost is.
        return true;
    },
})((props: FormProps & InjectedFormProps) => {
    const { submissionError, handleSubmit, children, change, resetRecaptchas, recaptchaWidgetIds } = props;
    const prevResetRecaptchas = useRef(resetRecaptchas);
    useEffect(() => {
        if (resetRecaptchas !== prevResetRecaptchas.current) {
            if (resetRecaptchas) {
                recaptchaWidgetIds.forEach((id) => {
                    change(id, null);
                });
            }
            prevResetRecaptchas.current = resetRecaptchas;
        }
    }, [resetRecaptchas, change, recaptchaWidgetIds]);
    useEffect(() => {
        if (submissionError) {
            handleSubmit(() => {
                throw new SubmissionError(
                    Object.assign(
                        { _error: submissionError } as {
                            _error: {
                                [field: string]: string[];
                            };
                            [field: string]: any;
                        },
                        submissionError,
                    ),
                );
            })();
        }
    }, [submissionError, handleSubmit]);
    const formOnSubmit = React.useCallback((e) => {
        e.preventDefault();
        return false;
    }, []);
    return (
        <form onSubmit={formOnSubmit} autoComplete="off">
            {children}
        </form>
    );
});

type SubmissionState =
    | {
          type: 'NOT_SUBMITTING';
      }
    | {
          type: 'SUBMITTING_1';
          outcome?: string;
          outcomeDisplay?: string;
          taskFormKey?: string;
      }
    | {
          type: 'SUBMITTING_2';
          overrideWarning?: boolean;
          outcome?: string;
          outcomeDisplay?: string;
          data: {};
          taskFormKey?: string;
      }
    | {
          type: 'WARNING';
          warning: string; // e.g. 'Are you sure you want to continue?'
          request: {
              outcome?: string;
              outcomeDisplay?: string;
              data: {};
          };
      }
    | {
          type: 'BACKEND_VALIDATION_ERROR';
          error: string;
      }
    | {
          type: 'SUCCESS';
      }
    | {
          type: 'VALIDATION_FAILED';
          formErrors: {
              [field: string]: string[];
          };
      }
    | {
          type: 'ERROR';
          error: AjaxError;
      };

interface StartFormProps {
    renderBeforeOutcomes?: () => JSX.Element;
    noOutcomes?: boolean;
    formDefinition: TaskForm;
    businessKey: string;
    passThroughValues?: {};
    offlineMode?: boolean;
    useInternalFormId?: true;
    interceptSubmitCb?: (body: {
        processDefinitionKey: string;
        outcome: string;
        outcomeDisplay: string;
        values: {};
        overrideWarning: boolean;
    }) => void;
    interceptSuccess?: (redirect?: string) => void;
    taskFormKey?: string;
}
interface StartFormComponentProps extends StartFormProps {
    formId: string;
}

const useSubmit = (props: StartFormProps, fields: any) => {
    const { formDefinition, offlineMode = false, interceptSubmitCb, interceptSuccess, taskFormKey, noOutcomes } = props;
    const startFormContext = useContext(formContext);
    const dispatch = useDispatch();
    const [state, setState] = useState<SubmissionState>({ type: 'NOT_SUBMITTING' });
    const entities = useEntities();
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const valueSets = useValueSets();

    useEffect(() => {
        if (state.type === 'SUBMITTING_1') {
            const validateData = (includeFieldLevelErrors: boolean) =>
                validate({
                    outcome: state.outcome,
                    formDefinition,
                    entities,
                    valuesAfterExpressionsApplied: startFormContext.fieldValues,
                    visibleAndEditableFields: startFormContext.visibleAndEditableFields,
                    viewConfig,
                    ignoreFieldLevel: !includeFieldLevelErrors,
                    fields,
                    valueSets,
                });
            const allErrors = validateData(true);
            const hasErrors = Object.keys(allErrors).length > 0;
            if (hasErrors) {
                setState({ type: 'VALIDATION_FAILED', formErrors: validateData(true) });
            } else {
                const adjustedValues = adjustValues(formDefinition, {
                    ...startFormContext.variables,
                    ...startFormContext.fieldValues,
                });
                setState({
                    type: 'SUBMITTING_2',
                    outcome: state.outcome,
                    outcomeDisplay: state.outcomeDisplay,
                    data: adjustedValues,
                    taskFormKey: state.taskFormKey,
                });
            }
        }
    }, [
        state,
        setState,
        entities,
        valueSets,
        fields,
        startFormContext.fieldValues,
        startFormContext.visibleAndEditableFields,
        viewConfig,
        formDefinition,
        startFormContext.variables,
    ]);
    // we will use this to only trigger the fetch when state.type as switched to SUBMITTING_2 for the first time.
    // to do that we need to keep track of what the value of state.type was on the previous update.
    const prevSubmittingStateAfterUpdate = useRef(state.type);
    useEffect(() => {
        if (state.type !== 'SUBMITTING_2') {
            prevSubmittingStateAfterUpdate.current = state.type;
        }
        // if state.type is 'SUBMITTING_2', lets set prevSubmittingStateAfterUpdate in in the useEffect which actually does the fetch
    }, [state.type]);
    useEffect(() => {
        // if route changes and props.passThroughValues changes by reference on rerender, that can trigger a second update when submitting.
        // to prevent double-fetch (just in case the user of this hook doesn't memoize passThroughValues), check prevSubmittingStateAfterUpdate
        if (state.type === 'SUBMITTING_2' && prevSubmittingStateAfterUpdate.current !== 'SUBMITTING_2') {
            prevSubmittingStateAfterUpdate.current = state.type;
            const body = {
                processDefinitionKey: props.businessKey,
                outcome: state.outcome,
                outcomeDisplay: state.outcomeDisplay,
                values: {
                    ...state.data,
                    ...props.passThroughValues,
                },
                overrideWarning: state.overrideWarning,
                dynamicStartFormKey: state.taskFormKey,
            };

            if (interceptSubmitCb) {
                setTimeout(() => {
                    setState({ type: 'NOT_SUBMITTING' });
                }, 200);
                interceptSubmitCb(body);
                return;
            }
            const $ajax = refreshJwt(ajax(getOptions(getUrl('api/bpm/process-instances'), 'POST', body)));

            const subscription = $ajax.subscribe(
                (res) => {
                    if (res.status === 201) {
                        if (res.response.warning) {
                            setState({
                                type: 'WARNING',
                                warning: res.response.warning,
                                request: {
                                    data: state.data,
                                    outcome: state.outcome,
                                    outcomeDisplay: state.outcomeDisplay,
                                },
                            });
                            return;
                        }
                        if (res.response.error) {
                            setState({
                                type: 'BACKEND_VALIDATION_ERROR',
                                error: res.response.error,
                            });
                            return;
                        }
                        if (interceptSuccess) {
                            interceptSuccess(res.response.redirect);
                        } else if (offlineMode) {
                            alert('Success! Your data has been submitted.');
                            dispatch(pushAction('/'));
                        } else if (res.response.redirect) {
                            if (res.response.redirect.startsWith('http')) {
                                window.location.href = res.response.redirect;
                            } else {
                                dispatch(pushAction(res.response.redirect));
                            }
                        } else if (viewConfig.user.login === 'anonymousUser') {
                            // assume anonymous user can only start tasks from the dashboard links,
                            // so it makes sense to redirect them back.
                            dispatch(pushAction('/'));
                        } else {
                            dispatch(pushAction(`/processes/${res.response.id}`));
                        }
                        // if we navigate synchronously above, this setState is on an unmounted component.
                        // since we always navigate, commenting this out.
                        // setState({ type: 'SUCCESS' });
                    } else {
                        console.log('unexpected non-201 response', res);
                        setState({ type: 'NOT_SUBMITTING' });
                    }
                },
                (error: AjaxError) => {
                    setState({ type: 'ERROR', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [
        state,
        interceptSuccess,
        interceptSubmitCb,
        props.businessKey,
        dispatch,
        viewConfig.user.login,
        props.passThroughValues,
        offlineMode,
    ]);

    const submit = useMemo(() => {
        return memoize((outcome?: string, outcomeDisplay?: string) => {
            // function passed to submit button
            return () => {
                setState({
                    type: 'SUBMITTING_1',
                    outcome,
                    outcomeDisplay,
                    taskFormKey,
                });
            };
        });
    }, [setState, taskFormKey]);
    return [state, submit, setState] as [SubmissionState, typeof submit, typeof setState];
};

const StartFormComponent = (props: StartFormComponentProps) => {
    const { formDefinition, renderBeforeOutcomes, formId, noOutcomes } = props;
    const recaptchaWidgetIds = useMemo(() => {
        return getAllRecaptchaInstances(formDefinition);
    }, [formDefinition]);
    const classes = useStyles(props);
    const fieldFactory = useContext(FieldFactoryContext);
    const dispatch = useDispatch();

    useRegisterViewDetails(formId, 'start-form');

    useEffect(() => {
        if (formId) {
            dispatch(setAsTopView(formId));
            return () => {
                dispatch(unsetAsTopView(formId));
            };
        }
    }, [formId, dispatch]);
    const entitiesAreLoading = useSelector((state: RootState) => state.admin.loading > 0);
    const fields = useMemo(() => {
        return getFields(fieldFactory, formDefinition, {}, formId);
    }, [fieldFactory, formDefinition, formId]);

    const [state, submit, setState] = useSubmit(props, fields);
    const selectInitialValues = useMemo(createGetStartFormInitialValues, []);
    const initialValues = useSelector((state: RootState) => selectInitialValues(state, props));
    const hasOutcomes = formDefinition && formDefinition.outcomes && formDefinition.outcomes.length > 0;
    const evaluatedFormTitle = useEvaluateTemplate(formDefinition.name ?? '')({});
    const overriddenFormTitleExpression = useMemo(() => {
        const formTitleExpField: ExpressionFormField = formDefinition.fields.find(
            (f) => f.id === '_form_title' && f.type === 'expression',
        ) as ExpressionFormField;
        if (formTitleExpField) {
            const titleHtml = formTitleExpField.value ?? formTitleExpField.expression;
            return titleHtml;
        }
        return null;
    }, [formDefinition]);

    const onLiveValidate = useLiveTaskFormValidation({
        formDefinition,
        fields,
    });
    return (
        <formTypeContext.Provider value="NONE">
            <Form
                validate={onLiveValidate}
                form={formId}
                recaptchaWidgetIds={recaptchaWidgetIds}
                resetRecaptchas={Boolean(state.type === 'WARNING' || state.type === 'BACKEND_VALIDATION_ERROR')}
                initialValues={initialValues}
                // even if Object.keys(state.formErrors).length === 0 below, it touches all fields for us to display validations/warnings
                // so lets still mark a submissionError
                submissionError={state.type === 'VALIDATION_FAILED' ? state.formErrors : undefined}
            >
                <StartFormValidateContextProvider formId={formId} fields={fields}>
                    <Card>
                        {overriddenFormTitleExpression ? (
                            <SafeHtmlAsReact html={overriddenFormTitleExpression} />
                        ) : (
                            <CardHeader
                                titleTypographyProps={{ component: 'h1' } as any}
                                title={
                                    <span
                                        className="casetivity-start-form-title"
                                        style={{ paddingLeft: 'calc(0.5em + 4px)' }}
                                    >
                                        {evaluatedFormTitle}
                                    </span>
                                }
                            />
                        )}
                        <CardContent style={{ paddingTop: 0 }}>
                            {fields && <EnhancedRGridWithVis fields={fields} formDefinition={formDefinition} />}
                        </CardContent>
                        {state.type === 'ERROR' &&
                            ((window as any).CASETIVITY_USE_SERVICE_WORKER && !state.error.status ? (
                                // if we have a 'USE_SERVICE_WORKER' flag, start-forms that fail due to network errors will be retried on reconnection.
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Check color="primary" />
                                    <br />
                                    You are currently offline, but your data will be submitted once your device
                                    reconnects to the internet.
                                </div>
                            ) : (
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ whiteSpace: 'pre-wrap' }} className={classes.responseError}>
                                        {state.error.status || 'Network Error. Check your connection and retry.'}
                                        <br />
                                        {state.error.response && JSON.stringify(state.error.response)}
                                    </p>
                                </div>
                            ))}
                        {state.type === 'BACKEND_VALIDATION_ERROR' && (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <p style={{ whiteSpace: 'pre-wrap' }} className={classes.responseError}>
                                    <SafeHtmlAsReact html={state.error} />
                                </p>
                            </div>
                        )}
                        <CardActions className={classes.submissionArea}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                {renderBeforeOutcomes?.()}
                                {noOutcomes ? null : hasOutcomes ? (
                                    <Outcomes2
                                        formDefinition={formDefinition}
                                        createButton={(label, outcome, forceDisabled, ButtonProps) => {
                                            return (
                                                <EvaluateFormattedMessage>
                                                    {({ evaluateFormattedMessage }) => (
                                                        <Button
                                                            onClick={submit(outcome, label)}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                entitiesAreLoading ||
                                                                forceDisabled ||
                                                                state.type === 'SUBMITTING_2'
                                                            }
                                                            {...ButtonProps}
                                                        >
                                                            {evaluateFormattedMessage(label)}
                                                            {state.type === 'SUBMITTING_2' &&
                                                                state.outcome === outcome && (
                                                                    <CircularProgress
                                                                        size={20}
                                                                        className={classes.buttonSpinner}
                                                                    />
                                                                )}
                                                        </Button>
                                                    )}
                                                </EvaluateFormattedMessage>
                                            );
                                        }}
                                    />
                                ) : (
                                    <Button
                                        disabled={entitiesAreLoading || state.type === 'SUBMITTING_2'}
                                        onClick={submit('complete', 'Complete')}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Complete
                                        {state.type === 'SUBMITTING_2' && (
                                            <CircularProgress size={20} className={classes.buttonSpinner} />
                                        )}
                                    </Button>
                                )}
                                {entitiesAreLoading && (
                                    <span style={{ width: 0, position: 'relative' }}>
                                        <CircularProgress className={classes.pendingSpinner} size={20} />
                                    </span>
                                )}
                            </div>
                        </CardActions>
                    </Card>
                    {state.type === 'VALIDATION_FAILED' && (
                        <ErrorPopup formErrors={state.formErrors} formDefinition={formDefinition} />
                    )}
                    {state.type === 'WARNING' && (
                        <WarningPopup
                            onContinue={() => {
                                setState({
                                    ...state.request,
                                    type: 'SUBMITTING_2',
                                    overrideWarning: true,
                                });
                            }}
                            warning={state.warning}
                        />
                    )}
                </StartFormValidateContextProvider>
            </Form>
        </formTypeContext.Provider>
    );
};

const StartFormFormComponent = (props: StartFormProps) => {
    const title = useEvaluatedFormattedMessage(props.formDefinition?.name ?? '');
    const { useInternalFormId = false } = props;
    const formId = useMemo(
        () => (useInternalFormId ? uniqueId('current-task-form-') : 'current-task-form'),
        [useInternalFormId],
    );
    return (
        <casetivityViewContext.Provider value="START_FORM">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <BpmFormExpressionTester formId={formId} contextType="start-form" formDefinition={props.formDefinition}>
                {({ formDefinition }) => {
                    return (
                        <AsyncEventsInProgressContextProvider>
                            <InternationalizeForm taskForm={formDefinition}>
                                {({ internationalizedForm }) => (
                                    <startFormContext.Provider value={internationalizedForm}>
                                        <StartFormComponent
                                            {...props}
                                            formId={formId}
                                            formDefinition={internationalizedForm}
                                        />
                                    </startFormContext.Provider>
                                )}
                            </InternationalizeForm>
                        </AsyncEventsInProgressContextProvider>
                    );
                }}
            </BpmFormExpressionTester>
        </casetivityViewContext.Provider>
    );
};

const StartFormForm: React.FC<StartFormProps> = (props) => {
    if (!props.formDefinition) {
        return null;
    }
    return <StartFormFormComponent {...props} />;
};
export default StartFormForm;
