import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { css } from 'emotion';

/**
 * Function copied from https://stackoverflow.com/a/11868398
 */
function getContrastYIQ(hexcolor: string) {
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
}

const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const DynamicViewIndicator: React.FC<{
    isDynamic: boolean;
    viewName?: string;
}> = (props) => {
    const visibleViewNames = useSelector((state: RootState) => state.visibleViewNames);
    return (
        <div
            className={
                !visibleViewNames
                    ? undefined
                    : css`
                          border: 1px solid black;
                      `
            }
        >
            {visibleViewNames ? (
                <p
                    className={css`
                        background-color: black;
                        color: white;
                        padding-left: 1rem;
                    `}
                >
                    {`[DYNAMIC VIEW]`}&nbsp;
                    <a style={{ color: 'inherit' }} href={`/load-layout/${props.viewName}`}>
                        {props.viewName}
                    </a>
                </p>
            ) : undefined}
            {props.children}
        </div>
    );
};

const withVisibleViewName =
    <Props extends { viewName: string }>(BaseComponent: React.ComponentType<Props>): React.FC<Props> =>
    (props: Props) => {
        const randomColor = useMemo(() => getRandomColor(), []);
        const contrastTextColor = useMemo(() => getContrastYIQ(randomColor), [randomColor]);
        const visibleViewNames = useSelector((state: RootState) => state.visibleViewNames);
        const divClassName = !visibleViewNames
            ? undefined
            : css`
                  border: 1px solid ${randomColor};
              `;
        const textClassName = !visibleViewNames
            ? undefined
            : css`
                  background-color: ${randomColor};
                  color: ${contrastTextColor};
                  padding-left: 1rem;
              `;
        return (
            <div className={divClassName}>
                {visibleViewNames ? (
                    <p className={textClassName}>
                        <a style={{ color: 'inherit' }} href={`/load-layout/${props.viewName}`}>
                            {props.viewName}
                        </a>
                    </p>
                ) : null}
                <BaseComponent {...props} />
            </div>
        );
    };

export default withVisibleViewName;
