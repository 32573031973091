import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Form, FormSpy, FormRenderProps } from 'react-final-form';
import deepEql from 'deep-eql';
import produce from 'immer';
import { TaskForm } from 'reducers/taskFormType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import useLiveTaskFormValidation from 'bpm/components/TaskDetail/TaskForm/hooks/useLiveTaskFormValidation';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import createGetOptionsSelector from 'bpm/form-context-utils/etc/getOptionsSelector';
import debounce from 'lodash/debounce';
import { FormState } from 'final-form';

interface ControlledValidatedFormProps {
    values: {};
    setValues: (values: {}) => void;
    children: (props: FormRenderProps, fields: React.ReactElement<any>[]) => JSX.Element;
    submitFailed?: boolean;
    bpmFormDefinition: Partial<TaskForm>;
    disabled?: boolean;
}

const TouchFieldsWhenSubmitFailed = ({
    submitFailed,
    onSubmitFailed,
}: {
    submitFailed?: boolean;
    onSubmitFailed: () => void;
}) => {
    // New rows added after a failed submission will show errors - I think that's fine.
    const prevSubmitFailed = useRef(false);
    useEffect(() => {
        if (submitFailed && prevSubmitFailed.current !== submitFailed) {
            prevSubmitFailed.current = true;
            onSubmitFailed();
        }
    }, [submitFailed, onSubmitFailed]);
    return null;
};

const getFields = (fieldFactory, formDefinition: TaskForm, disabled?: boolean) => {
    const defs =
        formDefinition.fields?.filter((f) => !(f.type === 'expression' && f.value && f.value.startsWith('$=['))) ?? [];

    return fieldFactory({
        dataSource: DataSource.FLOWABLE,
        mode: Mode.INPUT_NOWARN,
        validate: true,
        connected: 'ff',
        options: {
            getOwnData: true,
            hideCheckboxLabel: true,
            ff: true,
        },
    })(
        {
            noPad: true,
            fetchOnMount: true,

            isPopover: true, // < -not sure about this.

            replacePeriodsInFieldName: '_',
            options: {
                fullWidth: true,
            },
            overrideFieldValueIfDisabled: true,
            shouldFetchValueset: false,
            disabled,
        },
        null,
        // TODO: check formContext read by this.
        createGetOptionsSelector(formDefinition),
    )(defs);
};

const ffContext = createContext<FormRenderProps | null>(null);
export const useFFContext = () => {
    return useContext(ffContext);
};

const ControlledValidatedBpmForm: React.FC<ControlledValidatedFormProps> = ({
    values,
    setValues,
    children,
    submitFailed,
    bpmFormDefinition,
    disabled,
}) => {
    const currentValues = useRef(values);
    currentValues.current = values;
    const debouncer = useMemo(
        () =>
            debounce((formState: FormState<Record<string, any>, Partial<Record<string, any>>>) => {
                if (
                    !deepEql(formState.values, currentValues.current) ||
                    Boolean(formState.hasValidationErrors) !== currentValues.current['__invalid']
                ) {
                    const newValues = formState.hasValidationErrors
                        ? {
                              ...formState.values,
                              __invalid: true,
                          }
                        : produce(formState.values, (draft) => {
                              delete draft['__invalid'];
                          });
                    // hm...
                    setValues(newValues);
                }
            }),
        [setValues],
    );
    useEffect(() => {
        return () => debouncer.cancel();
    }, [debouncer]);

    const fieldFactory = useContext(FieldFactoryContext);
    const fields = useMemo(() => {
        return getFields(fieldFactory, bpmFormDefinition as TaskForm, disabled);
    }, [fieldFactory, bpmFormDefinition, disabled]);

    const onLiveValidate = useLiveTaskFormValidation({
        formDefinition: bpmFormDefinition as TaskForm,
        fields,
    });

    const handleSubmit = useCallback((data) => {
        // No-op: we are using the form to keep track of values, and propagating using FormSpy
    }, []);
    return (
        <div onBlur={() => debouncer.flush()}>
            <Form
                mutators={{
                    setFieldTouched: (args: any[], state) => {
                        const [name, touched] = args;
                        const field = state.fields[name];
                        if (field) {
                            field.touched = !!touched;
                        }
                    },
                }}
                validate={onLiveValidate}
                validateOnBlur
                initialValues={values}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    return (
                        <ffContext.Provider value={props}>
                            <>
                                {children(props, fields)}
                                <TouchFieldsWhenSubmitFailed
                                    submitFailed={submitFailed}
                                    onSubmitFailed={() => {
                                        props.form.getRegisteredFields().forEach((f) => {
                                            props.form.mutators.setFieldTouched(f, true);
                                        });
                                    }}
                                />
                                {/*
                                The ONLY reason we're debouncing the onChange is that
                                it gets called in render.

                                https://github.com/final-form/react-final-form/issues/809
                            */}
                                <FormSpy
                                    subscription={{ values: true, hasValidationErrors: true }}
                                    onChange={debouncer}
                                />
                            </>
                        </ffContext.Provider>
                    );
                }}
            </Form>
        </div>
    );
};
export default ControlledValidatedBpmForm;
