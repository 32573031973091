import React, { useMemo, useContext, useState } from 'react';
import { createExtraTaskContextSelector, formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card, Button, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';
import { getValueset1Fields } from 'bpm/form-context-utils';
import { useSelector } from 'react-redux';
import { EntityFormContext, formContext as editEntityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as showEntityFormContext } from 'components/generics/form/EntityFormContext/Show';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import evaluateExpression from 'ts-spel-utils/evaluateExpression';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import createRecordSelector from 'components/generics/form/EntityFormContext/util/recordSelector';
import forceBooleanFieldsBooleanAndArrayFieldsArrays from 'components/generics/form/EntityFormContext/util/enforceBoolAndArrayValues';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import useEntities from 'util/hooks/useEntities';
import { processContext } from 'bpm/components/processContext';
import createBackrefSelector from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import useViewConfig from 'util/hooks/useViewConfig';
import { setupGenericContext } from 'expressions/Provider/setupGenericContext';

function addSlashes(string) {
    return (
        string
            .replace(/\\/g, '\\\\')
            .replace(/\t/g, '\\t')
            .replace(/\n/g, '\\n')
            .replace(/\f/g, '\\f')
            .replace(/\r/g, '\\r')
            // .replace(/'/g, "\\'");
            .replace(/"/g, '\\"')
    );
}

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export type ExpressionTestAreaProps =
    | {
          contextType: 'start-form' | 'existing-task-form';
          formDefinition: TaskForm;
          viewName?: undefined;
      }
    | {
          contextType: 'entity-view';
          type: 'EDIT' | 'SHOW';
          formId?: string;
          viewName: string;
          record: { id?: string; entityType?: string };
          expansionsRequired: string[];
          dataPaths: string[];
          valueset1Fields: {
              [f: string]: string;
          };
      };

const useGetEvalContext = (props: ExpressionTestAreaProps) => {
    const viewConfig = useSelector((state: RootState) => state.viewConfig);

    // GET VALUES FOR SHOW VIEW
    const getRootRecord = useMemo(createRecordSelector, []);
    const rootRecord = useSelector((state: RootState) =>
        props.contextType === 'entity-view'
            ? getRootRecord(state, { ...props, overrideFieldsInExpressions: props.expansionsRequired })
            : null,
    );
    const valuesToUse = useMemo(
        () =>
            props.contextType === 'entity-view' &&
            rootRecord &&
            forceBooleanFieldsBooleanAndArrayFieldsArrays(viewConfig, props.viewName)(rootRecord),
        [rootRecord, props.contextType, props.viewName, viewConfig],
    );
    // END FOR SHOW VIEW
    const valuesets = useSelector((state: RootState) => state.valueSets);
    const entities = useEntities();
    const fc = useContext<
        ReturnType<typeof evaluateContext2> & { adhocVariablesContext?: EntityFormContext['adhocVariablesContext'] }
    >(
        (props.contextType === 'entity-view'
            ? props.type === 'EDIT'
                ? editEntityFormContext
                : showEntityFormContext
            : formContext) as any,
    );

    const taskExtraContextSelector = useMemo(createExtraTaskContextSelector, []);
    const { taskId } = useContext(processContext) ?? {};
    const taskInjectedContext = useSelector((state: RootState) => {
        if (props.contextType !== 'existing-task-form' || !taskId) {
            return null;
        }
        return taskExtraContextSelector(state, taskId);
    });

    const backrefSelector = useMemo(createBackrefSelector, []);
    const backref = useSelector((state: RootState) =>
        backrefSelector(state, { formId: props.contextType === 'entity-view' ? props.formId : undefined }),
    );

    const viewContext =
        props.contextType === 'start-form'
            ? 'START_FORM'
            : props.contextType === 'existing-task-form'
            ? 'PROCESS_TASK'
            : 'ENTITY';
    const evalContext = createRootContext({
        ...setupGenericContext({
            viewConfig,
            entities,
            valueSets: valuesets,
            viewContext,
            backref,
        }),
        ...entityPreprocessValuesForEval(
            props.contextType === 'entity-view' && props.type === 'SHOW' ? valuesToUse : fc.fieldValues,
            props.contextType === 'entity-view' ? props.dataPaths : props.formDefinition.fields.map((f) => f.id),
            props.contextType === 'entity-view'
                ? props.valueset1Fields
                : getValueset1Fields(props.formDefinition.fields),
            entities,
            {
                viewContext,
                dateFormat: 'MM/dd/yyyy', // can hook this up later,
                backref,
            },
            valuesets,
        ),
        ...entityAndConceptLookupUtils(entities, viewConfig, valuesets),
        ...taskInjectedContext,
        ...fc.adhocVariablesContext,
    });
    return evalContext;
};
export const ExpressionTestValues: React.FC<ExpressionTestAreaProps> = (props) => {
    const evalContext = useGetEvalContext(props);
    const evalContextValues = Object.entries(evalContext)
        .filter(([key, value]) => !isFunction(value))
        .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    return (
        <JSONEditorDemo
            mode="view"
            json={evalContextValues}
            // onChangeJSON={this.onChangeJSON}
        />
    );
};

export const Evaluator: React.FC<ExpressionTestAreaProps> = (props) => {
    const viewConfig = useViewConfig();
    const entityType = viewConfig.views[props.viewName]?.entity;
    const [text, setText] = useState('');
    const [evalResult, setEvalResult] = useState('');
    const evalContext = useGetEvalContext(props);
    const evalContextFns = Object.entries(evalContext)
        .filter(([key, value]) => isFunction(value))
        .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    // TODO:
    // We could (possibly) autocomplete functions like this. TODO.

    // const contextRef = useRef(evalContext);
    // contextRef.current = evalContext;

    // const getCompletions = useCallback(valueUnitlCursor => {
    //     const prefix = '';
    //     return [];
    //     const path = prefix.split('.').map(s => s.trim());
    //     const completions: string[] = path.reduce((prev, curr, i, arr) => {
    //         if (!prev) {
    //             return prev;
    //         }
    //         const isLast = i === arr.length - 1;
    //         if (!isLast) {
    //             return prev[curr];
    //         }
    //         return Object.keys(prev).filter(k => k.startsWith(curr));
    //     }, contextRef.current);

    //     return completions?.map(c => ({ word: c, meta: 'local' })) ?? [];
    // }, []);
    return (
        <div style={{ padding: '1em' }}>
            <div style={{ paddingBottom: '1em' }}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        In addition to the values to the left, the following functions are available:
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ul>
                            {Object.keys(evalContextFns).map((fnname) => (
                                <li key={fnname}>{fnname}</li>
                            ))}
                        </ul>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
            <label>
                <b>Test Expression</b>
                <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
                    <AutocompleteSpelEditor rootEntity={entityType} value={text} onChange={setText} hideDocs />
                </div>
            </label>
            ["{addSlashes(text)}"]
            <div style={{ paddingTop: '1em' }}>
                <Button
                    onClick={() => {
                        try {
                            setEvalResult(`${evaluateExpression(text, evalContext, evalContext)}`);
                        } catch (e) {
                            console.error(e);
                            setEvalResult('The expression has an error. Check the console.');
                        }
                    }}
                    color="primary"
                    variant="contained"
                >
                    Evaluate
                </Button>
            </div>
            <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }} />
            {evalResult}
        </div>
    );
};

const ExpressionTestArea: React.FC<ExpressionTestAreaProps & { belowEvaluator?: JSX.Element }> = ({
    belowEvaluator,
    ...props
}) => {
    return (
        <Card>
            <div style={{ padding: '1em' }}>Test new expressions against the live values in the form below.</div>
            <SplitPane split="vertical">
                <Pane initialSize="50%">
                    <ExpressionTestValues {...props} />
                </Pane>
                <div>
                    <Evaluator {...props} />
                    {belowEvaluator}
                </div>
                {/* <Pane initialSize="25%" minSize="10%" maxSize="500px">
    Using a Pane allows you to specify any constraints directly
</Pane> */}
            </SplitPane>
        </Card>
    );
};
export default ExpressionTestArea;
