import Labeled from 'components/generics/utils/Labeled';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../translation/types/DataSource';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import React, { useContext, useMemo, useState } from 'react';
import createErrorTableFromMessages from './util/createErrorTableFromMessages';
import {
    BufferChanges,
    flowableArrayOfObjectsToJSONStr,
    isStringGreaterThanLength,
    isTableFieldErrorMessage,
} from './util/utils';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import Hidden from 'components/generics/form/HiddenField';
import RGrid from 'components/generics/fields/display/RGrid';
import clone from 'clone';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import PromoteIcon from '@material-ui/icons/ArrowDropUp';
import DemoteIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import { Button, Tooltip, useTheme } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { tableRowContext, getTableRowContext } from './util/tableRowContext';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { tryCatch, fromPredicate } from 'fp-ts/lib/Option';

type SelectOption = string | { key: string; value: unknown };
interface BaseFieldSpec {
    title: string;
    fieldName: string;
    readOnly: boolean;
    defaultValue?: unknown;
    isReadOnly?: (rowData: {}) => boolean;
    width?: string | number;
    span: number;
}
interface TextFieldSpec extends BaseFieldSpec {
    inputType: 'TextField';
}
interface SelectFieldSpec extends BaseFieldSpec {
    inputType: 'SelectField';
    selectOptions: SelectOption[];
}
interface ToggleFieldSpec extends BaseFieldSpec {
    inputType: 'Toggle';
}
interface CustomFieldSpec extends BaseFieldSpec {
    inputType: React.ComponentType<{}> | React.ReactElement<{}>;
}
type FieldSpec = TextFieldSpec | SelectFieldSpec | ToggleFieldSpec | CustomFieldSpec;
const isCustomFieldSpec = (fieldSpec: FieldSpec): fieldSpec is CustomFieldSpec =>
    typeof fieldSpec.inputType !== 'string';

interface EditableTable2Props {
    meta: any;
    input: any;
    label: string;
    source: string;
    disabled?: boolean;
    colSpec: FormFieldUnion[];
    allowRowAddDelete?: boolean;
    reorderable?: boolean;
    copyRows?: boolean;
    overrideAriaLabel?: string;
    addRowText?: string;
}

const EditableTable2: React.FC<EditableTable2Props> = (props) => {
    const {
        source,
        label,
        overrideAriaLabel,
        colSpec,
        disabled,
        meta: { error, touched, submitFailed },
        input,
        allowRowAddDelete = true,
        reorderable = true,
        copyRows = true,
    } = props;
    const fieldFactory = useContext(FieldFactoryContext);
    const theme = useTheme();

    const internalColSpec = useMemo(() => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT,
            validate: false,
            connected: false,
            options: {
                fullWidth: true,
            },
        };
        const colSpecWithComponents = colSpec.map(({ name, type, id, readOnly, params, ...rest }) => ({
            title: name,
            fieldName: id,
            readOnly: fromPredicate<string>(Boolean)(params?.configs?.fieldConfig)
                .chain((fieldConfig) => tryCatch(() => JSON.parse(fieldConfig)))
                .mapNullable((config) => config['readOnly'])
                .getOrElse(readOnly),
            span: (params?.span && parseInt(params.span, 10)) || Math.floor(12 / colSpec.length),
            inputType: fieldFactory(config)({
                disabled: disabled,
                overrideFieldValueIfDisabled: true,
            })([
                {
                    ...rest,
                    params,
                    id,
                    required: false,
                    type,
                    name,
                },
            ])[0],
        }));
        return colSpecWithComponents;
    }, [fieldFactory, colSpec, disabled]);

    const { evaluateFormattedMessage } = useEvaluateFormattedMessage();
    const errorTable =
        (touched || submitFailed) &&
        error &&
        Array.isArray(error) &&
        createErrorTableFromMessages(error.filter(isTableFieldErrorMessage));

    const rowData = isStringGreaterThanLength(4)(input.value)
        ? JSON.parse(flowableArrayOfObjectsToJSONStr(input.value))
        : isStringGreaterThanLength(1)(input.value)
        ? JSON.parse(input.value)
        : input.value || [];
    const onChange = props.input.onBlur;

    const [key, setKey] = useState(1);

    const onFieldChange = (rowId: number, fieldName: string, value?: unknown) => {
        const tempDataRow = clone(rowData);

        tempDataRow[rowId][fieldName] = value;
        onChange(tempDataRow);
    };

    const getHiddenRowHeadingId = (index: number) => {
        // because there might be multiple of these if the table is nested,
        // (there might be multiple rows with the same source)
        // we should include overrideAriaLabel, because it includes row information
        return 'editabletable-row-' + source + '-' + (overrideAriaLabel ?? label) + '-' + index + '-heading';
    };
    const renderInputField = (column: FieldSpec, index: number, rowData: {}) => {
        if (column.isReadOnly && column.isReadOnly(rowData)) {
            return <div style={{ width: column.width }} />;
        }
        if (React.isValidElement(column.inputType) && isCustomFieldSpec(column)) {
            const CustomComponent: any = column.inputType;
            const modProps = (props) => {
                return {
                    ...props,
                    overrideAriaLabel: (
                        (overrideAriaLabel ?? label) +
                        ' row ' +
                        (index + 1) +
                        ' ' +
                        column.title
                    ).trim(),
                };
            };
            const renderer =
                typeof CustomComponent.type === 'function'
                    ? (props) => React.cloneElement(CustomComponent, modProps(props)) // React Element
                    : (props) => <CustomComponent {...modProps(props)} />; // React Component
            return (
                <div style={{ paddingRight: '1em' }}>
                    <BufferChanges
                        submitFailed={submitFailed}
                        error={errorTable && errorTable[index] && errorTable[index][column.fieldName]}
                        id={column.fieldName + index}
                        style={{ width: column.width }}
                        defaultToggled={column.fieldName in rowData ? rowData[column.fieldName] : false}
                        label={column.title}
                        disabled={column.readOnly || CustomComponent.props.disabled}
                        onChange={(eventOrValue?: Event | any) =>
                            onFieldChange(
                                index,
                                column.fieldName,
                                eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue,
                            )
                        }
                        value={rowData[column.fieldName] || ''}
                        render={renderer}
                    />
                </div>
            );
        }
        throw new Error(`Input field type ${column.inputType} not supported`);
    };
    const iconButton = (
        rowKey: number | string,
        action: string,
        clickEvent: (eventOrValue: unknown) => void,
        muiIcon: JSX.Element,
    ) => {
        return (
            <div className="cell action" key={`action${action}${rowKey}`} style={{ width: '45px', display: 'inline' }}>
                <Button
                    aria-label={action}
                    className={`action-button ${action}-row-button${rowKey}`}
                    color="primary"
                    onClick={clickEvent}
                    style={{ minWidth: '45px' }}
                >
                    {muiIcon}
                </Button>
            </div>
        );
    };
    const onDeleteRow = (rowId: number) => {
        return function () {
            const tempDataRow = [...rowData];

            tempDataRow.splice(rowId, 1);
            onChange(tempDataRow);
        };
    };

    const onDuplicateRow = (rowId: number) => {
        return function () {
            onChange([...rowData.slice(0, rowId), clone(rowData[rowId]), ...rowData.slice(rowId)]);
        };
    };

    const onReorderRow = (rowId: number, direction: -1 | 1) => {
        return function () {
            const tempDataRow = [...rowData];

            const oldIndex = rowId;
            const newIndex = rowId + direction;

            tempDataRow.splice(newIndex, 0, tempDataRow.splice(oldIndex, 1)[0]);
            onChange(tempDataRow);
            setKey(key + 1);
        };
    };

    const renderRowButtons = (index: number) => {
        const buttons: JSX.Element[] = [];
        const rowLabel = props.label + ' row ' + (index + 1);
        if (allowRowAddDelete) {
            buttons.push(iconButton(index, 'delete ' + rowLabel, onDeleteRow(index), <DeleteIcon />));
        }
        if (allowRowAddDelete && copyRows) {
            buttons.push(
                <Tooltip title="Copy row" placement="top-end">
                    {iconButton(index, 'duplicate ' + rowLabel, onDuplicateRow(index), <DuplicateIcon />)}
                </Tooltip>,
            );
        }

        if (reorderable) {
            if (index < rowData.length - 1 && rowData.length > 1) {
                buttons.push(iconButton(index, 'demote ' + rowLabel, onReorderRow(index, +1), <DemoteIcon />));
            }
            if (index > 0) {
                buttons.push(iconButton(index, 'promote ' + rowLabel, onReorderRow(index, -1), <PromoteIcon />));
            }
        }

        return (
            <div style={{ position: 'relative', height: '100%', minWidth: '156px' }}>
                <div style={{ position: 'absolute', bottom: 0, left: 0 }}>{buttons}</div>
            </div>
        );
    };

    const renderRow = (dataRow: {}, index: number) => {
        const dataRowStyle = {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            border: '0',
            minHeight: '70px',
            borderBottom: '1px solid rgb(224, 224, 224)',
            flexGrow: 1,
        };

        return (
            <tableRowContext.Consumer key={index}>
                {(c) => {
                    const tableRowContextValue = {
                        row: index,
                        tableId: source,
                        columns: colSpec,
                        tableIdPath: c ? [...c.tableIdPath, source] : [source],
                        tableRowIndexes: c ? [...c.tableRowIndexes, index] : [index],
                        rowGetExp: c ? `${c.rowGetExp}.${source}[${index}]` : `${source}[${index}]`,
                    };
                    return (
                        <formContext.Consumer>
                            {(fc) => {
                                return (
                                    <tableRowContext.Provider key={index} value={tableRowContextValue}>
                                        <div className="mui-editable-table-row" key={index} style={dataRowStyle}>
                                            <b
                                                id={getHiddenRowHeadingId(index)}
                                                className="casetivity-off-screen"
                                                tabIndex={-1}
                                            >
                                                Row {index + 1} of {rowData.length}
                                            </b>
                                            <RGrid
                                                smSize={null}
                                                xsSize={12}
                                                fields={internalColSpec.map((col, i) => {
                                                    const currentRowContext = getTableRowContext(
                                                        tableRowContextValue,
                                                        fc,
                                                    );
                                                    const isHidden =
                                                        // sometimes this is undefined,
                                                        // https://strategicsolutionsgroup.atlassian.net/browse/FISH-2971
                                                        // so short circuit.
                                                        currentRowContext &&
                                                        currentRowContext.hiddenFields[col.fieldName];
                                                    let el = (
                                                        <div
                                                            className={`cell ${col.fieldName}`}
                                                            key={col.fieldName + index}
                                                        >
                                                            {renderInputField(col, index, dataRow)}
                                                        </div>
                                                    );
                                                    if (isHidden) {
                                                        el = <Hidden dontShowCol={true}>{el}</Hidden>;
                                                    }
                                                    return React.cloneElement(el, {
                                                        span: col.span,
                                                        row: 1,
                                                        col: i,
                                                        key: i,
                                                    });
                                                })}
                                            />

                                            {!disabled && (
                                                <span style={{ height: '70px' }}>{renderRowButtons(index)}</span>
                                            )}
                                        </div>
                                    </tableRowContext.Provider>
                                );
                            }}
                        </formContext.Consumer>
                    );
                }}
            </tableRowContext.Consumer>
        );
    };

    const onAddRow = () => {
        return function () {
            let newRow = {};
            internalColSpec.forEach((column) => {
                newRow[column.fieldName] = ''; // column.defaultValue || '';
            });
            let newRowIx = rowData.length;
            onChange([...rowData, newRow]);

            // focus new row
            setTimeout(() => {
                // focus on hidden tabbable element, so the next tab goes to the next element.
                const headingEl = document.getElementById(getHiddenRowHeadingId(newRowIx));
                headingEl?.focus?.();
            }, 500);
        };
    };
    const renderRowActions = () => {
        const rowActions = !disabled && allowRowAddDelete;
        return (
            rowActions && (
                <div className={'row-cell header-cell action'} style={{ width: '100%' }}>
                    {iconButton(
                        '',
                        `add a new row${
                            (overrideAriaLabel ?? label).trim() ? ' to ' + (overrideAriaLabel ?? label) : ''
                        }`,
                        onAddRow(),
                        props.addRowText ? (
                            <>
                                {props.addRowText}
                                <AddIcon />
                            </>
                        ) : (
                            <AddIcon />
                        ),
                    )}
                </div>
            )
        );
    };
    const id = useMemo(() => uniqueId('editabletable'), []);

    return (
        <div>
            <Labeled label={label} source={source} fullWidth={true} key={key}>
                <div className="container" style={{ width: '100%' }}>
                    <div
                        className="mui-editable-table"
                        style={{
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontFamily: 'Roboto, sans-serif',
                        }}
                    >
                        <ol
                            aria-label={label}
                            style={{
                                width: '100%',
                                listStyle: 'none',
                                marginLeft: 0,
                                paddingLeft: 0,
                            }}
                        >
                            {rowData && rowData.map((dataRow, i) => <li key={i}>{renderRow(dataRow, i)}</li>)}
                        </ol>
                        {renderRowActions()}
                        <input type="hidden" id={id + '-count'} value={rowData ? rowData.length : 0} readOnly={true} />
                    </div>
                </div>
            </Labeled>
            {(touched || submitFailed) &&
                error &&
                Array.isArray(error) &&
                error.find((e) => !isTableFieldErrorMessage(e)) && (
                    <span style={{ color: theme.palette.error.main, fontSize: 'small' }}>
                        {evaluateFormattedMessage(error.filter((e) => !isTableFieldErrorMessage(e)).join(`\n`))}
                    </span>
                )}
        </div>
    );
};

export default EditableTable2;
