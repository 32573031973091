import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import defaultFormContext from 'bpm/form-context-utils/defaultFormContext';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import { useContext } from 'react';

const useIsTaskFormContext = () => {
    const taskFormContext = useContext(formContext);
    const flowableFormDefinitionContext = useContext(stagedFormDefinitionContext);
    const maybeBpmStyleFormDefinitionFields = flowableFormDefinitionContext?.formDefinition?.fields ?? null;
    const isTaskFormContext = taskFormContext !== defaultFormContext && maybeBpmStyleFormDefinitionFields;
    return isTaskFormContext;
};
export default useIsTaskFormContext;
