import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError, InjectedFormProps } from 'redux-form';
import { initialAssigneeValuesSelector as taskAssignmentInitialValuesSelector } from 'bpm/components/TaskDetail/selectors';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../../../../../../../reducers/taskFormType';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { asyncEventsInProgressContext } from 'bpm/components/TaskDetail/asyncEventCountContext';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import { GetComponentProps } from 'util/typeUtils';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import useTaskOption from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskOption';
import useLinkedEntitySyncErrors from './Save/hooks/useLinkedEntitySyncErrors';
import useCascadingSave from './Save/hooks/useCascadingSave';
import ErrorDialog from './Save/ErrorDialog';
import WorkingDialog from './Save/WorkingDialog';
import useTaskAttributesAreDirty from './Save/hooks/useTaskAttributesAreDirty';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useEvaluateOutcomeExpression from 'bpm/components/TaskDetail/TaskForm/hooks/useEvaluateOutcomeExpression';
import useEntities from 'util/hooks/useEntities';
import { isDisabled } from '..';
import { capitalizeFirstLetter } from 'components/generics';
import { useHasOfflineWorkToApply } from 'offline_app/offline_stateful_tasks/back_online/components/OfflineWorkBanner';
import {
    OfflineEntitySubmitsPrompt,
    ReturnCode,
    useOfflineEntitySubmitsNeeded,
    useSaveEntries,
} from 'offline_app/offline_entity_submits/SaveOrDiscardPrompt/SaveOrDiscard';
import RemoveOfflineDataDialog from './Save/RemoveTaskOfflineDialog';
import { unMarkTaskForOffline } from 'offline_app/offline_stateful_tasks/offlineTasks/actions';
import { unsetDownloadedListViews } from 'offline_app/offline_stateful_tasks/download/downloadedListViews/actions';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { EntityFormContextRef } from '../../../types';

const useButtonStyles = makeStyles({
    label: {
        textTransform: 'none',
    },
});

export interface SaveTriggerArgs {
    cascadingSave: ReturnType<typeof useCascadingSave>[2];
    openErrorDialog: (data: OpenDialogData) => void;
}
export interface OpenDialogData {
    getTaskFormErrors: () => {};
    formDefinition: RootState['taskForms'][0];
    submissionType: 'save' | 'submit';
}

const translateTaskAttributesKey = (n) => {
    return n === 'dueDate' ? 'due date' : n;
};
interface SaveButtonProps {
    handleSubmit: InjectedFormProps['handleSubmit'];
    fields: React.ReactElement[];
    taskId: string;
    buttonText: string;
    submissionType: 'save' | 'submit';
    outcome?: string;
    outcomeDisplay?: string;
    onSuccess: (
        response: { redirect?: string; processComplete: boolean; error?: string; nextTaskId?: string },
        submittedTaskFormValues: {},
    ) => void;
    forceDisabled?: boolean;
    ButtonProps?: GetComponentProps<typeof Button>;
    formDefinition: TaskForm;
    entityFormContextRef?: EntityFormContextRef;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}

const SaveTrigger: React.FC<SaveTriggerArgs & SaveButtonProps> = ({
    cascadingSave,
    openErrorDialog,
    outcome,
    fields,
    submissionType,
    forceDisabled,
    taskId,
    handleSubmit,
    buttonText,
    onSuccess,
    ButtonProps,
    formDefinition,
    entityFormContextRef,
}) => {
    const classes = useButtonStyles();
    const hasEntityErrors = Object.keys(useLinkedEntitySyncErrors()).length > 0;
    const [isLoading, setIsLoading] = React.useState(false);
    const taskO = useTaskOption(taskId);
    const entitiesAreLoading = useEntitiesAreLoading();
    const entities = useEntities();
    const taskAssignee = useSelector(
        (state: RootState) => taskAssignmentInitialValuesSelector(state, { taskId }).assignee,
    );
    const viewConfig = useViewConfig();
    const valueSets = useValueSets();
    const { eventCount, increment, decrement } = React.useContext(asyncEventsInProgressContext);
    const fc = React.useContext(taskFormContext);
    const isSubmitting = useSelector((state: RootState) => state.bpm.tasks.submitting[taskId]);
    const getTaskFormErrors = React.useCallback(
        (includeFieldLevel: boolean = true) => {
            return validate({
                formDefinition: formDefinition!,
                outcome,
                entities,
                valuesAfterExpressionsApplied: fc.fieldValues,
                visibleAndEditableFields: fc.visibleAndEditableFields,
                viewConfig,
                fields,
                valueSets,
                ignoreFieldLevel: !includeFieldLevel,
            });
        },
        [formDefinition, outcome, entities, fc.fieldValues, fc.visibleAndEditableFields, viewConfig, fields, valueSets],
    );
    const saveIsEditable = useEvaluateOutcomeExpression(formDefinition, '_save', 'editable');
    const saveIsVisible = useEvaluateOutcomeExpression(formDefinition, '_save', 'visibility');
    const currentUser = viewConfig && viewConfig.user && viewConfig.user.login;

    const submit = React.useCallback(() => {
        const errors = getTaskFormErrors(true);
        if (
            (submissionType !== 'save' && Object.keys(errors).length > 0) ||
            (entityFormContextRef.current?.isDirty && hasEntityErrors)
        ) {
            // call handleSubmit so taskForm reduxForm causes
            // syncErrors to update and display
            const displayErrors = getTaskFormErrors(false);
            handleSubmit(() => {
                throw new SubmissionError(Object.assign({ _error: displayErrors }, displayErrors));
            })();
            openErrorDialog({
                formDefinition: formDefinition!,
                getTaskFormErrors,
                submissionType,
            });
        } else {
            increment();
            setIsLoading(true);
            cascadingSave(
                submissionType,
                outcome,
                buttonText,
                taskId,
                formDefinition!,
                {
                    ...fc.variables,
                    ...fc.fieldValues,
                },
                Object.assign(
                    {},
                    ...Object.keys(fc.fieldValues).map((f) => ({
                        [f]: !fc.hiddenFields[f],
                    })),
                ),
                {
                    '*': () => {
                        decrement();
                        setIsLoading(false);
                    },
                },
                () => {
                    decrement();
                    setIsLoading(false);
                },
                (
                    response = {
                        processComplete: false,
                    },
                ) => {
                    decrement();
                    setIsLoading(false);
                    onSuccess(response, fc.fieldValues);
                },
            );
        }
    }, [
        entityFormContextRef,
        getTaskFormErrors,
        buttonText,
        handleSubmit,
        decrement,
        increment,
        cascadingSave,
        fc.fieldValues,
        fc.variables,
        fc.hiddenFields,
        formDefinition,
        hasEntityErrors,
        onSuccess,
        openErrorDialog,
        outcome,
        submissionType,
        taskId,
    ]);

    const offlineEntitySubmitsNeeded = useOfflineEntitySubmitsNeeded(taskId);
    const save = useSaveEntries(taskId);
    const [promptState, setPromptState] = React.useState<ReturnCode>('ok');

    const handleClick = React.useCallback(async () => {
        if (!offlineEntitySubmitsNeeded) {
            submit();
        } else {
            const returnCode = await save();
            setPromptState(returnCode);
            if (returnCode === 'ok') {
                submit();
            }
        }
    }, [save, setPromptState, submit, offlineEntitySubmitsNeeded]);

    const hasOfflineWorkToApply = useHasOfflineWorkToApply();
    return submissionType === 'save' && !saveIsVisible ? null : (
        <>
            <Button
                type="submit"
                variant="contained"
                disabled={
                    hasOfflineWorkToApply ||
                    (submissionType === 'save' && !saveIsEditable) ||
                    entitiesAreLoading ||
                    forceDisabled ||
                    isDisabled(taskO.map((t) => t.endDate).toUndefined(), currentUser, taskAssignee) ||
                    isSubmitting ||
                    eventCount > 0
                }
                onClick={entitiesAreLoading ? undefined : handleClick}
                classes={{
                    label: classes.label,
                }}
                {...ButtonProps}
            >
                {buttonText && capitalizeFirstLetter(buttonText)}
                {isLoading && <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />}
            </Button>
            <OfflineEntitySubmitsPrompt setPromptState={setPromptState} promptState={promptState} />
        </>
    );
};

const SaveButton = ({
    buttonText: _buttonText,
    submissionType,
    outcome,
    onSuccess,
    forceDisabled,
    ButtonProps,
    handleSubmit,
    fields,
    taskId,
    formDefinition,
    entityFormContextRef,
    relatedEntityResource,
    relatedEntityId,
}: SaveButtonProps) => {
    const buttonText = useEvaluatedFormattedMessage(_buttonText);
    const dispatch = useDispatch();
    const taskIsOffline = useSelector((state: RootState) => state.offlineTasks?.[taskId]);

    const [offlinePrompt, setOpenOfflinePrompt] = React.useState<
        | {
              _tag: 'open';
              cb: (remove: boolean) => void;
          }
        | {
              _tag: 'closed';
          }
    >({ _tag: 'closed' });

    const [errorDialogOpen, setErrorDialogOpen] = React.useState<OpenDialogData | false>(false);
    const closeDialog = React.useCallback(() => {
        setErrorDialogOpen(false);
    }, [setErrorDialogOpen]);

    const resource = entityFormContextRef.current?.initialValues?.entityType;

    const [workingState, clearWorkingState, cascadingSave] = useCascadingSave(
        taskIsOffline
            ? (submissionType) =>
                  new Promise((resolve) => {
                      if (submissionType === 'submit') {
                          dispatch(unMarkTaskForOffline(taskId));
                          dispatch(unsetDownloadedListViews());
                          setTimeout(() => {
                              resolve();
                          }, 100);
                          return;
                      }
                      // if no offline data - resolve immediately
                      setOpenOfflinePrompt({
                          _tag: 'open',
                          cb: (remove) => {
                              if (remove) {
                                  dispatch(unMarkTaskForOffline(taskId));
                                  dispatch(unsetDownloadedListViews());
                              }
                              // allow the store subscription a chance to do its work - there are some async things it needs to do.
                              setTimeout(() => {
                                  resolve();
                              }, 100);
                          },
                      });
                  })
            : undefined,
        relatedEntityResource,
        relatedEntityId,
        entityFormContextRef,
    );
    const { assigneeIsDirty, taskAttributesDirtyKeys, taskAttributesAreDirty } = useTaskAttributesAreDirty();

    const taskAttributesDirtyAlert = React.useCallback(() => {
        alert(
            `Please save task ${
                taskAttributesAreDirty
                    ? taskAttributesDirtyKeys.map(translateTaskAttributesKey).join(assigneeIsDirty ? ', ' : ' and ')
                    : ''
            }${
                assigneeIsDirty && taskAttributesAreDirty ? ' and assignee' : assigneeIsDirty ? 'assignee' : ''
            } before continuing.`,
        );
    }, [taskAttributesAreDirty, assigneeIsDirty, taskAttributesDirtyKeys]);

    if (taskAttributesAreDirty || assigneeIsDirty) {
        return (
            <Button variant="contained" {...ButtonProps} onClick={taskAttributesDirtyAlert}>
                {buttonText}
            </Button>
        );
    }
    return (
        <React.Fragment>
            <ErrorDialog
                entityFormContextRef={entityFormContextRef}
                resource={resource}
                errorDialogOpen={errorDialogOpen}
                closeDialog={closeDialog}
            />
            <WorkingDialog resource={resource} workingState={workingState} clearWorkingState={clearWorkingState} />
            {offlinePrompt._tag === 'open' ? (
                <RemoveOfflineDataDialog
                    open={true}
                    remove={(shouldRemove) => {
                        offlinePrompt.cb(shouldRemove);
                    }}
                />
            ) : null}
            <SaveTrigger
                formDefinition={formDefinition}
                cascadingSave={cascadingSave}
                openErrorDialog={setErrorDialogOpen}
                outcome={outcome}
                fields={fields}
                submissionType={submissionType}
                forceDisabled={forceDisabled}
                taskId={taskId}
                handleSubmit={handleSubmit}
                buttonText={buttonText}
                onSuccess={onSuccess}
                ButtonProps={ButtonProps}
                entityFormContextRef={entityFormContextRef}
            />
        </React.Fragment>
    );
};
export default SaveButton;
