import { useMemo } from 'react';
import produce from 'immer';
import {
    getIsRemovedByCasetivityRemovedFieldsDataProperty,
    useCasetivityRemovedFields,
} from '../EnhancedRGridTabbable';

// takes fieldsByTab and removes tabs where all fields are hidden.
const useFieldsByTab = (
    _fieldsByTab: {
        [tab: string]: any[];
    } = {},
    context: 'show' | 'edit',
) => {
    const [casetivityRemovedFields, fc] = useCasetivityRemovedFields(context);

    const tabsWithAllFieldsHidden = useMemo(() => {
        return Object.entries(_fieldsByTab)
            .filter(([tabName, fields]) => {
                return fields.every((f) => {
                    if (
                        f.props.fieldInstanceIdentifier &&
                        f.props.source?.endsWith('Id') &&
                        fc.hiddenFields[f.props.fieldInstanceIdentifier + 'Id']
                    ) {
                        // special case to accommodate old (bad) way of doing things where we appended
                        // 'Id' to the fieldInstanceIdentifier.
                        return true;
                    }
                    return (
                        fc.hiddenFields[f.props.fieldInstanceIdentifier || f.props.source] ||
                        getIsRemovedByCasetivityRemovedFieldsDataProperty(casetivityRemovedFields)(f.props.source)
                    );
                });
            })
            .map(([k]) => k);
    }, [fc.hiddenFields, _fieldsByTab, casetivityRemovedFields]);
    const fieldsByTab = useMemo(() => {
        return produce(_fieldsByTab, (fbt) => {
            tabsWithAllFieldsHidden.forEach((t) => {
                delete fbt[t];
            });
            return fbt;
        });
    }, [_fieldsByTab, JSON.stringify(tabsWithAllFieldsHidden)]); // eslint-disable-line
    return fieldsByTab;
};
export default useFieldsByTab;
