import React, { useCallback, useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { TaskForm } from '../../reducers/taskFormType';
import * as flowableFieldTypes from 'fieldFactory/translation/fromFlowable/flowableFieldTypes';
import { FormFieldConfigs, FormFieldOption, FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import {
    Button,
    CardActions,
    Checkbox,
    createStyles,
    FormControlLabel,
    IconButton,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import Popup from 'components/Popup';
import EditIcon from '@material-ui/icons/Edit';
import SpelExpressionForm from './SpelExpressionForm';
import { ValidationsEditor } from 'fieldFactory/input/components/ValidationExpressionEditor';
import CodemirrorJSONEditor from 'components/CodemirrorJSONEditor/LazyEditor';
import produce from 'immer';
import set from 'lodash/set';
import NumberInput from 'fieldFactory/input/components/NumberInput';
import { OptionsEditor } from './components/options/OptionsEditor';
import OptionsForm from './components/options/OptionForm';
import EditableTableEditorField from './components/table/TableEditor';
import clone from 'clone';
import insertOrReplaceTableField from './components/table/util/insertOrReplaceTableField';
import EntityTypeSelect from 'layout-editor/build-layout/fields/EntityTypeSelect';
import ModedHtmlEditor from './components/ModedHtmlEditor';
import Help from '@material-ui/icons/Help';
import { postFixInUrl } from 'clients/utils/translateFieldWithSearchTypeAppended';
import useBpmFormConfigFields from './fieldConfigEditors/useBpmFormConfigFields';
import useAddressConfigFields from './fieldConfigEditors/useAddressConfigFields';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
    }),
);

const typeOptions = Object.values(flowableFieldTypes);

type Field = TaskForm['fields'][any];

type flowableFieldType = (typeof flowableFieldTypes)[keyof typeof flowableFieldTypes];

interface FieldValues {
    // fieldType: I'm not sure if this is used for anything. we basically ignore this on the frontend.
    // lets just modify this on 'save'
    // fieldType: 'FormField' | 'OptionFormField' | 'ExpressionFormField';
    id: string;
    name: string;
    // overrideId: boolean; <- maybe lets make this always true?
    // readOnly: boolean; <- not really ever used I think
    required: boolean;
    type: flowableFieldType;
    expression?: string;

    params?: {
        configs?: FormFieldConfigs;
        maxLength?: number | string;
        minLength?: number | string;
        regexPattern?: string;
        regexMessage?: string;
        entityField?: string; // <- linked-field.
        row?: string;
        column?: string;
        span?: string;
        multiple?: boolean; // <- what is this?
        mask?: string;
        address?: {
            // address
            configs: string;
        };
        event?: {
            // event
            configs: string;
        };
        // expression params
        expressionCustom?: string; // <- is this used???
        size?: string;
        // table
        columnObj: {};
        columns: string; //<- stringified obj

        // valueset
        valueSet?: string;
        multiSelectValueSet: string; // multivalueset/chckbx/radio
        group?: string;
        availableConcepts?: string;

        entity?: string; // entitylookup, dmsdoclink, image
        filter?: string; // entitylookup, typeahead, multientitylookup, list

        viewName: string; // listfield
        useCheckboxes;
    };
    // radio and option
    hasEmptyValue?: boolean;
    options?: FormFieldOption[];
}
interface EditTaskFormField {
    taskForm?: Partial<TaskForm>;
    initialData: Partial<Field>;
    inTablePath?: string;
    onSubmit: (data: Field) => void;
}
const FLOWABLE_WIDGETTYPE_LABELS: {
    [key in (typeof flowableFieldTypes)[keyof typeof flowableFieldTypes]]?: string;
} = {
    'bpm-form': 'Dynamic Form',
    'bpm-form-builder': 'Dynamic Form Builder',
    address: 'deprecated address (do not use)',
    address2: 'Address',
};

const EditTaskFormField: React.FC<EditTaskFormField> = ({
    initialData,
    onSubmit: _onSubmit,
    taskForm,
    inTablePath = '',
}) => {
    const { producedBpmFieldConfig, ConfigElement: BpmFormConfigElement } = useBpmFormConfigFields({ initialData });
    const { addressFieldConfig, ConfigElement: AddressConfigElement } = useAddressConfigFields({ initialData });
    const onSubmit = useCallback(
        (data: Field) => {
            const saveData = produce(data, (draft) => {
                if (!get(data, 'params.configs.fieldConfig')) {
                    set(draft, 'params.configs.fieldConfig', undefined);
                }
                if (data.type === 'table') {
                    const columnObj = get(data, 'params.columnObj');
                    set(draft, 'params.columns', columnObj ? JSON.stringify(columnObj, null, 1) : null);
                }
                if (data.type === 'bpm-form') {
                    set(draft, 'params.configs.fieldConfig', producedBpmFieldConfig);
                }
                if (data.type === 'address2') {
                    set(draft, 'params.configs.fieldConfig', addressFieldConfig);
                }
                return draft;
            });
            _onSubmit(saveData);
        },
        [_onSubmit, producedBpmFieldConfig, addressFieldConfig],
    );
    const methods = useForm<FieldValues>({
        defaultValues: initialData,
    });
    const { register, handleSubmit, control, watch, errors } = methods;
    const classes = useStyles();
    const id = watch('id', initialData?.id);
    const type = watch('type', initialData?.type);
    const rgx_pattern = watch('params.regexPattern', initialData?.params?.regexPattern);
    const formData = watch();
    const { fieldVariant, getInputLabelProps } = React.useContext(themeOverrideContext);

    const renderIntegerField = (name: string, label: string = capitalize(name), options?: { max?: number }) => {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={get(initialData, name) ?? ''}
                render={({ onChange, onBlur, value }) => (
                    <NumberInput
                        options={{ margin: 'normal' }}
                        input={{
                            onChange: (e) => onChange(parseInt(e)),
                            onBlur: (e) => {
                                onChange(parseInt(e));
                                onBlur();
                            },
                            value,
                        }}
                        label={label}
                        meta={(() => {
                            const message = get(errors, name + '.message');
                            if (!message) {
                                return {};
                            }
                            return {
                                error: message,
                                touched: true,
                            };
                        })()}
                        isInteger
                        source={name}
                    />
                )}
                rules={
                    typeof options?.max === 'number'
                        ? {
                              max: {
                                  value: options.max,
                                  message: `Cannot be more than ${options.max}`,
                              },
                          }
                        : undefined
                }
            />
        );
    };
    const renderHtmlWysiwyg = (name: string, label: string = capitalize(name)) => {
        return (
            <Controller
                name={name}
                fullWidth
                control={control}
                defaultValue={get(initialData, name) ?? ''}
                render={(props) => (
                    <ModedHtmlEditor value={props.value} onChange={props.onChange} onBlur={props.onBlur} />
                )}
                error={Boolean(get(errors, name))}
                helperText={get(errors, name)?.message}
            />
        );
    };
    const renderTextField = (name: string, label: string = capitalize(name), required: boolean = false) => (
        <Controller
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label={label}
            name={name}
            fullWidth
            control={control}
            defaultValue={get(initialData, name) ?? ''}
            margin="normal"
            as={TextField}
            rules={required ? { required: label + ' is Required' } : undefined}
            error={Boolean(get(errors, name))}
            helperText={get(errors, name)?.message}
        />
    );
    const renderEntityTypeSelectField = () => {
        return <EntityTypeSelect fieldName="params.entity" label="Entity" required="An entity is required" />;
    };
    const renderBooleanField = (name: string, label: string = capitalize(name)) => (
        <Controller
            name={name}
            control={control}
            defaultValue={get(initialData, name) ?? false}
            render={({ onChange, value }) => (
                <FormControlLabel
                    htmlFor={name + '-bool-Field'}
                    control={
                        <Checkbox
                            id={name + '-bool-Field'}
                            inputProps={{
                                'aria-label': label,
                            }}
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    }
                    label={label}
                />
            )}
        />
    );
    const taskFormPreview = useMemo((): Partial<TaskForm> => {
        const { fields } = taskForm;
        // modify to include current field

        if (inTablePath) {
            const modifiedFields = insertOrReplaceTableField(initialData?.id, id)(
                fields,
                inTablePath,
                clone(formData) as unknown as FormFieldUnion,
            );
            return {
                ...taskForm,
                fields: modifiedFields,
            };
        }

        const thisField = fields.find((f) => f.id === initialData?.id ?? id);
        if (thisField) {
            const ix = fields.indexOf(thisField);
            const res = {
                ...taskForm,
                fields: [...fields.slice(0, ix), formData as any, ...fields.slice(ix + 1)],
            };
            return res;
        }
        return {
            ...taskForm,
            fields: [...fields, formData as any],
        };
    }, [taskForm, initialData?.id, formData, inTablePath, id]);
    const renderExpressionField = (name: string, label: string) => (
        <Controller
            name={name}
            control={control}
            defaultValue={get(initialData, name) ?? ''}
            render={({ onChange, value }) => {
                return (
                    <Popup
                        renderDialogContent={({ closeDialog }) => (
                            <div onSubmit={(e) => e.stopPropagation()} style={{ padding: '1em' }}>
                                <SpelExpressionForm
                                    taskForm={taskFormPreview}
                                    initialExpression={value ?? ''}
                                    onSubmit={(params) => {
                                        const { expression } = params;
                                        onChange(expression);
                                        closeDialog();
                                    }}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <div>
                                <span>
                                    <b>{label} expression:</b>&nbsp;
                                    {!value ? <em>None</em> : value}&nbsp;
                                    <IconButton aria-label={`Edit ${label} expression`} onClick={openDialog()}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </div>
                        )}
                    />
                );
            }}
        />
    );

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={(e) => {
                    if (e.target !== e.currentTarget) {
                        /**
                         * Prevent nested form submission bug in React 17+
                         */
                        return false;
                    }
                    return handleSubmit(onSubmit)(e);
                }}
            >
                <Controller
                    name="id"
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Id"
                    fullWidth
                    control={control}
                    defaultValue={initialData?.id ?? ''}
                    margin="normal"
                    as={TextField}
                    error={Boolean(errors?.id)}
                    helperText={(() => {
                        if (errors?.id?.type === 'endsWithId') {
                            return 'If a field is operating on individual id values (e.g. entity typeahead, value-set) it MUST end with "Id"';
                        }
                        if (errors?.id?.type === 'endsWithIds') {
                            return 'If a field is operating on an array of id values (e.g. list-view, value-set-multi-select) it MUST end with "Ids"';
                        }
                        return errors?.id?.message;
                    })()}
                    rules={{
                        required: 'An Id is Required',
                        validate: {
                            endsWithId: (value) => {
                                if (initialData?.id === value) {
                                    // let it bypass if already saved.
                                    // since lots of forms have this issue.
                                    return true;
                                }
                                if (
                                    type === 'entity-lookup' ||
                                    type === 'entity-typeahead' ||
                                    type === 'value-set-dropdown' ||
                                    type === 'value-set-radiobox'
                                ) {
                                    // we are looking at a ref-1 field working on an id of either an entity or a valueset concept
                                    if (value && !value.endsWith('Id')) {
                                        return false;
                                    }
                                }
                                return true;
                            },
                            endsWithIds: (value) => {
                                if (initialData?.id === value) {
                                    // let it bypass if already saved.
                                    // since lots of forms have this issue.
                                    return true;
                                }
                                if (
                                    type === 'entity-multi-select-chip' ||
                                    type === 'multiple-entity-typeahead' ||
                                    type === 'list-view' ||
                                    type === 'value-set-multi-checkbox' ||
                                    type === 'value-set-multi-select'
                                ) {
                                    // we are looking at an array of ids
                                    if (value && !value.endsWith('Ids')) {
                                        return false;
                                    }
                                }
                                return true;
                            },
                        },
                    }}
                />
                {type !== 'expression' && renderTextField('name', 'Label')}
                <Controller
                    name="type"
                    control={control}
                    defaultValue={initialData?.type}
                    rules={{ required: 'Type is Required' }}
                    render={({ onChange, value }) => (
                        <Autocomplete
                            options={typeOptions}
                            value={value ?? ''}
                            defaultValue={initialData?.type ?? ''}
                            autoHighlight
                            onChange={(e, value) => onChange(value)}
                            getOptionLabel={(option) => FLOWABLE_WIDGETTYPE_LABELS[option] ?? option}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldVariant as any}
                                    InputLabelProps={getInputLabelProps({ shrink: true })}
                                    label="Type"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        draggable: false,
                                        autoComplete: 'disabled',
                                    }}
                                    error={Boolean(get(errors, 'type'))}
                                    helperText={get(errors, 'type')?.message}
                                />
                            )}
                        />
                    )}
                />
                {type !== 'expression' && renderBooleanField('required', 'Required')}
                {inTablePath ? renderIntegerField('params.span', 'Span', { max: 12 }) : null}
                {(() => {
                    if (type) {
                        switch (type) {
                            case 'expression': {
                                return renderHtmlWysiwyg('expression');
                            }
                            case 'multi-line-text':
                            case 'text': {
                                return (
                                    <>
                                        {renderIntegerField('params.maxLength', 'Max Length')}
                                        {renderIntegerField('params.minLength', 'Min Length')}
                                        {renderTextField('params.regexPattern', 'Regex (pattern)')}
                                        {rgx_pattern && renderTextField('params.regexMessage', 'Regex (message)')}
                                    </>
                                );
                            }
                            case 'dropdown':
                            case 'radio-buttons': {
                                return (
                                    <>
                                        {renderBooleanField('hasEmptyValue', 'Has Empty Value')}
                                        <div onSubmit={(e) => e.stopPropagation()}>
                                            <Controller
                                                name="options"
                                                control={control}
                                                defaultValue={initialData?.options ?? []}
                                                render={({ onChange, value }) => (
                                                    <OptionsEditor
                                                        options={value}
                                                        setOptions={onChange}
                                                        renderOptionEditor={({ initialValues, onSubmit }) => {
                                                            return (
                                                                <OptionsForm
                                                                    initialData={initialValues}
                                                                    onSubmit={onSubmit}
                                                                    taskForm={taskFormPreview}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                            <div style={{ height: '35px' }} />
                                        </div>
                                    </>
                                );
                            }
                            case 'table': {
                                // columns
                                return (
                                    <div onSubmit={(e) => e.stopPropagation()}>
                                        <Controller
                                            name="params.columnObj"
                                            control={control}
                                            defaultValue={get(initialData, 'params.columnObj', [])}
                                            render={({ onChange, value }) => (
                                                <EditableTableEditorField
                                                    renderFieldEditor={({ initialValues, onSubmit }) => (
                                                        <EditTaskFormField
                                                            inTablePath={inTablePath ? inTablePath + '.' + id : id}
                                                            // todo: pass table context, so those values are in context for expressions
                                                            // i.e. pass table id, and fields in table
                                                            taskForm={taskFormPreview}
                                                            initialData={initialValues}
                                                            onSubmit={onSubmit}
                                                        />
                                                    )}
                                                    label="Table Columns"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        <div style={{ height: '35px' }} />
                                    </div>
                                );
                            }
                            case 'value-set-dropdown':
                            case 'value-set-radiobox':
                            case 'valueset-suggest': {
                                return (
                                    <>
                                        {renderTextField('params.valueSet', 'Valueset', true)}
                                        {renderTextField('params.group', 'Valueset group')}
                                        {renderExpressionField('params.availableConcepts', 'Available Concepts')}
                                    </>
                                );
                            }
                            case 'value-set-multi-select':
                            case 'value-set-multi-checkbox': {
                                return (
                                    <>
                                        {renderTextField('params.multiSelectValueSet', 'Multiselect Valueset', true)}
                                        {renderTextField('params.group', 'Valueset group')}
                                        {renderExpressionField('params.availableConcepts', 'Available Concepts')}
                                    </>
                                );
                            }
                            case 'entity-lookup':
                            case 'multiple-entity-typeahead':
                            case 'entity-typeahead': {
                                return (
                                    <>
                                        {/* TODO Replace with an autocomplete with all entities */}
                                        {renderEntityTypeSelectField()}
                                        {/* TODO add 'help' */}
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1 }}>{renderTextField('params.filter', 'Filter')}</div>
                                            <Popup
                                                renderDialogContent={({ closeDialog }) => (
                                                    <div style={{ padding: '1em' }}>
                                                        <p>
                                                            Filters are formatted as comma-seperated key-value pairs,
                                                            with an optional qualifier appended to the key.
                                                        </p>
                                                        <p>
                                                            For example, if I wanted to restrict the firstName field on
                                                            the entity pointed to in this field to "Bob" The filter
                                                            would read simply,
                                                            <pre>firstName=Bob</pre>. If we wanted to add a last name
                                                            restriction, we might have
                                                            <pre>firstName=Bob,lastName=Jones</pre>
                                                            If we wanted to ensure all firstNames were allowed except
                                                            for bob, we would append a "NOT_EQUALS" qualifier to the
                                                            key, seperated with double-underscores:
                                                            <pre>firstName__NOT_EQUALS=Bob</pre>
                                                            The possible qualifiers are:
                                                            <ul>
                                                                {Object.keys(postFixInUrl)
                                                                    .filter(
                                                                        (k) =>
                                                                            // these two really just map onto .equals,
                                                                            // but add asterisks to the end or both sides of the searched value
                                                                            k !== 'CONTAINS' && k !== 'STARTS_WITH',
                                                                    )
                                                                    .map((k) => (
                                                                        <li key={k}>{k}</li>
                                                                    ))}
                                                            </ul>
                                                            If we want to use an array of values (for example, a list of
                                                            firstNames not allowed) we would seperate each value with a
                                                            semicolon (because commas are already used to seperate
                                                            key-value pairs and are thus reserved for that purpose).
                                                            <pre>firstName__NOT_IN=Bob;Robert,lastName=Jones</pre>
                                                            If we would like a filter to be dynamic (for example, we
                                                            have a text-field in the form with the id "firstName") and
                                                            we would like to use whatever the currently typed value is,
                                                            we would do:
                                                            <pre>firstName=$[firstName]</pre>
                                                            <p>
                                                                (anything in the $[...] brackets is evaluated live, as a{' '}
                                                                <a href="https://docs.spring.io/spring-framework/docs/3.0.x/reference/expressions.html#expressions-language-ref">
                                                                    SPEL expression.
                                                                </a>{' '}
                                                                So you can include logic, or make function calls within
                                                                that bracketed expression.
                                                            </p>
                                                            <p>
                                                                You can also template how the form is served from the
                                                                backend, where tags are only evaluated once, using{' '}
                                                                {'${}'} tags. Inside you can evaluate snippets of groovy
                                                                code, accessing process variables which may not be in
                                                                the form.
                                                            </p>
                                                        </p>
                                                    </div>
                                                )}
                                                renderToggler={({ openDialog }) => (
                                                    <div style={{ paddingTop: '24px' }}>
                                                        <IconButton
                                                            onClick={openDialog()}
                                                            aria-label="Filter explanation"
                                                        >
                                                            <Help />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </>
                                );
                            }
                            case 'list-view': {
                                return (
                                    <>
                                        {renderTextField('params.viewName', 'ViewName')}
                                        {renderTextField('params.filter', 'Filter')}
                                        {renderBooleanField('params.useCheckboxes', 'Use Checkboxes')}
                                    </>
                                );
                            }
                            case 'dmsdoclink':
                            case 'image': {
                                return <>{renderEntityTypeSelectField()}</>;
                            }
                            default:
                                return null;
                        }
                    }
                })()}
                {renderExpressionField('params.configs.visibility', 'Visibility')}
                {type !== 'expression' && renderExpressionField('params.configs.editable', 'Editable')}
                {type !== 'expression' && (
                    <Controller
                        name="params.configs.validation"
                        control={control}
                        defaultValue={get(initialData, 'params.configs.validation', '[]')}
                        render={({ onChange, value }) => (
                            <div onSubmit={(e) => e.stopPropagation()}>
                                <ValidationsEditor
                                    validations={(() => {
                                        if (!value) {
                                            return [];
                                        }
                                        const parsed = JSON.parse(value);
                                        if (!Array.isArray(parsed)) {
                                            return [];
                                        }
                                        return parsed;
                                    })()}
                                    setValidationExpression={(validations) => onChange(JSON.stringify(validations))}
                                    renderValidationEditor={({ initialValues, onSubmit }) => {
                                        return (
                                            <SpelExpressionForm
                                                includeMessage
                                                initialMessage={initialValues?.message}
                                                taskForm={taskFormPreview}
                                                initialExpression={initialValues?.expression ?? ''}
                                                onSubmit={({ expression, message }) => {
                                                    onSubmit({
                                                        expression,
                                                        message,
                                                    });
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        )}
                    />
                )}
                <div style={{ height: '35px' }} />
                {type !== 'expression' && (
                    <Controller
                        name="params.configs.fieldConfig"
                        control={control}
                        defaultValue={get(initialData, 'params.configs.fieldConfig', '')}
                        rules={{
                            validate: {
                                isJson: (value) => {
                                    if (!value) {
                                        return true;
                                    }
                                    try {
                                        JSON.parse(value);
                                        return true;
                                    } catch (e) {
                                        return false;
                                    }
                                },
                            },
                        }}
                        render={({ onChange }) => {
                            if (type === 'address2') {
                                return AddressConfigElement;
                            }
                            if (type === 'bpm-form') {
                                return BpmFormConfigElement;
                            }
                            return (
                                <label>
                                    <b>JSON FieldConfig</b>
                                    <br />
                                    <br />
                                    <div style={{ border: '1px solid #CDCDCD' }}>
                                        <CodemirrorJSONEditor
                                            onChange={onChange}
                                            initialValue={get(initialData, 'params.configs.fieldConfig', '')}
                                        />
                                    </div>
                                </label>
                            );
                        }}
                    />
                )}
                <pre className={classes.error}>
                    {get(errors, 'params.configs.fieldConfig.type', '') === 'isJson' ? 'Incorrect JSON config' : ''}
                </pre>

                <CardActions>
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </CardActions>
            </form>
        </FormProvider>
    );
};
export default EditTaskFormField;
