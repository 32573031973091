import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import JsonDownload from 'expression-tester/util/JsonDownload';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import EditableTaskFormLayout from 'layout-editor/components/EditableTaskFormLayout';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../reducers/taskFormType';

import { reduxForm } from 'redux-form';
import ReactDiffViewer from 'react-diff-viewer';
import stableStringify from 'json-stable-stringify';
import FormBuilderTabs from './components/Tabs';
import StartFormForm from 'bpm/start-form/components/StartForm';
import { createGetStartFormInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { useDispatch, useSelector } from 'react-redux';
import casetivityViewContext from 'util/casetivityViewContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ServerTemplatedBracketsController from './components/ServerTemplatedBracketsController/ServerTemplatedBracketsController';
import removeAttributesFromTaskFrom from './util/removeAttributesFromTaskForm';
import { FormContextProvider } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import ProcessAndTaskContextController from './components/ProcessAndTaskContextController/ProcessAndTaskContextController';
import { useLocation } from 'react-router-dom';
import SaveFormToModelerButton from './components/SaveFormToModelerButton/SaveFormToModelerButton';
import SelectTask from './components/ProcessAndTaskContextController/SelectTask';
import useViewConfig from 'util/hooks/useViewConfig';
import getTaskFormValueSets from 'bpm/components/TaskDetail/TaskForm/fetchValueSets';
import { loadValueSets } from 'valueSets/actions';
import debounce from 'lodash/debounce';
import OutcomesEditor from './components/OutcomesEditor';
import { useSaveAndCancelDisplayExpressions } from 'bpm/components/TaskDetail/TaskForm/TaskForm/Form/Layout';
import Help from '@material-ui/icons/Help';
import EvalExpressionInTaskContext from 'expressions/components/EvalExpressionInTaskFormContext';
import useEvaluateOutcomeExpression from 'bpm/components/TaskDetail/TaskForm/hooks/useEvaluateOutcomeExpression';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';

const ProvideOutcomesProperties: React.FunctionComponent<{
    taskForm: TaskForm;
    children: (properties: {
        renderAsStartForm: boolean;
        setRenderAsStartForm: (value: boolean) => void;
        saveAndCancelDisplayExpressions: {
            saveDisplay?: [string];
            cancelDisplay?: [string];
        };
        cancelIsVisible: boolean;
    }) => JSX.Element;
}> = ({ taskForm, children }) => {
    const [renderAsStartForm, setRenderAsStartForm] = React.useState(true);
    const saveAndCancelDisplayExpressions = useSaveAndCancelDisplayExpressions(taskForm);
    const cancelIsVisible = useEvaluateOutcomeExpression(taskForm, '_cancel', 'visibility');
    return children({
        renderAsStartForm,
        setRenderAsStartForm,
        saveAndCancelDisplayExpressions,
        cancelIsVisible,
    });
};

const modifyTaskFormForExport = (taskForm: Partial<TaskForm>) => {
    // 1. make sure row numbers are like 100, 200, etc instead of 1, 2, etc.
    const isNumberOrString = (n: unknown): n is number | string => {
        return typeof n === 'number' || typeof n === 'string';
    };
    return {
        ...taskForm,
        fields: taskForm.fields?.map((f) => {
            return {
                ...f,
                overrideId: true,
                params: {
                    ...f.params,
                    row: isNumberOrString(f.params?.row) ? String(parseInt(f.params.row) * 100) : undefined,
                    column: isNumberOrString(f.params?.column) ? String(f.params.column) : undefined,
                    span: isNumberOrString(f.params?.span) ? String(f.params.span) : undefined,
                },
            };
        }),
    };
};

interface TaskFormBuilderProps {
    initialTaskForm: Partial<TaskForm>;
    renderSaveButton?: (outputJson: TaskForm) => JSX.Element;
    formId?: string;
    modelerUrl?: string;
    noOutcomes?: boolean;
    hideIdField?: boolean;
    isCreate?: true;
    TopRightAction?: React.ReactNode;
}
const defaultInitialTaskForm: Partial<TaskForm> = {
    fields: [],
    id: '',
    key: '',
    name: '',
    outcomes: [],
};

const Form = reduxForm({
    form: 'test-form',
})((props) => <form>{props.children}</form>);

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useFetchFormConfigValuesets = (currentForm: Partial<TaskForm>) => {
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const fetchValuesets = useMemo(() => {
        let valueSetsFetched: Set<string> = new Set();

        const getValuesetsToFetch = (taskForm: TaskForm) => {
            const valueSetsReferenced = getTaskFormValueSets(taskForm, viewConfig).map((sr) =>
                sr.includes('.') ? sr.split('.')[0] : sr,
            );

            const toFetch = valueSetsReferenced.filter((vs) => !valueSetsFetched.has(vs));
            valueSetsReferenced.forEach((vs) => {
                valueSetsFetched.add(vs);
            });
            return toFetch;
        };
        return debounce((taskForm: TaskForm) => {
            const toFetch = getValuesetsToFetch(taskForm);
            dispatch(loadValueSets(toFetch.map((valueSet) => ({ valueSet }))));
        }, 500);
    }, [dispatch, viewConfig]);

    useEffect(() => {
        fetchValuesets(currentForm as TaskForm);
    }, [currentForm, fetchValuesets]);
};

export const useDuplicateLastRow = ({
    currentForm,
    setCurrentForm,
}: {
    currentForm: Partial<TaskForm>;
    setCurrentForm: (form: Partial<TaskForm>) => void;
}) => {
    const duplicateLastRow = useCallback(() => {
        const lastRow = currentForm.fields.reduce((prev, curr) => {
            const prevRowLevel = parseInt(prev[0]?.params?.row ?? '-1');

            const currLevel = parseInt(curr?.params?.row ?? '-1');
            if (currLevel > prevRowLevel) {
                return [curr];
            }
            if (currLevel >= 0 && currLevel === prevRowLevel) {
                return [...prev, curr];
            }
            return prev;
        }, [] as FormFieldUnion[]);
        const increment = (text: string) =>
            text.match(/-[0-9]*$/)
                ? text.slice(0, text.lastIndexOf('-') + 1) + (parseInt(text.slice(text.lastIndexOf('-') + 1)) + 1)
                : text + '-1';
        setCurrentForm({
            ...currentForm,
            fields: [
                ...currentForm.fields,
                ...lastRow.map((f) => ({
                    ...f,
                    id: increment(f.id),
                    name: f.name || f.id,
                    params: {
                        ...(f.params as any),
                        row: parseInt(f.params.row) + 1,
                    },
                })),
            ],
        });
    }, [currentForm, setCurrentForm]);
    return duplicateLastRow;
};

export const useOutputJsonForForm = (currentForm: Partial<TaskForm>) => {
    const outputJson = useMemo(() => {
        // strip out value, since we might inject values to help with the preview of e.g. expression-fields
        return modifyTaskFormForExport({
            ...currentForm,
            fields: currentForm.fields?.map(({ value, ...f }) => f) as any,
        });
    }, [currentForm]);
    return outputJson;
};

export const useFormInitialValues = (currentForm: Partial<TaskForm>) => {
    const getInitialValues = useMemo(() => createGetStartFormInitialValues(), []);
    const initialValues = useSelector((state: RootState) =>
        getInitialValues(state, { formDefinition: currentForm as TaskForm }),
    );
    return initialValues;
};

export const useFormWithoutExpressions = (currentForm: Partial<TaskForm>) => {
    const formWithoutExpressions = useMemo(() => {
        return removeAttributesFromTaskFrom(
            currentForm as TaskForm,
            [
                'fields.params.filter',
                'fields.params.configs.editable',
                'fields.params.configs.validation',
                'fields.params.configs.visibility',
                'fields.params.configs.availableConcepts',
                'fields.params.configs.fieldConfig',
            ].flatMap((p) => {
                return [
                    p,
                    'fields.params.columnObj.' + p.slice('fields.'.length),
                    'fields.params.columnObj.params.columnObj.' + p.slice('fields.'.length),
                ];
            }),
        );
    }, [currentForm]);
    return formWithoutExpressions;
};

export const useRenderFormPreview = ({ noOutcomes }: { noOutcomes?: boolean }) => {
    const [submittedValues, setSubmittedValues] = useState(null);

    const renderFormPreview = useCallback(
        ({ taskForm }) => {
            return (
                <ProvideOutcomesProperties taskForm={taskForm}>
                    {({
                        renderAsStartForm,
                        setRenderAsStartForm,
                        saveAndCancelDisplayExpressions,
                        cancelIsVisible,
                    }) => (
                        <div style={{ padding: '1em' }}>
                            {noOutcomes ? null : (
                                <div style={{ padding: '1em' }}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={renderAsStartForm}
                                                    onChange={(e) => setRenderAsStartForm(Boolean(e.target.checked))}
                                                    name="renderAsStartForm"
                                                />
                                            }
                                            label="Preview as Start Form"
                                        />
                                        <span>
                                            <Tooltip title="Start forms don't show 'save' or 'cancel' outcomes at bottom">
                                                <Help style={{ verticalAlign: 'middle', height: '100%' }} />
                                            </Tooltip>
                                        </span>
                                    </FormGroup>
                                </div>
                            )}
                            <StartFormForm
                                noOutcomes={noOutcomes}
                                interceptSubmitCb={setSubmittedValues}
                                businessKey={taskForm.key}
                                formDefinition={taskForm as TaskForm}
                                renderBeforeOutcomes={() => {
                                    if (renderAsStartForm || noOutcomes) {
                                        return null;
                                    }
                                    return (
                                        <EvalExpressionInTaskContext
                                            formDefinition={taskForm as TaskForm}
                                            expressions={saveAndCancelDisplayExpressions}
                                        >
                                            {(res) => (
                                                <>
                                                    <Button variant="contained" color="primary">
                                                        {res.saveDisplay?.[0] || 'Save'}
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {cancelIsVisible && (
                                                        <Button variant="contained">
                                                            {res.cancelDisplay?.[0] || 'Cancel'}
                                                        </Button>
                                                    )}
                                                    &nbsp;&nbsp;
                                                </>
                                            )}
                                        </EvalExpressionInTaskContext>
                                    );
                                }}
                            />
                            {submittedValues ? (
                                <Card style={{ marginTop: '1em' }}>
                                    <CardHeader title="Submitted Values" />
                                    <CardContent>
                                        <pre>{JSON.stringify(submittedValues, null, 1)}</pre>
                                    </CardContent>
                                </Card>
                            ) : null}
                        </div>
                    )}
                </ProvideOutcomesProperties>
            );
        },
        [setSubmittedValues, submittedValues, noOutcomes],
    );
    return renderFormPreview;
};
export const TaskFormBuilder: React.FC<TaskFormBuilderProps> = ({
    initialTaskForm = defaultInitialTaskForm,
    formId,
    modelerUrl,
    noOutcomes,
    isCreate = false,
    hideIdField = false,
    renderSaveButton,
    TopRightAction,
}) => {
    const initialTaskFormWithoutValues = useMemo(
        () => ({
            ...initialTaskForm,
            fields: initialTaskForm.fields?.map(({ value, ...f }) => f),
        }),
        [initialTaskForm],
    );
    const [currentForm, setCurrentForm] = useState<Partial<TaskForm>>(initialTaskForm);
    useFetchFormConfigValuesets(currentForm);
    const [refreshKey, refresh] = useReducer((state) => state + 1, 1);
    const { fieldVariant, getInputLabelProps } = React.useContext(themeOverrideContext);
    const handleIdChange = useCallback(
        (e) => {
            setCurrentForm({
                ...currentForm,
                id: e.target.value,
            });
        },
        [currentForm, setCurrentForm],
    );
    const handleKeyChange = useCallback(
        (e) => {
            setCurrentForm({
                ...currentForm,
                key: e.target.value,
            });
        },
        [currentForm, setCurrentForm],
    );
    const handleNameChange = useCallback(
        (e) => {
            setCurrentForm({
                ...currentForm,
                name: e.target.value,
            });
        },
        [currentForm, setCurrentForm],
    );
    const duplicateLastRow = useDuplicateLastRow({
        currentForm,
        setCurrentForm: (form) => {
            setCurrentForm(form);
            refresh();
        },
    });
    const outputJson = useOutputJsonForForm(currentForm);
    const initialValues = useFormInitialValues(currentForm);

    const query = useQuery();

    const formWithoutExpressions = useFormWithoutExpressions(currentForm);

    const renderFormPreview = useRenderFormPreview({ noOutcomes });

    return (
        <div>
            <div style={{ padding: '1em' }}>
                <Card>
                    <CardHeader title="Build Task Form" action={TopRightAction} />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid container item xs={12} sm={6} spacing={3}>
                                {!isCreate && !hideIdField && (
                                    <Grid item xs={12} lg={4}>
                                        <TextField
                                            variant={fieldVariant}
                                            InputLabelProps={getInputLabelProps({})}
                                            fullWidth
                                            name="id"
                                            label="Form id"
                                            value={currentForm.id || ''}
                                            onChange={handleIdChange}
                                            onBlur={handleIdChange}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        variant={fieldVariant}
                                        InputLabelProps={getInputLabelProps({})}
                                        name="key"
                                        label="Form key"
                                        fullWidth
                                        value={currentForm.key || ''}
                                        onChange={handleKeyChange}
                                        onBlur={handleKeyChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        variant={fieldVariant}
                                        InputLabelProps={getInputLabelProps({})}
                                        name="name"
                                        label="Form name"
                                        fullWidth
                                        value={currentForm.name || ''}
                                        onChange={handleNameChange}
                                        onBlur={handleNameChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <div style={{ padding: '1em' }}>
                <FormBuilderTabs
                    keepTabsMounted
                    layoutEditorElement={
                        <Card style={{ margin: '1em', padding: '1em' }}>
                            <casetivityViewContext.Provider value="START_FORM">
                                <FormContextProvider contextType="start-form" formDefinition={formWithoutExpressions}>
                                    <Form initialValues={initialValues}>
                                        <stagedFormDefinitionContext.Provider
                                            value={{
                                                formDefinition: currentForm as TaskForm,
                                                setFormDefinition: () => {},
                                            }}
                                        >
                                            <EditableTaskFormLayout
                                                key={refreshKey}
                                                formDefinition={currentForm as TaskForm}
                                                onFormDefinitionChange={({ formDefinition }) =>
                                                    setCurrentForm(formDefinition)
                                                }
                                            />
                                            <Button onClick={duplicateLastRow} variant="contained" color="primary">
                                                Duplicate bottom row
                                            </Button>
                                            <div style={{ margin: '1em' }} />
                                            <Typography variant="h6">
                                                Outcomes&nbsp;
                                                <Tooltip title="Outcomes named _save and _cancel override the default Save and Cancel on non-start-forms. Otherwise, they are new outcomes replacing the default 'Complete' outcome.">
                                                    <Help
                                                        style={{
                                                            verticalAlign: 'middle',
                                                            height: '100%',
                                                            opacity: 0.8,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography>
                                            <OutcomesEditor
                                                taskForm={currentForm as TaskForm}
                                                outcomes={currentForm.outcomes ?? []}
                                                setOutcomes={(outcomes) => {
                                                    setCurrentForm({
                                                        ...currentForm,
                                                        outcomes,
                                                    });
                                                }}
                                            />
                                        </stagedFormDefinitionContext.Provider>
                                    </Form>
                                </FormContextProvider>
                            </casetivityViewContext.Provider>
                        </Card>
                    }
                    previewElement={
                        <div>
                            <SelectTask taskId={query.get('taskId')} formKey={currentForm.key} />
                            {query.get('processId') && query.get('taskId') ? (
                                <ProcessAndTaskContextController
                                    processId={query.get('processId')}
                                    taskId={query.get('taskId')}
                                    formCurrentlyEditing={currentForm as TaskForm}
                                >
                                    {({ templatedForm }) => (
                                        <ServerTemplatedBracketsController
                                            processId={query.get('processId')}
                                            taskForm={templatedForm}
                                            renderWhenReady={renderFormPreview}
                                        />
                                    )}
                                </ProcessAndTaskContextController>
                            ) : (
                                <ServerTemplatedBracketsController
                                    taskForm={currentForm as TaskForm}
                                    renderWhenReady={renderFormPreview}
                                />
                            )}
                        </div>
                    }
                />
            </div>
            {!isCreate && (
                <div style={{ padding: '1em' }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>View Diff</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {initialTaskForm === defaultInitialTaskForm ? (
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    disabled
                                    value={JSON.stringify(outputJson, null, 1)}
                                />
                            ) : (
                                <div style={{ width: '100%', wordBreak: 'break-all' }}>
                                    <ReactDiffViewer
                                        oldValue={stableStringify(initialTaskFormWithoutValues, { space: ' ' })}
                                        newValue={stableStringify(outputJson, { space: ' ' })}
                                        splitView={true}
                                    />
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
            <div style={{ padding: '1em' }}>
                <Card>
                    <CardHeader title="Save" />
                    <CardActions>
                        <JsonDownload json={outputJson} />
                        {renderSaveButton?.(outputJson as TaskForm) ??
                            (formId && modelerUrl && (
                                <SaveFormToModelerButton
                                    modelerUrl={modelerUrl}
                                    formId={formId}
                                    formDefinition={outputJson as TaskForm}
                                />
                            ))}
                    </CardActions>
                </Card>
            </div>
        </div>
    );
};

interface TaskFormControllerProps {
    isCreate?: true;
    hideIdField?: boolean;
    TopRightAction?: React.ReactNode;
    renderSaveButton?: (outputJson: TaskForm) => JSX.Element;
}
const TaskFormController: React.FC<TaskFormControllerProps> = (props) => {
    const [importedState, setImportedState] = useState<TaskForm | -1>(-1);
    const importState = (file: File) => {
        const success = (content) => {
            const taskForm = JSON.parse(content);
            setImportedState(taskForm);
        };
        const fileReader = new FileReader();
        fileReader.onload = (evt: ProgressEvent & { target: { result?: ArrayBuffer | string } | null }) => {
            if (evt && evt.target && evt.target.result) {
                success(evt.target.result);
            }
        };
        fileReader.readAsText(file);
    };
    let refreshKeyRef = useRef<number>(1);
    const refreshKey = useMemo(() => {
        return ++refreshKeyRef.current;
    }, [importedState]); // eslint-disable-line

    return (
        <div>
            <div style={{ padding: '1em' }}>
                <Card>
                    <CardHeader title="Import file" />
                    <CardContent>
                        <input
                            type="file"
                            style={{
                                marginBottom: '1em',
                            }}
                            accept="application/json"
                            onChange={(e) => e.target.files && importState(e.target.files[0])}
                        />
                    </CardContent>
                </Card>
            </div>
            <TaskFormBuilder
                TopRightAction={props.TopRightAction}
                hideIdField={props.hideIdField}
                isCreate={props.isCreate}
                renderSaveButton={props.renderSaveButton}
                key={refreshKey}
                initialTaskForm={importedState === -1 ? undefined : importedState}
            />
        </div>
    );
};

export default TaskFormController;
