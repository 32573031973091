import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { taskClosed } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { SimpleFormField } from 'fieldFactory/translation/fromFlowable/types';
import fromEntries from 'util/fromentries';
import Edit from './Edit2';
import { EditProps } from './EditProps';
import { useDefaultRouteFromView, useResourceName } from 'util/hooks/useDefaultView';
import useViewConfig from 'util/hooks/useViewConfig';
import { getIsDynamicView } from '../DynamicView/util';
import SimpleDynamicView from '../DynamicView/SimpleDynamicView';
import { DynamicViewIndicator } from '../hoc/withVisibleViewName';

const GenericEdit: React.FunctionComponent<EditProps> = React.memo((props) => {
    const resource = useResourceName(props.viewName);
    const defaultShowViewRoute = useDefaultRouteFromView(props.viewName, 'SHOW')
        .map((r) => r.split(':id').join(props.id))
        .getOrElse(`/${resource}/${props.id}/show`);

    const redirect = typeof props.redirect !== 'undefined' ? props.redirect : defaultShowViewRoute;

    /**
     * Control rendering according to isDynamicView view configuration here.
     */
    const viewConfig = useViewConfig();
    const delayRenderingUntilFetch = React.useMemo(() => {
        return getIsDynamicView(viewConfig, props.viewName);
    }, [viewConfig, props.viewName]);
    if (delayRenderingUntilFetch) {
        // render component with pending states and resulting viewname
        return (
            <SimpleDynamicView
                noRedirectOnIdChange={props.noRedirectOnIdChange}
                id={props.id}
                entityType={props.resource}
                viewType="edit"
                render={(casetivityViewName) => {
                    return (
                        <DynamicViewIndicator isDynamic={!!casetivityViewName} viewName={props.viewName}>
                            <Edit
                                {...props}
                                key={props.id}
                                viewName={casetivityViewName ?? props.viewName}
                                redirect={redirect}
                            />
                        </DynamicViewIndicator>
                    );
                }}
            />
        );
    }
    return props.id && <Edit {...props} key={props.id} redirect={redirect} />;
});

export default GenericEdit;

interface EditLinkedToTaskFormProps extends EditProps {
    taskId: string;
}
export const GenericEditLinkedToTaskForm: React.FunctionComponent<EditLinkedToTaskFormProps> = React.memo((props) => {
    const taskFormDefinition = useSelector((state: RootState) => state.taskForms[props.taskId]);
    const taskIsClosed = useSelector((state: RootState) => taskClosed(state, props));
    const optInWriteable = React.useMemo(() => {
        if (taskIsClosed || !taskFormDefinition) {
            return null;
        }
        const hasSomeEntityLinkedField = (taskFormDefinition.fields || []).some((field) =>
            Boolean(field.params && (field as SimpleFormField).params.entityField),
        );
        if (!hasSomeEntityLinkedField) {
            return null;
        }
        return fromEntries(
            taskFormDefinition.fields
                .filter((field) => field.params && (field as SimpleFormField).params.entityField)
                .map((field) => [(field as SimpleFormField).params.entityField, field]),
        );
    }, [taskIsClosed, taskFormDefinition]);
    return <GenericEdit optInWriteable={optInWriteable} {...props} />;
});
