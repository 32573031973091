import React, { useMemo } from 'react';
import { Button, Card, CardHeader, CircularProgress } from '@material-ui/core';
import { Check, Error } from '@material-ui/icons';
import Forward from '@material-ui/icons/Forward';
import TaskFormController from 'bpm/task-form-editor/TaskFormBuilder';
import { Link } from 'react-router-dom';
import { TaskForm } from 'reducers/taskFormType';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';
import { css } from 'emotion';

const ManagedFormCreate = () => {
    const service = useMemo(
        () => (taskForm: Omit<TaskForm, 'id'>) =>
            services.taskFormsDefinitions.create(taskForm.key, {
                ...taskForm,
                version: 0,
            }),
        [],
    );
    const [state, request] = useService(service);
    return (
        <div>
            <Card style={{ margin: '1em' }}>
                <CardHeader
                    titleTypographyProps={{
                        variant: 'h4',
                    }}
                    title="Create Form"
                    action={
                        <div style={{ marginTop: '1em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/admin/bpm/forms"
                                startIcon={
                                    <Forward
                                        className={css`
                                            -webkit-transform: scaleX(-1);
                                            transform: scaleX(-1);
                                        `}
                                    />
                                }
                            >
                                Back to all forms
                            </Button>
                        </div>
                    }
                />
            </Card>
            <TaskFormController
                isCreate
                renderSaveButton={({ id, ...form }) => (
                    <Button
                        endIcon={
                            state.status === 'error' ? (
                                <Error />
                            ) : state.status === 'pending' ? (
                                <CircularProgress />
                            ) : state.status === 'success' ? (
                                <Check />
                            ) : null
                        }
                        variant="contained"
                        color="primary"
                        disabled={state.status === 'pending'}
                        onClick={() => request(form)}
                    >
                        Save
                    </Button>
                )}
            />
        </div>
    );
};

export default ManagedFormCreate;
