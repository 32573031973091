import { Button, CircularProgress } from '@material-ui/core';
import { Check, Error, Forward } from '@material-ui/icons';
import { TaskFormBuilder } from 'bpm/task-form-editor/TaskFormBuilder';
import React, { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TaskForm } from 'reducers/taskFormType';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';
import { Link } from 'react-router-dom';
import { css } from 'emotion';

const Edit = () => {
    const match = useRouteMatch();
    const formKey = match?.params?.key;

    const getFormService = useCallback(() => services.taskFormsDefinitions.get(formKey), [formKey]);
    const [taskFormState, fetchTaskForm] = useService(getFormService);
    useEffect(() => {
        return fetchTaskForm();
    }, [fetchTaskForm]);

    const saveFormService = useCallback(
        (formDefinition: Omit<TaskForm, 'id'>) =>
            services.taskFormsDefinitions.update(formDefinition.key, { ...formDefinition, version: 0 }),
        [],
    );
    const [saveFormState, saveForm] = useService(saveFormService);

    return taskFormState.fold(
        () => null,
        () => null,
        (data) => (
            <TaskFormBuilder
                TopRightAction={
                    <div style={{ marginTop: '1em' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/admin/bpm/forms"
                            startIcon={
                                <Forward
                                    className={css`
                                        -webkit-transform: scaleX(-1);
                                        transform: scaleX(-1);
                                    `}
                                />
                            }
                        >
                            Back to all forms
                        </Button>
                    </div>
                }
                hideIdField
                initialTaskForm={data}
                renderSaveButton={({ id, ...form }) => (
                    <Button
                        endIcon={
                            saveFormState.status === 'error' ? (
                                <Error />
                            ) : saveFormState.status === 'pending' ? (
                                <CircularProgress />
                            ) : saveFormState.status === 'success' ? (
                                <Check />
                            ) : null
                        }
                        variant="contained"
                        color="primary"
                        disabled={saveFormState.status === 'pending'}
                        onClick={() => saveForm(form)}
                    >
                        Save
                    </Button>
                )}
            />
        ),
        (error) => (
            <div>
                <pre>{(JSON.stringify(error), null, 1)}</pre>
            </div>
        ),
    );
};

export default Edit;
