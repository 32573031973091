import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import LinkButton from 'components/links/LinkButton';
import NameFilter from 'custom-reports/reports/shared/NameFilter';
import Message from 'i18n/components/Message';
import { makeAdhocList } from 'mui-rest-list';
import { Link } from 'react-router-dom';
import { CasetivityFormListItem, services } from 'sideEffect/services';
import { Add } from '@material-ui/icons';

const TaskFormList = makeAdhocList<CasetivityFormListItem>();

const List = () => {
    return (
        <div>
            <TaskFormList.AdhocList
                type="unpaginated"
                hasRefresh
                titleOptions={{
                    type: 'Typography',
                    TypographyProps: {
                        variant: 'h5',
                        component: 'span',
                        style: {
                            marginLeft: '1rem',
                            marginTop: '1rem',
                        },
                    },
                    text: 'Form Definitions',
                }}
                TopRightAction={
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/admin/bpm/create-form"
                        endIcon={<Add />}
                    >
                        Create
                    </Button>
                }
                initialFilter={{ name: '' }}
                renderFilter={(params) => <NameFilter {...params} />}
                renderNoResults={() => getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
                getDataObservable={({ filter }) => services.taskFormsDefinitions.list()}
                tableCaption="Reports available"
            >
                <TaskFormList.AdhocListColumn fieldKey="name" title="name" />
                <TaskFormList.AdhocListColumn fieldKey="version" title="Version" />
                <TaskFormList.AdhocListColumn fieldKey="key" title="Key" />
                <TaskFormList.AdhocListColumn
                    fieldKey="key"
                    hideColTitle
                    title="Link"
                    renderdata={(key) => (
                        <TableCell>
                            <LinkButton to={`/admin/bpm/form/${key}`}>Edit</LinkButton>
                        </TableCell>
                    )}
                />
            </TaskFormList.AdhocList>
        </div>
    );
};

export default List;
