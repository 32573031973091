import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import useService from 'util/hooks/useService';
import EntityTypeahead from 'fieldFactory/input/components/EntityTypeahead';
import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { services } from 'sideEffect/services';
import { saveAs } from 'file-saver';
import { Check, Error } from '@material-ui/icons';
import FileDownload from '@material-ui/icons/SaveAlt';
import DropzoneUpload from 'components/custom/DropzoneUpload';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ValueSetFromMultipleVsEndpoint } from 'valueSets/domain';

const ImportButton = ({
    uploaded,
}: {
    uploaded: ValueSetFromMultipleVsEndpoint & {
        deactivateMissing?: boolean;
    };
}) => {
    const [deactivateMissing, setDeactivateMissing] = useState(uploaded.deactivateMissing ?? false);
    const request = useCallback(
        () =>
            services.valuesetAdmin.import({
                ...uploaded,
                deactivateMissing,
            }),
        [uploaded, deactivateMissing],
    );
    const [state, fetch] = useService(request);
    return (
        <span>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={deactivateMissing}
                            onChange={(e) => {
                                setDeactivateMissing(e.target.checked);
                            }}
                            name="checked"
                        />
                    }
                    label="Deactivate Missing"
                />
            </FormGroup>
            <Button
                disabled={state.status === 'pending'}
                onClick={fetch}
                color="primary"
                variant="contained"
                endIcon={
                    state.status === 'error' ? (
                        <Error />
                    ) : state.status === 'pending' ? (
                        <CircularProgress />
                    ) : state.status === 'success' ? (
                        <Check />
                    ) : null
                }
            >
                Import
            </Button>
        </span>
    );
};
const ExportButton = ({ valuesetCode }: { valuesetCode: string }) => {
    const exportValueset = useCallback(() => services.valuesetAdmin.export(valuesetCode), [valuesetCode]);
    const [state, fetch] = useService(exportValueset);

    const file = state.status === 'success' ? state.data : null;
    useEffect(() => {
        if (file) {
            saveAs(new Blob([JSON.stringify(file, null, 2)], { type: 'application/json' }), `${file.code}.json`);
        }
    }, [file]);
    return (
        <Button
            disabled={state.status === 'pending'}
            onClick={fetch}
            color="primary"
            variant="contained"
            endIcon={
                state.status === 'error' ? (
                    <Error />
                ) : state.status === 'pending' ? (
                    <CircularProgress />
                ) : state.status === 'success' ? (
                    <Check />
                ) : (
                    <FileDownload />
                )
            }
        >
            Download
        </Button>
    );
};

const ValuesetAdminPage = () => {
    const [vs, setVS] = useState<{ id: string; code: string }>({ id: '', code: '' });
    const store = useStore();
    const [dryRunResponse, setDryRunResponse] = useState<{
        uploaded: ValueSetFromMultipleVsEndpoint;
        toAdd: string[];
        toUpdate: string[];
        leftover: string[];
    } | null>(null);
    return (
        <div style={{ marginLeft: '2em' }}>
            <h2>Import</h2>
            <div>
                <DropzoneUpload
                    title="Import Valueset"
                    dropzoneText="Drop file"
                    getLazyRequest={([file]: File[]) =>
                        async () => {
                            setDryRunResponse(null);
                            const text = await file.text();
                            const vs = JSON.parse(text);
                            const res = await services.valuesetAdmin.dryRun(vs).toPromise();
                            setDryRunResponse({
                                uploaded: vs,
                                ...res,
                            });
                            return {
                                status: 200,
                                json: () => Promise.resolve({}),
                            };
                        }}
                />
            </div>
            {dryRunResponse && (
                <div style={{ marginLeft: '1em' }}>
                    <h3>Dry run results</h3>
                    <div>
                        <b>Valueset:</b>&nbsp;{dryRunResponse.uploaded.code}
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="Dry run results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>To Add</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>To Update</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Leftover</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array(
                                    Math.max(
                                        dryRunResponse.leftover?.length ?? 0,
                                        dryRunResponse.toAdd?.length ?? 0,
                                        dryRunResponse.toUpdate?.length ?? 0,
                                    ),
                                )
                                    .fill(null)
                                    .map((_, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{dryRunResponse.toAdd?.[i]}</TableCell>
                                            <TableCell>{dryRunResponse.toUpdate?.[i]}</TableCell>
                                            <TableCell>{dryRunResponse.leftover?.[i]}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ height: '1em' }} />
                    <Alert severity="info" action={<ImportButton uploaded={dryRunResponse.uploaded} />}>
                        <AlertTitle>Looks good?</AlertTitle>
                        <p>You may complete the import now.</p>
                    </Alert>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <div style={{ margin: '1em' }}></div>
                    </div>
                </div>
            )}
            <Divider style={{ margin: '1em' }} />
            <h2>Export</h2>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '1em',
                }}
            >
                <div style={{}}>
                    <EntityTypeahead
                        allowEmptyQuery
                        meta={{}}
                        label="Valueset to export"
                        input={{
                            value: vs.id,
                            onBlur: (id) => {
                                if (typeof id === 'undefined') {
                                    return;
                                }
                                if (id === null) {
                                    setVS({ id: '', code: '' });
                                    return;
                                }
                                const code = store.getState().admin.entities['ValueSet']?.[id]?.code;

                                setVS({ id, code });
                            },
                        }}
                        reference="ValueSet"
                    />
                </div>
                {vs.code && (
                    <>
                        &nbsp;
                        <ExportButton valuesetCode={vs.code} />
                    </>
                )}
            </div>
        </div>
    );
};
export default ValuesetAdminPage;
