import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import { RootState } from 'reducers/rootReducer';
import { getContainsCodes, getValuesetReverseLookupUtilities, isNumEqual, isZero } from 'expressions/contextUtils';
import { CasetivityViewContext } from 'util/casetivityViewContext';
import ViewConfig from 'reducers/ViewConfigType';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import { IntlShape } from 'react-intl';
import getMessage, { getLocale as getGetLocale } from 'i18n/getMessage';
import { nullAdhocVariablesContext } from 'components/generics/form/EntityFormContext/util/getVariablesContextSelector';
import { userAgent } from 'userAgent';
import isOffline from 'util/isOffline';
import f from 'f';
import getHtmlTableFromList from 'expressions/contextUtils/getHtmlTableFromList';
// import { browserContextUtils } from './getContexts';

const getAppConfig = (() => {
    let basicInfo: RootState['basicInfo'];
    return (appConfigName: string) => {
        try {
            if (!basicInfo) {
                basicInfo = JSON.parse((window as any).CASETIVITY_BASIC_INFO);
            }
            const found = basicInfo.application?.publicAppConfigs?.[appConfigName] ?? null;
            return found;
        } catch (e) {
            console.error(e);
            return null;
        }
    };
})();

export const browserContextUtils = {
    _isIos: userAgent.isIos(),
    _userAgent: navigator.userAgent,
    getUserAgent: () => navigator.userAgent,
    _isAndroid: userAgent.isAndroid(),
    getAppConfig,
};

export const setupGenericContext = (args: {
    viewConfig: ViewConfig;
    entities: { Concept?: {} };
    valueSets: RootState['valueSets'];
    viewContext?: CasetivityViewContext;
    intl?: IntlShape;
    extra?: {};
    backref?: {
        id: string;
        entityType: string;
        path: string;
    };
}) => {
    const { entities, valueSets, viewConfig, viewContext, intl, extra, backref } = args;
    const concepts = entities.Concept || {};
    const options = { viewContext, dateFormat: viewConfig?.application?.dateFormat || 'MM/dd/yyyy', backref };
    const getLocale = intl ? getGetLocale(intl) : () => 'en';

    let res = {
        f,
        isPrintMode: () => false, // let this be overridden by caller
        ...nullAdhocVariablesContext,
        isOffline,
        getLocale,
        getMessage: intl ? getMessage(intl) : (m) => m,
        roles: viewConfig?.user?.roles || [],
        getHtmlTableFromList,
        ...getValuesetReverseLookupUtilities(valueSets, concepts),
        ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
        containsCodes: getContainsCodes(concepts),
        options,
        viewContext,
        ...createRootContext({ options }),
        isZero,
        isNumEqual,
        isFrontend: () => true,
        isBackend: () => false,
        ...browserContextUtils,
        ...extra,
        getBackref: () => backref ?? null,
    };
    return res;
};
