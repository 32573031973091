import { connectRouter } from 'connected-react-router';
import lists from './lists/index';
import { BPMState } from 'bpm/reducer/BPMState';
import valueSetsReducer from 'valueSets/reducer';
import printTemplateReducer from 'printTemplate/reducer';
import taskFormStatusReducer from 'bpm/task-form/reducer/remoteStatus';
import dashboardReducer from 'dashboard2/dashboard-config/reducer';
import ViewConfig, { ApplicationConfig } from './ViewConfigType';
import { RecentTab } from './recentTabReducer';
import entityValidationsReducer from './entityValidationsReducer';
import viewValidationsReducer from './viewValidationsReducer';
import searchValidationsReducer from './searchValidationsReducer';
import entityVisibilityReducer from './entityVisibilityReducer';
import lastSearchOrProcessPageReducer from './lastSearchOrProcessPageReducer';
import entityConceptExpReducer from 'viewConfigCalculations/ConceptAvailabilityExpressions/reducer';
import viewItemFilterExpReducer from 'viewConfigCalculations/filterExpressions/reducer';
import templateExpReducer from 'viewConfigCalculations/expressionTemplates/reducer';
import actionButtonExpReducer from 'viewConfigCalculations/actionButtonDisplayExpressions/reducer';
import entityEditabilityReducer from './entityEditabilityReducer';
import notistackReducer from 'notistack/reducer';
import listIsLoading from './lists/listLoading';
import getOneStatusReducer from 'remoteStatus/one/reducer';
import disableFormSaveNotifierStatusReducer from 'formSaveNotifier/reducer';
import globalAlerts from 'global-alerts/reducer';
import impersonatingReducer from 'impersonate/reducer';
import reauthReducer from 'reauth-interval-and-retries/reducers';
import expressionTesterReducer from 'expression-tester/reducer';
import undisableTaskFormsReducer from 'bpm/undisable-task-form/reducer';
import connectivityStatusReducer from 'connectivity/reducer';
import mfaReducer from 'auth/reducer/mfaReducer';
import { LangLeaf, Messages } from 'i18n/getProvidedMessages';
import localeReducer from 'i18n/store/reducer';
import profilesReducer from 'auth/profiles/reducer';
import offlineTasksReducer from 'offline_app/offline_stateful_tasks/offlineTasks/offlineTasksReducer';
import taskCurrentlyWritingToOfflineReducer from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/currentlyWritingToOfflineReducer';
import entitySubmitsInTaskContextReducer from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/entitySubmitsInTaskContextReducer';
import downloadedListViewsReducer from 'offline_app/offline_stateful_tasks/download/downloadedListViews/reducer';
import downloadedRef1ViewsReducer from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/reducer';
import offlineMetaReducer from 'offline_app/offline_stateful_tasks/offlineMeta/reducer';
import saveOfflineGateOpenReducer from 'offline_app/offline_stateful_tasks/saveOfflineGate/saveOfflineGateOpenReducer';
import entityFormSubmissionStateReducer from './entityFormSubmissionStateReducer';
import viewDetailsStackReducer from 'popoverStackManagement/viewDetailsStack/viewDetailsSlice';
import { TaskForm } from './taskFormType';
import refreshProcessDataKeyReducer from 'bpm2/refreshProcessData/refreshProcessDataKeySlice';
import visibleViewNamesReducer from 'components/generics/visibleViewNames/visibleViewNamesSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import reportDefinitionReducer from '../report2/hooks/reportDefinitionSlice';

interface Admin {
    loading: number;
    entities: {
        [entitykey: string]: {
            [id: string]: {
                id: string;
                entityType: string;
                title?: string;
                active?: boolean;
            };
        };
    };
    ui: {
        sidebarOpen: boolean; // this is the sidebar menu used in mobile
    };
    resources: {
        [resource: string]: {
            lists: ReturnType<ReturnType<typeof lists>>;
            listIsLoading: ReturnType<ReturnType<typeof listIsLoading>>;
        };
    };
}

export interface RootAppState {
    visibleViewNames: ReturnType<typeof visibleViewNamesReducer>;
    refreshProcessDataKey: ReturnType<typeof refreshProcessDataKeyReducer>;
    viewDetailsStack: ReturnType<typeof viewDetailsStackReducer>;
    entityFormSubmissionState: ReturnType<typeof entityFormSubmissionStateReducer>;
    offlineMeta: ReturnType<typeof offlineMetaReducer>;
    offlineDownloadedRef1Views: ReturnType<typeof downloadedRef1ViewsReducer>;
    offlineDownloadedListViews: ReturnType<typeof downloadedListViewsReducer>;
    entitySubmitsInTaskContext: ReturnType<typeof entitySubmitsInTaskContextReducer>;
    taskCurrentlyWritingToOffline: ReturnType<typeof taskCurrentlyWritingToOfflineReducer>;
    offlineTasks: ReturnType<typeof offlineTasksReducer>;
    profiles: ReturnType<typeof profilesReducer>;
    globalAlerts: ReturnType<typeof globalAlerts>;
    locale?: ReturnType<typeof localeReducer>;
    form?: {};
    mfa: ReturnType<typeof mfaReducer>;
    router: ReturnType<ReturnType<typeof connectRouter>>;
    viewConfig?: ViewConfig;
    admin: Admin;
    resources?: { name: string }[];
    processDefinitions?: {};
    valueSets: ReturnType<typeof valueSetsReducer>;
    printTemplates: ReturnType<typeof printTemplateReducer>;
    recentTab: RecentTab;
    entityValidations: ReturnType<typeof entityValidationsReducer>;
    viewValidations: ReturnType<typeof viewValidationsReducer>;
    searchValidations: ReturnType<typeof searchValidationsReducer>;
    entityVisibility: ReturnType<typeof entityVisibilityReducer>;
    entityConceptExps: ReturnType<typeof entityConceptExpReducer>;
    viewItemFilterExps: ReturnType<typeof viewItemFilterExpReducer>;
    templateExps: ReturnType<typeof templateExpReducer>;
    entityEditability: ReturnType<typeof entityEditabilityReducer>;
    actionButtonExps: ReturnType<typeof actionButtonExpReducer>;
    getOneStatus: ReturnType<typeof getOneStatusReducer>;
    taskForms: {
        [key: string]: TaskForm;
    };
    taskFormStatuses: ReturnType<typeof taskFormStatusReducer>;
    bpm: BPMState;
    undisableTaskForms: ReturnType<typeof undisableTaskFormsReducer>;
    impersonating: ReturnType<typeof impersonatingReducer>;
    dashboard: ReturnType<typeof dashboardReducer>;
    basicInfo?: {
        casetivityCore?: { coreCommit: string; coreVersion: string };
        frontendBundleBaseUrl?: string;
        acceptExpandTree?: boolean;
        logo: string;
        title: string;
        debugFeaturesEnabled?: boolean;
        pageNum: number;
        application: ApplicationConfig;
        applicationColor?: string;
        logoutRedirectUrl?: string;
        loginRedirectUrl?: string;
        maxFileSize: string;
        messages: Messages<LangLeaf>;
        maxExactTotalCount?: number;
        showConfigMgmt: boolean;
        // FISH-4327 SAML IDP login options
        formLoginEnabled?: boolean; // allow hiding regular login form
        saml2LoginEnabled?: boolean; // If true, add 'saml2Idps' below
        saml2Idps: {
            [buttonText: string]: string; // maps onto urls to navigate to.
        };
    };
    reauth: ReturnType<typeof reauthReducer>;
    connectivityStatus: ReturnType<typeof connectivityStatusReducer>;
    viewStack: string[];
    expressionEval?: {
        configEntity?: ReturnType<typeof entityVisibilityReducer>;
        configTask?: any;
    };
    debugMode: boolean;
    reportDefinition: ReturnType<typeof reportDefinitionReducer>;
    thm0: boolean;
    printMode: boolean;
    lastSearchOrProcessPage: ReturnType<typeof lastSearchOrProcessPageReducer>;
    notistack: ReturnType<typeof notistackReducer>;
    disableFormSaveNotifier: ReturnType<typeof disableFormSaveNotifierStatusReducer>;
    expressionTesterOpen: ReturnType<typeof expressionTesterReducer>;
    saveOfflineGateOpen: ReturnType<typeof saveOfflineGateOpenReducer>;
}
export interface RootState extends RootAppState {
    viewConfig: ViewConfig;
    viewConfigIsLoading: boolean;
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
