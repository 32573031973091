import React, { useRef, useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { RootState } from 'reducers/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, makeStyles, Chip, Tooltip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fromNullable, fromPredicate, option } from 'fp-ts/lib/Option';
import { traverse } from 'fp-ts/lib/Array';
import uniq from 'lodash/uniq';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { getRestUrl, getPluralName, getRelatedField } from 'components/generics/utils/viewConfigUtils';
import { createStyles, Theme } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import translateFieldWithSearchTypeAppended from 'clients/utils/translateFieldWithSearchTypeAppended';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { grey } from '@material-ui/core/colors';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { refreshContext } from 'components/generics/form/refreshContext';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';
import isOffline from 'util/isOffline';
import useAxios from '../useAxios';
import { BACKEND_BASE_URL } from 'config';
import renderTextFieldLabel from 'fieldFactory/util/renderTextFieldLabel';
import ListboxComponent from './Listbox';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        },
        chipArea: {
            marginTop: 16,
            backgroundColor: grey[100],
        },
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        inputUnderline: {
            // '&::after': {
            //     borderBottom: 0,
            // },
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            maxHeight: 300,
            left: 0,
            minWidth: '100%',
        },
        popoverPaper: {
            maxHeight: 150,
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            maxHeight: 150,
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        iconButton: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
            height: 30,
            width: 30,
            padding: 0,
        },
        loadingSpinner: {
            position: 'absolute',
            right: 2,
            bottom: 4,
            padding: 0,
        },
    }),
);
export interface MultipleEntityTypeaheadCommonProps {
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    expansions?: string[];
    filterString?: string;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    isPopover?: boolean;
    label?: string;
    isRequired?: boolean;
    renderLabel?: boolean;
    emptyText?: string;
    disabled?: boolean;
    allowEmptyQuery: boolean;
    input: {
        onChange: EventOrValueHandler<string[] | null>;
        onBlur: EventOrValueHandler<string[] | null>;
        value?: string[] | null;
    };
    source: string;
    reference: string;
    meta: WrappedFieldMetaProps;
    record?: {};
    search?: string | boolean;
    create?: string | boolean;
    resultSize?: number;
}
export type MultipleEntityTypeaheadProps = MultipleEntityTypeaheadCommonProps &
    (
        | {
              mode: 'SetRelationshipOnAdd';
              resource: string;
              record: { id: string };
          }
        | { mode: 'IdsList' }
    );

export const createCurrentlySelectedEntitiesSelector = () => {
    return createSelector(
        (state: RootState, args: { reference: string; value: string[] }) => args.value,
        (state: RootState, args: { reference: string; value: string[] }) => state.admin.entities[args.reference],
        (value, refEntities) => {
            return fromPredicate<string[]>(Boolean)(value)
                .chain((ids) =>
                    fromNullable(refEntities).chain<EntityRecord[]>((e) => {
                        const r = traverse(option)<string, EntityRecord>(ids, (id) => fromNullable(e[id]));
                        return r;
                    }),
                )
                .getOrElse([]);
        },
    );
};

const addEntityToStore = (record: { id: string; entityType: string }) => ({
    type: 'ADD_ENTITY_TO_STORE',
    payload: {
        data: {
            entities: {
                [record.entityType]: {
                    [record.id]: record,
                },
            },
        },
    },
});
type EntityRecord = RootState['admin']['entities'][0][0];
const MultipleEntityTypeaheadComponent: React.FC<MultipleEntityTypeaheadProps> = (props) => {
    const {
        label,
        randomizeNameAsBrowserAutocompleteHack = true,
        input: { value: _value, onBlur, onChange },
        meta,
        expansions,
        disabled,
        renderLabel = true,
        emptyText = 'None Selected',
        isPopover,
        reference,
        dropdownPosition = 'below',
        source,
        allowEmptyQuery,
        filterString,
        tooltipText,
        resultSize,
    } = props;
    const propagateValue: EventOrValueHandler<string[] | null> = useCallback(
        (value) => {
            onChange?.(value);
            onBlur(value);
        },
        [onChange, onBlur],
    );
    const value = useMemo(() => _value || [], [_value]);
    const { touched, error } = meta;
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const refresh = useContext(refreshContext);
    const dispatch = useDispatch();
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const classes = useStyles(props);
    const restUrl = useSelector(getRestUrl(props.reference));
    const refEntityDisplayNamePlural = useSelector((state: RootState) => getPluralName(state.viewConfig, reference));
    const getcurrentlySelectedEntities = useMemo(createCurrentlySelectedEntitiesSelector, []);
    const currentlySelectedEntities = useSelector((state: RootState) =>
        getcurrentlySelectedEntities(state, { reference, value }),
    );
    const errorMessageId = useRef(uniqueId('entity-typeahead'));
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const newFilter = useMemo(() => {
        const filterObject = getFilterFromFilterString(filterString);
        return (() => {
            const _newFilter = {};
            Object.entries(filterObject).forEach(([key, value]) => {
                _newFilter[translateFieldWithSearchTypeAppended(key).split('_~_').join('.')] = value;
            });
            return _newFilter;
        })();
    }, [filterString]);

    useEffect(() => {
        if (Array.isArray(value)) {
            const notFoundIds =
                expansions && expansions.length > 0
                    ? value
                    : value.filter((id) => !currentlySelectedEntities.find((e) => e.id === id));
            if (notFoundIds.length === 0) {
                return;
            }
            setLoading(true);
            const $completionStream = Observable.create((observer) => {
                notFoundIds.forEach((id) => {
                    dispatch(
                        crudGetOneAction({
                            id,
                            resource: reference,
                            appendExpansions: expansions,
                            view: -1,
                            cb: () => {
                                observer.next();
                            },
                            errorsCbs: {
                                '*': () => {
                                    observer.next();
                                },
                            },
                        }),
                    );
                });
            }).pipe(take(notFoundIds.length));
            const subscription = $completionStream.subscribe(
                () => {},
                () => {
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                },
            );
            return () => {
                subscription.unsubscribe();
            };
        }
    }, []); // eslint-disable-line
    const { fieldVariant, getInputLabelProps } = useContext(themeOverrideContext);
    const { InputPropsClasses, createFormHelperTextProps, muiErrorProp, helperText } = useTextFieldUtils(meta);
    const handleSelectRecord = (record) => {
        if (record) {
            // make record immediately available
            dispatch(fetchStart());
            dispatch(addEntityToStore(record));
            dispatch(fetchEnd());

            setInputValue('');
            const addValue = () => {
                const newValue = uniq([...value, record.id]);
                // IF we have expansions, we will have to fetch...
                if (expansions && expansions.length > 0) {
                    setLoading(true);
                    setImmediate(() => {
                        dispatch(
                            crudGetOneAction({
                                id: record.id,
                                resource: reference,
                                view: -1,
                                appendExpansions: expansions,
                                cb: () => {
                                    setImmediate(() => {
                                        setLoading(false);
                                        propagateValue(newValue);
                                    });
                                },
                                errorsCbs: {
                                    '*': () => {
                                        setLoading(false);
                                        propagateValue(newValue);
                                    },
                                },
                            }),
                        );
                    });
                } else {
                    propagateValue(newValue);
                }
            };
            if (props.mode === 'SetRelationshipOnAdd') {
                const fieldOpposite = `${getRelatedField(viewConfig, props.resource, props.source.slice(0, -3))}Ids`;

                setLoading(true);
                dispatch(
                    crudGetOneAction({
                        resource: reference,
                        id: record.id,
                        view: -1,
                        cb: (id, newData) => {
                            // new we use the data with the expansion to edit
                            dispatch(
                                crudUpdateAction({
                                    resource: reference,
                                    data: {
                                        id,
                                        partialUpdate: true,
                                        [fieldOpposite]: uniq([...(newData[fieldOpposite] || []), props.record.id]),
                                    },
                                    previousData: newData,
                                    cb: () => {
                                        setLoading(false);
                                        refresh();
                                    },
                                    errorsCbs: {
                                        '*': () => {
                                            setLoading(false);
                                        },
                                    },
                                }),
                            );
                        },
                        appendExpansions: [fieldOpposite],
                        errorsCbs: {
                            '*': () => {
                                setLoading(false);
                            },
                        },
                    }),
                );
                // set relationship on opposite side, and refetch base?
                // duplicate manymany behavior.
            } else {
                addValue();
            }
        } else {
            // onBlur(null);
        }
    };

    const [open, setOpen] = useState(false);

    const axiosParams = useMemo(
        () => ({
            'title.equals': inputValue ? `*${inputValue}*` : '*',
            sort: 'title%2CASC',
            size: resultSize,
            ...newFilter,
        }),
        [inputValue, newFilter, resultSize],
    );

    const needsSomeInputValue = !inputValue && !allowEmptyQuery;
    const axios = useAxios<any[]>({
        url: !open || needsSomeInputValue ? '' : BACKEND_BASE_URL + restUrl,
        params: axiosParams,
    });
    const { loading: queryLoading, error: queryError, data, total } = axios;
    const handleDelete = (id: string) => {
        if (!disabled) {
            // ajax delete if on entity
            if (props.mode === 'SetRelationshipOnAdd') {
                setLoading(true);
                dispatch(
                    crudUpdateAction({
                        resource: props.resource,
                        data: {
                            id: props.record.id,
                            partialUpdate: true,
                            [source]: (value || []).filter((_id) => _id !== id),
                        },
                        previousData: props.record,
                        cb: () => {
                            setLoading(false);
                            setImmediate(() => {
                                refresh();
                            });
                        },
                        errorsCbs: {
                            '*': () => {
                                setLoading(false);
                            },
                        },
                    }),
                );
            } else {
                propagateValue(value.filter((lid) => lid !== id));
            }
        }
    };
    const InputProps = {
        'aria-errormessage': touched && error ? errorMessageId.current : undefined,
        // placeholder: selectedItem ? selectedItem.title : emptyText,
        placeholder: 'Select Values',
        disabled,
        style: {
            textOverflow: 'ellipsis',
            marginRight: '60px',
        },
        autoComplete: 'never',
    };
    if (randomizeNameAsBrowserAutocompleteHack) {
        InputProps['name'] = uniqueNameToThrowOffChromeAutoFill.current;
    }

    const El = (
        <EntityInspect
            reference={reference}
            formId={`frommultipleentitytypeahead ${source}`}
            renderComponent={(args) => (
                <div className={classes.root}>
                    <Autocomplete<any, true, true>
                        ListboxComponent={ListboxComponent}
                        ListboxProps={{
                            style: { maxHeight: isPopover ? '150px' : '300px' },
                            count: data?.length,
                            total: total,
                        }}
                        multiple
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        inputValue={inputValue}
                        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue(e.target.value);
                        }}
                        disabled={disabled}
                        onChange={(event, records) => {
                            // const newIds = records.map(c => (c as any).id);
                            const added = records.filter((v) => !value?.includes(v.id))[0];
                            const deletedId = value.filter((v) => !records?.map((r) => r.id).includes(v))[0];
                            if (added) {
                                handleSelectRecord(added);
                            }
                            if (deletedId) {
                                handleDelete(deletedId);
                            }
                        }}
                        options={
                            needsSomeInputValue
                                ? [
                                      {
                                          title: 'You have to enter a search query',
                                          id: '__alert__',
                                      },
                                  ]
                                : data ?? []
                        }
                        getOptionDisabled={(option) => option?.id === '__alert__'}
                        renderOption={
                            needsSomeInputValue ? (option) => <div aria-live="polite">{option.title}</div> : undefined
                        }
                        value={currentlySelectedEntities || []}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(record) => record.title ?? ''}
                        disableCloseOnSelect
                        loading={needsSomeInputValue ? false : queryLoading}
                        noOptionsText={
                            <>
                                {!queryLoading && data ? (
                                    <div aria-live="polite">{`No ${
                                        refEntityDisplayNamePlural || 'results'
                                    } found`}</div>
                                ) : !queryLoading && queryError ? (
                                    <div aria-live="polite">{`Error! ${queryError}`}</div>
                                ) : null}
                            </>
                        }
                        disableClearable
                        renderTags={(tagValue, getTagProps) => {
                            return tagValue.map(({ title, id }, index) => (
                                <Chip
                                    key={id}
                                    aria-roledescription="Button Press Delete key to delete"
                                    label={title || id}
                                    aria-label={`${label}: Entry ${index + 1} of ${tagValue.length}: ${title || id}`}
                                    className={classes.chip}
                                    {...getTagProps({ index })}
                                    onClick={() => args.selectId(id)}
                                    tabIndex={0}
                                    onDelete={() => handleDelete(id)}
                                    deleteIcon={disabled ? <span style={{ width: '.5em' }} /> : undefined}
                                />
                            ));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={renderTextFieldLabel(fieldVariant, renderLabel, props.isRequired, props.label)}
                                error={muiErrorProp}
                                helperText={helperText}
                                disabled={disabled}
                                FormHelperTextProps={createFormHelperTextProps(InputProps)}
                                InputLabelProps={getInputLabelProps({ ...params.InputLabelProps })}
                                fullWidth
                                variant={fieldVariant}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.inputProps,
                                        ...InputProps,
                                        'aria-label': label,
                                    },
                                    classes: InputPropsClasses,
                                    endAdornment: (
                                        <React.Fragment>
                                            {!isOffline() && loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
            )}
        />
    );
    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                <div>{El}</div>
            </Tooltip>
        );
    }
    return El;
};

export default MultipleEntityTypeaheadComponent;
